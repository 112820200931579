import { gql } from 'apollo-angular';
import { cancelOrder } from 'libs/transport-ui-store/src/lib/orders-table/actions/order-details-carrier.actions';

import * as sharedFragments from '../shared/graphql-fragments-shared';
import * as fragments from './graphql-fragments-owner';

/**
 * GraphQL carrier queries
 */
export const OWNER_MUTATIONS = {
  addOrder: gql`
    mutation AddOrder($input: AddOrderInput!) {
      addOrder(input: $input) {
        order {
          id
        }
      }
    }
  `,

  addAuction: gql`
    mutation AddAuctionOrder($input: AddAuctionOrderInput!) {
      addAuctionOrder(input: $input) {
        id
        status
      }
    }
  `,

  finishAuctionOrder: gql`
    mutation finishAuctionOrder($orderId: ID!) {
      finishAuctionOrder(orderId: $orderId) {
        id
      }
    }
  `,

  cancelOrderTrading: gql`
    mutation CancelOrderTrading($orderId: ID!) {
      cancelOrderTrading(orderId: $orderId) {
        ok
      }
    }
  `,

  assignCarrier: gql`
    mutation AssignCarrier($carrierId: ID!, $orderId: ID!) {
      assignCarrier(carrierId: $carrierId, orderId: $orderId) {
        carrier {
          email
          id
          lastName
          firstName
          companyName
        }
      }
    }
  `,

  acceptCounterOffer: gql`
    mutation acceptCounterOffer($counterOfferId: String! ){
      acceptCounterOffer(counterOfferId: $counterOfferId) {
        ok
      }
    }
  `,

  acceptCounterOfferAsBet: gql`
    mutation acceptCounterOfferAsBet($counterOfferId: String! ){
      acceptCounterOfferAsBet(counterOfferId: $counterOfferId) {
        ok
      }
    }
  `,

  acceptCounterOfferAsBetForBidding: gql`
    mutation acceptCounterOfferAsBetForBidding($counterOfferId: String! ){
      acceptCounterOfferAsBetForBidding(counterOfferId: $counterOfferId) {
        ok
      }
    }
  `,

  startOrderTrading: gql`
    mutation StartOrderTrading($orderId: ID!, $isMarket: Boolean, $lot: LotInput) {
      startOrderTrading(orderId: $orderId, isMarket: $isMarket, lot: $lot) {
        ok
      }
    }
  `,

  updateOrder: gql`
  mutation UpdateOrder($input: UpdateOrderInput!) {
      updateOrder(input: $input) {
        order {
          id
        }
      }
    }
  `,

  cancelOrder: gql`
    mutation cancelOrder($orderId: ID!) {
      cancelOrder(orderId: $orderId) {
        id
      }
    }
  `,

  archiveCargoType: gql`
    mutation ArchiveCargoType($cargoTypeId: ID!) {
      sendCargoTypeToArchive(cargoTypeId: $cargoTypeId) {
        id
      }
    }
  `,

  addCargoType: gql`
    mutation AddCargoType($input: CargoTypeInput!) {
      addCargoType(input: $input) {
        id
      }
    }
  `,

  editCargoType: gql`
    mutation EditCargoType($input: EditCargoTypeInput!) {
      editCargoType(input: $input) {
        id
      }
    }
  `,

  /**
   * notification mutation: mark as read
   */
  markNotificationAsRead: gql`
    mutation markNotificationAsRead($notificationId: ID!) {
      markNotificationAsRead(notificationId: $notificationId)
    }
  `,

  /**
   *  notifications mutation: mark all as read
   */
  markAllNotificationsAsRead: gql`
    mutation {
      markAllNotificationsAsRead
    }
  `,

  archivePlace: gql`
    mutation sendLoadingUnloadingPlaceToArchive($storagePointId: ID!) {
      sendLoadingUnloadingPlaceToArchive(storagePointId: $storagePointId) {
        id
      }
    }
  `,

  addPlace: gql`
    mutation addLoadingUnloadingPlace($input: LoadingUnloadingPlaceInput!) {
      addLoadingUnloadingPlace(input: $input) {
        ${fragments.STORAGE_POINT_FRAGMENT}
      }
    }
  `,

  addPlaceWithDrivingImg: gql`
    mutation addLoadingUnloadingPlace(
      $drivingDirectionsImg: Upload
      $input: LoadingUnloadingPlaceInput!
    ) {
      addLoadingUnloadingPlace(drivingDirectionsImg: $drivingDirectionsImg, input: $input) {
        ${fragments.STORAGE_POINT_FRAGMENT}
      }
    }
  `,

  editPlaceWithDrivingImg: gql`
    mutation editLoadingUnloadingPlace(
      $drivingDirectionsImg: Upload
      $input: EditLoadingUnloadingPlaceInput!
    ) {
      editLoadingUnloadingPlace(drivingDirectionsImg: $drivingDirectionsImg, input: $input) {
        ${fragments.STORAGE_POINT_FRAGMENT}
      }
    }
  `,

  editLoadingUnloadingPlace: gql`
    mutation editLoadingUnloadingPlace(
      $input: EditLoadingUnloadingPlaceInput!
    ) {
      editLoadingUnloadingPlace(input: $input) {
        ${fragments.STORAGE_POINT_FRAGMENT}
      }
    }
  `,

  editCarrierContract: gql`
    mutation EditCarrierContract($input: EditCarrierContractInput!, $file: Upload) {
      editCarrierContract(input: $input, file: $file) {
        id
        contractNumber
        startDate
        endDate
        file
        isArchived
        cargoQuantity
        loadingPlaces {
          id
          pointName
          name
          address
        }
        unloadingPlaces {
          id
          pointName
          name
          address
        }
        cargoTypes {
          id
          name
        }
      }
    }
  `,

  addCarrierContract: gql`
    mutation AddCarrierContract($input: CarrierContractInput!, $file: Upload) {
      addCarrierContract(input: $input, file: $file) {
        id
        contractNumber
        startDate
        endDate
        file
        isArchived
        cargoQuantity
        loadingPlaces {
          id
          pointName
          name
          address
        }
        unloadingPlaces {
          id
          pointName
          name
          address
        }
        cargoTypes {
          id
          name
        }
      }
    }
  `,

  addCargoContract: gql`
    mutation addCargoContract($input: CarrierContractInput!, $file: Upload) {
      addCarrierContract(input: $input, file: $file) {
        id
        contractNumber
        startDate
        endDate
        isArchived
        cargoQuantity
        loadingPlaces {
          id
          pointName
          name
          address
        }
        unloadingPlaces {
          id
          pointName
          name
          address
        }
        cargoTypes {
          id
          name
        }
      }
    }
  `,

  archiveContract: gql`
    mutation ArchiveContract($carrierContractId: ID!) {
      sendCarrierContractToArchive(carrierContractId: $carrierContractId) {
        id
      }
    }
  `,

  saveOrganizationProfile: gql`
    mutation editOrganization($input: EditOrganizationInput) {
      editOrganization(input: $input) {
        ${sharedFragments.OWNER_ORGANIZATION_FRAGMENT}
          subdomain
          logo
      }
    }
  `,

  archiveSubOrganization: gql`
    mutation sendOrganizationToArchive($organizationId: ID!) {
      sendOrganizationToArchive(organizationId: $organizationId) {
        id
      }
    }
  `,

  addSubOrganization: gql`
    mutation addOrganization($input: AddOrganizationInput!) {
      addOrganization(input: $input) {
        id
      }
    }
  `,

  addCarrierGroup: gql`
    mutation AddCarrierGroup($input: AddCarrierGroupInput!) {
      addCarrierGroup(input: $input) {
        id
      }
    }
  `,

  saveCarrierGroup: gql`
    mutation EditCarrierGroup($input: EditCarrierGroupInput!) {
      editCarrierGroup(input: $input) {
        id
      }
    }
  `,
  editBusinessRelationship: gql`
    mutation EditBusinessRelationship($carrierOrgId: ID!, $state: String!) {
      editBusinessRelationship(carrierOrgId: $carrierOrgId, state: $state){
        state
      }
    }
  `,
  editBusinessRelationshipFacsimileImg: gql`
    mutation EditBusinessRelationship($carrierOrgId: ID!, $carrierFacsimileImg: Upload) {
      editBusinessRelationship(carrierOrgId: $carrierOrgId, carrierFacsimileImg: $carrierFacsimileImg){
        carrierFacsimileImg
      }
    }
  `,

  rejectCarrier: gql`
    mutation EditBusinessRelationship($carrierOrgId: ID!, $state: String!, $lastChangeReason: String!) {
      editBusinessRelationship(carrierOrgId: $carrierOrgId, state: $state, lastChangeReason: $lastChangeReason){
        state
      }
    }
  `,

  archiveCarrierGroup: gql`
    mutation archiveCarrierGroup($groupId: ID!) {
      sendToArchiveCarrierGroup(groupId: $groupId) {
        id
      }
    }
  `,

  deleteDraft: gql`
    mutation DeleteDraft($draftId: ID!) {
      deleteDraft(draftId: $draftId) {
        id
      }
    }
  `,

  addInsuranceContract: gql`
    mutation AddInsuranceContract($input: AddInsuranceContractInput!) {
      addInsuranceContract(input: $input) {
        ${fragments.INSURANCE_CONTRACT}
      }
    }
  `,

  editInsuranceContract: gql`
    mutation EditInsuranceContract($input: EditInsuranceContractInput!) {
      editInsuranceContract(input: $input) {
        ${fragments.INSURANCE_CONTRACT}
      }
    }
  `,

  archiveInsuranceContract: gql`
    mutation SendInsuranceContractToArchive($contractId: ID!) {
      sendInsuranceContractToArchive(contractId: $contractId) {
        ${fragments.INSURANCE_CONTRACT}
      }
    }
  `,

  cancelTender: gql`
    mutation cancelTender($tenderId: ID!) {
      cancelTender(tenderId: $tenderId) {
        ${fragments.TENDER_FRAGMENT}
      }
    }
  `,

  addTender: gql`
    mutation addTender($input: AddTenderInput) {
      addTender(input: $input) {
        ${fragments.TENDER_FRAGMENT}
      }
    }
  `,

  setTenderWinner: gql`
    mutation setTenderWinner(
      $bidId: ID!
      $tenderId: ID!
    ) {
      setTenderWinner(tenderId: $tenderId, bidId: $bidId) {
        ${fragments.TENDER_FRAGMENT}
      }
    }
  `,

  addSla: gql`
    mutation AddSla($input: SLAInput!) {
      addSla(inputData: $input) {
        id
      }
    }`,

  editSla: gql`
    mutation EditSla($input: EditSLAInput!) {
      editSla(inputData: $input) {
        id
      }
    }
  `,

  deleteSla: gql`
    mutation DeleteSla($slaId: ID!) {
      deleteSla(slaId: $slaId) {
        deleted
      }
    }
  `,
};
