import { ChangeDetectionStrategy, ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TnFilterBaseDirective } from '@transport/ui-directives';
import { IDriversFilter, TnDirectoryDriversFacade } from '@transport/ui-store';
import { regExpConfig } from "@transport/ui-utils";

@UntilDestroy()
@Component({
  selector: 'transport-drivers-filter',
  templateUrl: './drivers-filter.component.html',
  styleUrls: ['./drivers-filter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnDriversFilterModal extends TnFilterBaseDirective<IDriversFilter, TnDriversFilterModal> implements OnInit {
  public filterForm = this.fb.group({
    fio: [''],
    phoneNumber: [''],
  });

  public filterData = this.getFilterData();

  public defaultValues = this.filterForm.value;

  public readonly mask = regExpConfig.ngxMask.phone.ru.mask;

  public readonly patterns = regExpConfig.ngxMask.phone.ru.patterns;

  constructor(
    public readonly facade: TnDirectoryDriversFacade,
    private readonly dialog: MatDialog,
    private readonly cdRef: ChangeDetectorRef,
    private readonly fb: FormBuilder,
  ) {
    super();
  }

  public ngOnInit(): void {
    void this.facade.currentFilter$.pipe(untilDestroyed(this)).subscribe((value?: IDriversFilter) => {
      this.applyCurrentFilter(value);
      this.cdRef.markForCheck();
    });
  }

  public applyCurrentFilter(value?: IDriversFilter) {
    this.filterForm.patchValue({
      fio: Boolean(value?.fullName) ? value?.fullName : '',
      phoneNumber: Boolean(value?.phone) ? value?.phone : '',
    });
    this.filterData = this.getFilterData();
  }

  public openDialog() {
    if (typeof this.dialogTemplateRef !== 'undefined') {
      this.dialogRef = this.dialog.open(this.dialogTemplateRef, {
        width: '425px',
        autoFocus: false,
      });

      this.applyCurrentFilter(this.facade.currentFilter);
    }
  }

  public getFilterData(): IDriversFilter {
    const { fio, phoneNumber } = this.filterForm.controls;
    const fullName = Boolean(fio.value) ? (fio.value as string).trim() : '';
    const phone = Boolean(phoneNumber.value) ? (phoneNumber.value as string).trim() : '';
    return { fullName, phone };
  }

  public apply() {
    this.filterData = this.getFilterData();
    this.filterChange.emit(this.filterData);
    this.dialogRef?.close();
  }
}
