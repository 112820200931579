import { Injectable, OnDestroy } from '@angular/core';
import { TnGqlClientSharedService } from '@transport/ui-store';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { BehaviorSubject, catchError, Observable, of, Subject, tap } from 'rxjs';
import { FormGroup } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import { VehicleModalComponent } from './vehicle-modal/vehicle-modal.component';
import { IVehicleFormFields } from './vehicle.interface';
import { VehicleCreateVm } from './form/vehicle-create.vm';
import { VehicleBaseVm } from './form/vehicle-base-vm';
import { VehicleEditVm } from './form/vehicle-edit.vm';
import { TnAlertService } from '@transport/ui-kit';
import { IAlertInputs } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { TOAST_DURATION, USER_ROLE } from '@transport/ui-interfaces';
import { TnModalAddTransportService } from 'libs/transport-notifications/src/lib/components/modal-add-transport/modal-add-transport.service';
import { TnDomainService } from 'libs/transport-ui-services/src/lib/domain/domain.service';
import { VEHICLE_QUERIES } from './vehicle-queries';
import { ICommonSelectData } from 'libs/custom-controls/src/lib/common.interface';
import { TnSupportedCountry } from '@transport/ui-pipes';
import { IVehicleTable } from 'libs/vehicles/src/lib/table/vehicles-table.interface';
import moment from 'moment';
import { IVehicleBlacklistItem } from 'apps/transport/src/app/black-list/components/vehicle/vehicle-blacklist.interfaces';

export interface VehicleFormVm {
  id?: string;
  countries?: ICommonSelectData<TnSupportedCountry>[];
  translate: TranslateService;
  title: string;
  baseVm: VehicleBaseVm;
  actionBtnTxt: string;
  vehicle$: BehaviorSubject<IVehicleFormFields>;
  loading$: BehaviorSubject<boolean>;
  finish$: Subject<{ close: boolean; alert: IAlertInputs }>;
  form: FormGroup;
  action: () => void;
  dispose: () => void;
}

@UntilDestroy()
@Injectable()
export class VehicleService implements OnDestroy {
  public dialogRef?: MatDialogRef<any>;
  public vm: VehicleFormVm | undefined;
  public role: USER_ROLE | undefined;

  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    private readonly dialog: MatDialog,
    private readonly alertService: TnAlertService,
    private readonly modalAddTransportService: TnModalAddTransportService,
    private readonly domainService: TnDomainService,
    public readonly translate: TranslateService,
  ) {}

  public openModal(role: USER_ROLE = USER_ROLE.CARRIER, id?: string, type?: string) {
    this.role = role;
    if (id && type) {
      this.vm = new VehicleEditVm(id, type, this.sharedGraphQlClient, this.translate, this.domainService, role);
    } else {
      this.vm = new VehicleCreateVm(this.sharedGraphQlClient, this.translate, this.modalAddTransportService, this.domainService, role);
    }

    this.vm?.finish$.pipe(untilDestroyed(this)).subscribe(res => {
      res.close && this.close();
      this.alertService.openAlert(res.alert, TOAST_DURATION.LONG);
    });

    this.dialogRef = this.dialog.open<{ service: VehicleService }>(VehicleModalComponent, {
      maxWidth: '640px',
      maxHeight: '93vh',
      disableClose: true,
      data: {
        service: this,
      },
      autoFocus: false,
    });
  }

  public close() {
    this.dialogRef?.close();
  }

  public deleteFromBlackList(vehicle: IVehicleBlacklistItem, role: USER_ROLE) {
    return this.sharedGraphQlClient
      .mutate(role as USER_ROLE, VEHICLE_QUERIES.ExcludeVehicleFromBlackList, {
        input: {
          regNo: vehicle.regNo,
          country: vehicle.country,
        },
      })
      .pipe(
        catchError(err => {
          this.alertService.openBasicErrorAlert('shared.mp.vehicles.vehicle.messages.fromBlackListError');
          return of(null);
        }),
        tap(res => {
          if (res) this.alertService.openBasicSuccessAlert('shared.mp.vehicles.vehicle.messages.fromBlackListSuccess');
        }),
      );
  }

  public vehicleToBlackList(data: any, role: USER_ROLE): Observable<any> {
    return this.sharedGraphQlClient.mutate(role as USER_ROLE, VEHICLE_QUERIES.sendVehicleToBlackList, { input: data }).pipe(
      catchError(err => {
        this.alertService.openBasicErrorAlert('shared.mp.vehicles.vehicle.messages.toBlackListError');
        return of(null);
      }),
      tap(res => {
        if (res) {
          this.alertService.openBasicSuccessAlert('shared.mp.vehicles.vehicle.messages.toBlackListSuccess');
        }
      }),
    );
  }

  public sendVechicleToArchive(role: USER_ROLE, id: string): Observable<any> {
    return this.sharedGraphQlClient.mutate(role, VEHICLE_QUERIES.sendVechicleToArchive, { id }).pipe(
      catchError(err => {
        this.alertService.openBasicErrorAlert('shared.mp.vehicles.vehicle.messages.archiveError');
        return of(null);
      }),
      tap(res => {
        if (res) {
          this.alertService.openBasicSuccessAlert('shared.mp.vehicles.vehicle.messages.archiveSuccess');
        }
      }),
    );
  }

  public restoreVehicle(role: USER_ROLE, id: string): Observable<any> {
    return this.sharedGraphQlClient.mutate(role, VEHICLE_QUERIES.restoreVehicle, { id }).pipe(
      catchError(err => {
        if (err.message) {
          this.alertService.openBasicErrorAlert(err.message);
        } else {
          this.alertService.openBasicErrorAlert('shared.mp.vehicles.vehicle.messages.restoreError');
        }

        return of(null);
      }),
      tap(res => {
        if (res) {
          this.alertService.openBasicSuccessAlert('shared.mp.vehicles.vehicle.messages.restoreSuccess');
        }
      }),
    );
  }

  ngOnDestroy() {
    this.vm?.dispose();
  }
}
