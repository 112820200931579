/* eslint-disable max-lines-per-function */
/* eslint-disable complexity */
import { AbstractControl, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { transport } from '@transport/proto';

import { isEmptyList, trimmedStringValidator } from '../form-validators/forms';
import { vehicleRequirementsValidator } from '../form-validators/vehicle-requirements-order-form.validator';
import { IInsuranceOutputData } from '../insurance/insurance.interface';
import {
  createNewPlaceRequirementsWithNullableFields,
  createNewVehicleRequirementsNullableFields,
  IOrder,
  IPlaceRequirements,
} from '../order/order.interface';
import {
  copyCargosToDeliveryUnits,
  createNewOrderStoragePointFormValue,
  IOrderCargoFormValue,
  IOrderDeliveryUnitsFormValue,
  IOrderStoragePointFormValue,
  mapOrderCargosFormValueToInputDto,
  mapOrderDeliveryUnitsFormValueToInputDto,
  mapOrderStoragePointFormValueToInputDto,
} from '../storage-point';
import ITimezone = transport.ITimezone;
import { ICargoType } from '@transport/ui-interfaces';

import { TDirectoryEntityPredefinedAction } from '../directory';
import { IPublishOrderFormValue, ORDER_ALLOCATION_TYPE, ORDER_PUBLISH_TYPE_NEW } from '../order';

export enum ORDER_FORM_CONSTANTS {
  MAX_COMMENT_LENGTH = 150,
  MAX_EXTERNAL_NUMBER_LENGTH = 20,
}

/**
 * TODO: fix bad typing
 */
export class TnOwnerOrderFormData {
  public id = '';

  public tenderId = '';

  public cargo?: {
    cargoType: string;
    weight: number;
    volume: number;
    temperatureMode: number | null;
  };

  public cargos?: IOrderCargoFormValue[];

  public deliveryUnits?: IOrderDeliveryUnitsFormValue[] | null;

  public priceInfo: {
    price?: number | null;
    currency?: string | null;
    isVatIncluded: boolean;
  } = {
    price: 0,
    currency: null,
    isVatIncluded: true,
  };

  public paymentPeriod = '';

  public paymentType?: transport.IPaymentType | null;

  public cargoInsurance: IInsuranceOutputData | null | string = null;

  public publishType: {
    carrier?: string;
    carrierGroupId?: string;
    isMarketOrder: boolean;
  } = {
    isMarketOrder: false,
  };

  public comment = '';

  public truckInfo = '';

  public trailerInfo = '';

  public driverInfo = '';

  public contactPersons = [];

  public vehicleRequirements?: transport.Order.IVehicleRequirements;

  public cargoPlaceRequirements?: transport.Order.IPlaceRequirements;

  public loadingPlaces: IOrderStoragePointFormValue[] = [];

  public unloadingPlaces: IOrderStoragePointFormValue[] = [];

  public lot?: transport.ILot;

  public externalNo = '';

  public extraServices: transport.IExtraService[] = [];

  public customerOrganization?: transport.IAdminOrganization;

  public data: string = JSON.stringify({});

  public lvlViolation: string | null;

  public publishTypeNew?: IPublishOrderFormValue;

  /**
   * TODO: refactor, initialization should not happen in constructor
   */
  // eslint-disable-next-line complexity -- TODO: tech debt
  constructor(order: IOrder, clearStoragePointsDates = false, initDefaultLoadType = false, shouldUseLocalTime = false) {
    this.id = order.id ?? '';
    this.cargo = {
      cargoType: order.cargoType?.name ?? '',
      weight: order.weight ?? 0,
      volume: order.volume ?? 0,
      temperatureMode: order.temperatureMode ?? null,
    };
    const minStepPrice = 0;
    this.cargos = [];
    this.deliveryUnits = [];
    this.priceInfo = {
      price: order.price?.amount ?? 0,
      currency: order.price?.currency ?? null,
      isVatIncluded: order.isVatIncluded ?? false,
    };
    this.paymentPeriod = order.paymentPeriod ?? '';
    this.paymentType = order.paymentType;

    this.publishType = {
      carrier: order.carrier?.id ?? '',
      carrierGroupId: order.carrierGroup?.id ?? '',
      isMarketOrder: order.isMarket ?? false,
    };
    this.comment = order.comment ?? '';
    this.truckInfo = order.truckInfo ?? '';
    this.trailerInfo = order.trailerInfo ?? '';
    this.driverInfo = order.driverInfo ?? '';
    /**
     * @note TODO
     * this should be removed once backend starts returning json instead of string
     */
    this.cargoInsurance = (Boolean(order.insurance?.data) && order?.insurance?.data) || null;
    this.vehicleRequirements = createNewVehicleRequirementsNullableFields(order.vehicleRequirements ?? {});

    this.cargoPlaceRequirements = createNewPlaceRequirementsWithNullableFields({
      ...(order.cargoPlaceRequirements as IPlaceRequirements),
      cargoPackagesCount: order.cargoPackagesCount,
    });

    this.initPlaces(order, clearStoragePointsDates, initDefaultLoadType, shouldUseLocalTime);

    this.lot = order.lot ?? {};
    this.externalNo = Boolean(clearStoragePointsDates) ? '' : order.externalNo ?? '';
    this.extraServices = order.extraServices ?? [];
    this.customerOrganization = order.customerOrganization ?? {};
    this.lvlViolation = order.sla?.id ?? null;

    let publishType = ORDER_PUBLISH_TYPE_NEW.QUICK_ORDER;

    if (Boolean(order.carrier?.id) && !Boolean(order.carrierGroup)) {
      publishType = ORDER_PUBLISH_TYPE_NEW.SET_CARRIER;
    } else if (Boolean(order.lot) && typeof order?.lot === 'object' && Object.keys(order.lot as Record<string, unknown>)) {
      publishType = ORDER_PUBLISH_TYPE_NEW.AUCTION;
    }

    this.publishTypeNew = {
      minStepPrice,
      publishType,
      dateTime: order?.acceptDeadline,
      price: order.price?.amount,
      currency: order.price?.currency,
      isVatIncluded: order.isVatIncluded,
      allowCounterOffer: order.allowCounterOffer,
      carrierGroupId: order.carrierGroup?.id,
      carrier: order.carrier?.id,
      carrierAllAccredited: order?.carrierAllAccredited,
      isMarket: order.isMarket ?? false,
    };
  }

  private initPlaces(order: IOrder, clearStoragePointsDates = false, initDefaultLoadType = false, shouldUseLocalTime = false) {
    this.loadingPlaces = this.createPlaces(order.loadingPlaces ?? [], clearStoragePointsDates, initDefaultLoadType, shouldUseLocalTime);
    this.unloadingPlaces = this.createPlaces(order.unloadingPlaces ?? [], clearStoragePointsDates, initDefaultLoadType, shouldUseLocalTime);
  }

  private createPlaces(
    places: transport.Order.IStoragePoint[],
    clearStoragePointsDates = false,
    initDefaultLoadType = false,
    shouldUseLocalTime = false,
  ) {
    return places.map(item => {
      return createNewOrderStoragePointFormValue(item, clearStoragePointsDates, initDefaultLoadType, shouldUseLocalTime);
    });
  }
}

/**
 * Owner order form interface.
 */
export interface IOwnerOrderForm extends FormGroup {
  controls: {
    loadingPlaces: FormGroup;
    unloadingPlaces: FormGroup;
    cargo: AbstractControl;
    price: AbstractControl;
    cargoInsurance: AbstractControl;
    comment: AbstractControl;
    truckInfo: AbstractControl;
    trailerInfo: AbstractControl;
    driverInfo: AbstractControl;
    lot: FormGroup;
    vehicleRequirements: AbstractControl;
    cargoPlaceRequirements: AbstractControl;
    contacts: FormGroup;
    consigneeContacts: FormGroup;
    publishType: AbstractControl;
    externalNo: AbstractControl;
    paymentType: AbstractControl;
    paymentPeriod: AbstractControl;
    customerOrganization: AbstractControl;
    isInsurance: AbstractControl;
    tenderId: AbstractControl;
    lvlViolation: AbstractControl;
    publishTypeNew: AbstractControl;
    cargos?: FormGroup;
    deliveryUnits?: FormGroup;
  };
}

/**
 * New owner order form constructor type.
 * @param fb form builder
 * @param disabled Flag for disabling controls
 * @param emptyValue Flag for setting empty value for controls
 * @param editableItem editable item
 */
export type TNewOwnerOrderFormConstructor = (
  fb: FormBuilder,
  disabled: boolean,
  editableItem: TnOwnerOrderFormData,
  isAuction?: boolean,
  isCompanyGroup?: boolean,
) => IOwnerOrderForm;

/**
 * Owner order form constructor
 * @param fb form builder
 * @param disabled Flag for disabling controls
 * @param editableItem editable item
 */
// eslint-disable-next-line max-lines-per-function -- TODO: tech debt
export const newOwnerOrderForm: TNewOwnerOrderFormConstructor = (
  fb: FormBuilder,
  disabled: boolean,
  editableItem: TnOwnerOrderFormData,
  isAuction?: boolean,
  isCompanyGroup?: boolean,
): IOwnerOrderForm => {
  const controlsConfig: Record<string, unknown> = {
    loadingPlaces: [{ value: editableItem.loadingPlaces, disabled }, isEmptyList],
    unloadingPlaces: [{ value: editableItem.unloadingPlaces, disabled }, isEmptyList],
    cargo: [{ value: editableItem.cargo, disabled }],
    cargos: [{ value: editableItem.cargos, disabled }],
    deliveryUnits: [{ value: editableItem.deliveryUnits, disabled }],
    vehicleRequirements: [{ value: editableItem.vehicleRequirements, disabled }, vehicleRequirementsValidator],
    cargoPlaceRequirements: [{ value: editableItem.cargoPlaceRequirements, disabled }],
    price: [{ value: editableItem.priceInfo, disabled }],
    cargoInsurance: [{ value: editableItem.cargoInsurance, disabled }],
    comment: [
      { value: editableItem.comment, disabled },
      Validators.compose([trimmedStringValidator, Validators.maxLength(ORDER_FORM_CONSTANTS.MAX_COMMENT_LENGTH)]),
    ],
    truckInfo: [{ value: editableItem.truckInfo, disabled: true }],
    trailerInfo: [{ value: editableItem.trailerInfo, disabled: true }],
    driverInfo: [{ value: editableItem.driverInfo, disabled: true }],
    publishType: [
      {
        value: editableItem.publishType,
        disabled: false, // этот параметр не учитывается, форма дизаблится кастомным методом setDisabledStateExt
      },
    ],
    publishTypeNew: [
      {
        value: { ...editableItem.publishTypeNew } as IPublishOrderFormValue,
      },
    ],
    paymentType: [{ value: editableItem.paymentType, disabled }],
    paymentPeriod: [{ value: editableItem.paymentPeriod, disabled }],
    externalNo: [
      { value: editableItem.externalNo, disabled },
      Validators.compose([Validators.maxLength(ORDER_FORM_CONSTANTS.MAX_EXTERNAL_NUMBER_LENGTH)]),
    ],
    extraServices: [{ value: editableItem.extraServices, disabled }],
    customerOrganization: [
      {
        value: editableItem.customerOrganization,
        disabled: disabled || !Boolean(isCompanyGroup),
      },
    ],
    isInsurance: [null],
    tenderId: [null],
    lvlViolation: [{ value: editableItem.lvlViolation, disabled }],
  };
  if (Boolean(isAuction)) {
    controlsConfig.lot = [
      {
        value: { ...editableItem.lot, isVatIncluded: editableItem.priceInfo.isVatIncluded },
        disabled,
      },
    ];
  }
  const form = fb.group(controlsConfig) as IOwnerOrderForm;
  setTimeout(() => {
    // technical debt, it is necessary to patch the value after the data for the selector arrives in TnOwnerOrderLvlViolationComponent
    form.patchValue({ lvlViolation: editableItem.lvlViolation ?? null });
  });

  return form;
};

function getLoadingTypes(order: TnOwnerOrderFormData, isDraft) {
  if (isDraft) {
    const places = (order.loadingPlaces ?? []).concat(order.unloadingPlaces ?? []);
    const mapPlaces = new Map();

    places
      .filter(place => place.loadingType)
      .forEach(place => {
        const id = typeof place.loadingType === 'string' ? place.loadingType : (place.loadingType as transport.ILoadingType)?.id;
        mapPlaces.set(id, id);
      });

    return mapPlaces.size ? [...mapPlaces].map(el => el[1]).sort() : [];
  }
  return order?.vehicleRequirements?.loadingTypes ?? [];
}

// eslint-disable-next-line max-lines-per-function -- TODO: tech debt
export const mapOrderFormValueToInputDto = (
  form: FormGroup,
  originalId?: string,
  mode?: TDirectoryEntityPredefinedAction,
  isAuction?: boolean,
  isDraft?: boolean,
): transport.IAddOrderInput => {
  const formValue = form.getRawValue() as TnOwnerOrderFormData;

  const cargoPlaceRequirements = formValue.cargoPlaceRequirements;
  const cargoPackagesCount = cargoPlaceRequirements?.cargoPackagesCount;
  delete cargoPlaceRequirements?.cargoPackagesCount;

  const { ...vehicleRequirements } = formValue.vehicleRequirements ?? ({} as transport.Order.IVehicleRequirements);
  vehicleRequirements.loadingTypes = getLoadingTypes(formValue, isDraft);
  vehicleRequirements.temperatureModeFrom = vehicleRequirements.temperatureMode?.from;
  vehicleRequirements.temperatureModeTo = vehicleRequirements.temperatureMode?.to;
  delete vehicleRequirements?.temperatureMode;

  if (Boolean(vehicleRequirements.bodySubtype)) {
    vehicleRequirements.bodySubtype = (vehicleRequirements?.bodySubtype?.id ?? '') as transport.Vehicle.Body.ISubtype; // TODO: the api is weird
  }

  const { cargoType, weight, volume, temperatureMode } = formValue.cargo ?? {
    cargoType: '',
    weight: 0,
    volume: 0,
    temperatureMode: null,
  };
  /**
   * @note TODO: fix incorrect types.
   */

  let price, isVatIncluded, carrier, carrierGroupId, carrierAllAccredited, acceptDeadline;
  if ((mode === 'copy' || mode === 'create' || mode === 'edit') && !isAuction) {
    price = {
      amount: (formValue as any).publishTypeNew.price,
      currency: '₽',
    };
    isVatIncluded = (formValue as any).publishTypeNew.isVatIncluded;
    carrier = (formValue as any).publishTypeNew.carrier;
    carrierGroupId = (formValue as any).publishTypeNew.carrierGroupId;
    carrierAllAccredited = (formValue as any).publishTypeNew.carrierAllAccredited;
    acceptDeadline = (formValue as any).publishTypeNew.dateTime;
  } else {
    // При создании аукциона сервер берет price из lot.startPrice. значения цены при создании аукциона не используется
    price = {
      amount: (formValue as any).price.price,
      currency: '₽',
    };
    isVatIncluded = (formValue as any).price.isVatIncluded;
    carrier = formValue.publishType.carrier;
    carrierGroupId = formValue.publishType.carrierGroupId;
    carrierAllAccredited = !Boolean(carrier) && !Boolean(carrierGroupId) ? true : null;
  }
  // Автоматически привязывать грузы в последней точки разгрузки, если нет промежуточных точек разгрузки
  if (formValue.unloadingPlaces?.length === 1) {
    formValue.deliveryUnits = formValue.cargos?.map(item => copyCargosToDeliveryUnits(item, formValue.loadingPlaces?.length));
  }

  const cargos = formValue.cargos?.map(value => mapOrderCargosFormValueToInputDto(value));

  if (isDraft) {
    price = {
      amount: (formValue as any).price.price,
      currency: '₽',
    };
  }

  const res: transport.IAddOrderInput = {
    loadingPlaces: formValue.loadingPlaces.map(value => mapOrderStoragePointFormValueToInputDto(value)),
    unloadingPlaces: formValue.unloadingPlaces.map(value => mapOrderStoragePointFormValueToInputDto(value)),
    cargos: cargos,
    deliveryUnits: formValue.deliveryUnits?.map(value => mapOrderDeliveryUnitsFormValueToInputDto(value)),
    cargoType,
    weight,
    volume,
    temperatureMode,
    comment: formValue.comment,
    cargoPackagesCount,
    price,
    ...(Boolean(carrier) && { carrier }),
    ...(Boolean(carrierGroupId) && { carrierGroupId }),
    ...(Boolean(carrierAllAccredited) && { carrierAllAccredited }),
    ...(Boolean(acceptDeadline) && { acceptDeadline }),
    isVatIncluded,
    allowCounterOffer: formValue.publishTypeNew?.allowCounterOffer,
    paymentPeriod: formValue.paymentPeriod,
    paymentTypeId: formValue.paymentType?.id,
    vehicleRequirements: vehicleRequirements as transport.IVehicleRequirementsInput,
    cargoPlaceRequirements,
    externalNo: formValue.externalNo,
    extraServices: formValue.extraServices.map(s => s.id ?? ''),
    customerOrganization: (form.getRawValue() as TnOwnerOrderFormData).customerOrganization?.id,
    tenderId: formValue.tenderId,
    sla: formValue.lvlViolation,
  };
  if (formValue.cargoInsurance !== null) {
    res.insurance = (formValue.cargoInsurance ?? {}) as transport.IAddInsuranceInput;
  }

  if (Boolean(originalId)) {
    res.copyOfOrderId = originalId;
  }
  return res;
};

export function mapOrderFormValueToEditCargoTypesDto(cargos: IOrderCargoFormValue[]) {
  const res: Record<string, unknown>[] = [];
  cargos?.forEach((cargo: IOrderCargoFormValue) => {
    if (Boolean(cargo?.createdCargoId) && !res.find(val => val.id === cargo?.createdCargoId)) {
      res.push({
        id: cargo?.createdCargoId,
        name: cargo?.name,
        defaultWeight: cargo?.size?.weight,
        defaultVolume: cargo?.size?.volume,
        packagingType: cargo?.size?.packageType?.type,
        cargoPackagesCount: cargo?.size?.quantity,
        lengthCargoPackage: cargo?.size?.dimensions?.length,
        widthCargoPackage: cargo?.size?.dimensions?.width,
        heightCargoPackage: cargo?.size?.dimensions?.height,

        // TODO: can add it from formValue.vehicleRequirements
        // temperatureMode: null,
        // typeVehicle: null,
        // permittedLoadingTypes: null,
        // vehicleCapacity: null,
        // vehicleVolume: null,
        // vehicleLength: null,
        // vehicleWidth: null,
        // vehicleHeight: null,
      });
    }
  });

  return res;
}

export const isCreatedAfterRelease = (createdDate: string) => {
  const releaseDate = '2021-10-25T22:00:00+00:00';
  return createdDate > releaseDate;
};

//TO DO: удалить/модифицировать после реализации ЛТЛ на черновиках/интеграции
//Мапперы для осуществления совместимости старой версии черновиков с ЛТЛ после публикации
export function setSeqIdBeforePublish(orderData: transport.IAddOrderInput) {
  let placeCounter = 0;
  const mappedLoadingPlaces = orderData.loadingPlaces?.map((el, index) => {
    placeCounter++;
    return { ...el, seqId: index };
  });
  const mappedUnloadingPlaces = orderData.unloadingPlaces?.map(el => {
    el = { ...el, seqId: placeCounter };
    placeCounter++;
    return el;
  });
  orderData = { ...orderData, loadingPlaces: mappedLoadingPlaces, unloadingPlaces: mappedUnloadingPlaces };
  return orderData;
}

export function mapCargosArrayBeforePublish(orderData: transport.IAddOrderInput, cargoTypes: Partial<ICargoType>[] | undefined) {
  const { cargoPlaceRequirements, weight, volume, cargoPackagesCount } = orderData;
  const cargoFromDictionary = cargoTypes?.find(el => el.name === orderData.cargoType);
  const mappedCargos: transport.IOrderCargo[] = [
    {
      id: 0, //пока груз в черновиках всего 1
      loadingPlaceId: 0, //Груз всегда привязан к первому месту погрузки
      name: cargoFromDictionary?.name,
      size: {
        length: cargoPlaceRequirements?.length,
        width: cargoPlaceRequirements?.width,
        height: cargoPlaceRequirements?.height,
        packageType: cargoPlaceRequirements?.packagingType,
        weight: weight,
        volume: volume,
        quantity: cargoPackagesCount,
      },
    },
  ];
  return mappedCargos;
}

export function mapDeliveryUnitsArrayBeforePublish(orderData: transport.IAddOrderInput) {
  const { cargoPlaceRequirements, weight, volume, cargoPackagesCount } = orderData;

  const seqId = orderData.unloadingPlaces?.length ? orderData.unloadingPlaces[orderData.unloadingPlaces?.length - 1].seqId || 1 : 1;
  const mappedDeliveryUnits: transport.IOrderDeliveryUnit[] = [
    {
      cargoId: 0, //пока груз в черновиках всего 1
      deliveryPointId: seqId, // груз пока привязывается к последнему месту разгрузки
      size: {
        length: cargoPlaceRequirements?.length,
        width: cargoPlaceRequirements?.width,
        height: cargoPlaceRequirements?.height,
        packageType: cargoPlaceRequirements?.packagingType,
        quantity: cargoPackagesCount,
        weight: weight,
        volume: volume,
      },
    },
  ];
  return mappedDeliveryUnits;
}
