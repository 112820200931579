<transport-toolbar [tabs]="tabs" [selectedTabIndex]="$any(selectedTabIndex$ | async)" (tabsSelectionChanged)="onTabChange($event)">
  <div fxFlex fxLayoutAlign="end center">
    <transport-drivers-filter (filterChange)="onFilterChange($event)"></transport-drivers-filter>
    <transport-button
      *ngIf="$any(userFacade.permissions$ | async).generalReference?.manage"
      fxFlexOffset="8px"
      label="{{ 'shared.directory.drivers.actions.add' | translate }}"
      type="action"
      icon="plus"
      (action)="gotoDriverCreationPage()"
    ></transport-button>
  </div>
</transport-toolbar>
