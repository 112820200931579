import { Pipe, PipeTransform } from '@angular/core';
import { regExpConfig } from '@transport/ui-utils';
import memo from 'memo-decorator';
import { MaskApplierService } from 'ngx-mask';

/**
 * Pipe for phone number +7(999)123-45-67 or +7 999 123 45 67
 */
@Pipe({
  name: 'phoneNumber',
})
export class TnPhoneNumberPipe implements PipeTransform {
  constructor(private readonly maskService: MaskApplierService) {}

  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform(value: number | string = '', onlySpace = false): string {
    if (value === '' || value === null) {
      return '';
    }
    const val = typeof value === 'string' ? value : value.toString();
    let mask = regExpConfig.ngxMask.phone.ru.mask;
    let patterns = regExpConfig.ngxMask.phone.ru.patterns;
    if (onlySpace) {
      mask = regExpConfig.ngxMask.phone.ruSpace.mask;
      patterns = regExpConfig.ngxMask.phone.ruSpace.patterns;
    }
    return Boolean(value)
      ? this.maskService.applyMaskWithPattern(val, [mask, { ...patterns }])
      : '';
  }
}
