<transport-card-header-indicator *ngIf="hasIndicator">
  <div indicatorLabel><ng-content select="[indicator]"></ng-content></div>
</transport-card-header-indicator>
<div fxFlex="100" fxLayoutAlign="space-between center">
  <h2>{{ title | translate }}</h2>
  <h3>{{ title | translate }}</h3>
  <div fxLayoutAlign="space-between center">
    <span
      *ngIf="hasErrors"
      class="form-empty-warning"
      [ngClass]="{'error': shoudHighlightErrors}"
      >{{ 'shared.card.fillIn' | translate }}</span
    >
    <mat-icon
      class="collapsible-icon icon_small"
      [ngClass]="{'card-collapsed': collapsed}"
      [svgIcon]="collapsed ? 'chevron-down' : 'chevron-up'"
      *ngIf="collapsible"
      (click)="toggle()"
    ></mat-icon>
  </div>
</div>

<ng-container *ngIf="collapsible && !collapsed || !collapsible">
  <ng-content></ng-content>
</ng-container>
