<div fxLayoutAlign="center center" class="h-8 flex">
  <mat-icon *ngIf="isWarnMode" svgIcon="eye-outline"></mat-icon>
  <countdown-counter
    *ngIf="isWarnMode"
    [endDatetime]="endSessionTime"
    (expired)="showTimeExpiredDialog()"
  ></countdown-counter>
  <ng-content></ng-content>
</div>

<ng-template #timeExpired>
  <h1 mat-dialog-title fxLayout="row">
    <strong fxFlex>{{ 'shared.supervisor.supervisorTimeExpiredModal.title' | translate }}</strong>
    <button
      mat-icon-button
      matTooltip="{{ 'shared.actions.close' | translate }}"
      class="close-button"
      mat-dialog-close
      (click)="dialogRef?.close()"
    >
      <mat-icon class="material-icons dialog-close">close</mat-icon>
    </button>
  </h1>

  <div mat-dialog-content class="timer-expired-content">
    {{ 'shared.supervisor.supervisorTimeExpiredModal.content' | translate }}
  </div>
  <div mat-dialog-actions class="timer-expired-action">
    <button mat-raised-button color="primary" (click)="dialogRef?.close()" cdkFocusInitial>
      {{ 'shared.supervisor.supervisorTimeExpiredModal.confirm' | translate }}
    </button>
  </div>
</ng-template>
