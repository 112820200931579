<h2 mat-dialog-title>
  {{ 'shared.directory.loadingPlacesDetails.modals.daDataModal.title' | translate }}
</h2>
<mat-dialog-content class="mat-typography">
  <mat-progress-bar class="modal-sticky-progress-bar" *ngIf="$any(isLoading$ | async)" mode="indeterminate"></mat-progress-bar>

  <div fxLayout="row">
    <yamap-root
      fxLayout="column"
      fxFlex="70%"
      height="400px"
      (yamapReady)="mapReady()"
      [getCoordinatesByClick]="true"
      (getCoordinatesByClickChange)="getCoordinatesByClickChange($event)"
    ></yamap-root>

    <section *ngIf="!$any(isLoading$ | async)" fxLayoutGap="10px" fxLayout="column" fxFlex="30%" class="right-column">
      <div *ngIf="addressSuggestions$ | async as addressSuggestions">
        <ng-container *ngIf="addressSuggestions.length > 0">
          <mat-card
            *ngFor="let item of addressSuggestions; let i = index"
            fxLayout="row"
            (click)="selectAddress(item, i)"
            [attr.data-address-index]="i"
          >
            {{ item.fullAddress }}
          </mat-card>
        </ng-container>

        <ng-container *ngIf="addressSuggestions.length === 0">
          <div class="empty-list" fxLayout="row" fxLayoutAlign="center center">
            {{ 'shared.directory.loadingPlacesDetails.modals.daDataModal.emtpy' | translate }}
          </div>
        </ng-container>
      </div>
    </section>
  </div>
</mat-dialog-content>
<mat-dialog-actions align="end">
  <button mat-button mat-dialog-close>
    {{ 'shared.directory.loadingPlacesDetails.modals.daDataModal.cancel' | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="select()" [disabled]="isSelectButtonDisabled">
    {{ 'shared.directory.loadingPlacesDetails.modals.daDataModal.confirm' | translate }}
  </button>
</mat-dialog-actions>
