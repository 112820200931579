<mat-progress-bar class="table-sticky-progress-bar" *ngIf="isLoading$ | async" mode="indeterminate"></mat-progress-bar>

<transport-toolbar [ngClass]="{'mat-elevation-z8': (isLoading$ | async)}">
  <div fxFlex="100" fxLayoutAlign="space-between center">
    <transport-back-to-list-button
      [title]="backButtonTitle"
      (goBack)="navigateBack()"
      dataTest="driver-page"
    > </transport-back-to-list-button>

    <transport-entity-flow-buttons
      *ngIf="$any(userFacade.permissions$ | async).generalReference?.manage && !(isLoading$ | async)"
      [actions]="actions"
      (copy)="copy()"
      (edit)="edit()"
      (remove)="remove()"
      (save)="save()"
      dataTest="driver-page"
    ></transport-entity-flow-buttons>
  </div>
</transport-toolbar>

<div class="scrollable-container">
  <form class="detail-content" [ngClass]="{ 'view-mode': isReadOnlyMode }" *ngIf="!(isLoading$ | async)" [formGroup]="$any(formData)">

    <transport-black-list-section
      *ngIf="isReadOnlyMode"
      [type]="blackListType.DRIVER"
      [list]="blackListInfo"
    ></transport-black-list-section>

    <transport-driver-form
      formControlName="driver"
      [countries]="pageData?.select?.countries"
      [isDisabled]="isReadOnlyMode"
      [usesDriverMobile]="pageData?.driver?.usesDriverMobile"
      [hasBeenInvitedToDriverMobile]="pageData?.driver?.hasBeenInvitedToDriverMobile"
      [isShowInvite]="
        !pageData?.driver?.usesDriverMobile && !pageData?.driver?.isArchived && mode === 'view'
      "
      [mode]="mode"
      (inviteDriver)="inviteDriver()"
      dataTest="driver-page"
    >
      <ng-container *ngIf="isAgentWorkScheme">
        <mat-form-field fxFlex="1 1 auto">
          <ng-select
            transportNgSelectMat
            formControlName="agentCarrier"
            placeholder="{{ 'carrier.directory.driver.form.agentCarrier' | translate }}"
            bindLabel="name"
            bindValue="id"
            [items]="$any(agentCarriers$ | async)"
            (search)="searchAgentCarrier($event)"
            (clear)="clearAgentCarrier()"
            [required]="true"
            data-test="driver-page-agent-carrier"
          >
            <ng-template ng-option-tmp let-item="item" let-item$="item$" let-index="index">
              <span transportTooltip [context]="{ message: item.name }">{{ item.name }}</span>
            </ng-template>
          </ng-select>
          <mat-error *ngIf="formData?.controls?.agentCarrier?.errors?.required">
            {{ 'shared.errors.required' | translate }}
          </mat-error>
        </mat-form-field>
      </ng-container>
    </transport-driver-form>
  </form>
</div>
