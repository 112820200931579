export const sidebarLocalization = {
  sidebar: {
    items: {
      title: {
        orders: 'Заявки',
        monitoring: 'Мониторинг',
        routing: 'Маршрутизация',
        tenders: 'Тендеры',
        tenderTn: 'Тендеры ТН',
        quotas: 'Квоты',
        reports: 'Отчеты',
        reconciliationActs: 'Акты сверки',
        organizationProfile: 'Настройки',
        usersManagement: 'Сотрудники',
        cargoTypes: 'Типы грузов',
        vehicleTemplates: 'Шаблоны ТС',
        loadingUnloadingPlaces: 'Адреса',
        trustedCarriers: 'Перевозчики',
        trusted: 'Доверенные',
        onAccreditation: 'На аккредитации',
        sla: 'Уровни обслуживания',
        integration: 'Интеграция',
        directory: 'Справочники',
        notifications: 'Уведомления',
        support: 'Поддержка',
        userAccount: 'Аккаунт',
        blackList: 'Черные списки',
      },
    },
  },
};
