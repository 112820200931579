import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, DoCheck, ElementRef, HostBinding, Input, OnDestroy, OnInit, Optional, Self } from '@angular/core';
import { ControlContainer, ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TnBaseMatInputDirective } from '@transport/ui-components';
import {
  INN_LEGAL_LENGTH,
  INN_PHYS_LENGTH,
  KEYDOWN_DELAY,
  MAX_LIST_ORGANIZATION,
  MAX_ORGANIZATION,
  MIN_ORGANIZATION,
  ORG_CAN_CREATE,
} from '@transport/ui-utils';
import { TnServicePassportComponent } from 'libs/transport-passport/src/lib/services/transport-passport.service';
import { debounceTime, filter } from 'rxjs/operators';

@UntilDestroy()
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- Tech dept // We don't know how to track when its outside FromControl gets "touched" status.
@Component({
  selector: 'transport-autocomplete-contractor',
  templateUrl: './autocomplete-contractor.component.html',
  styleUrls: ['./autocomplete-contractor.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TnAutocompleteContractorComponent,
    },
  ],
})
export class TnAutocompleteContractorComponent
  extends TnBaseMatInputDirective
  implements OnInit, ControlValueAccessor, OnDestroy, DoCheck, MatFormFieldControl<unknown> {
  @Input() public clearInputAfterSelect = false;

  @Input() public inputClass = '';

  @Input() public requiredInput = false;

  public static nextId = 0;

  @HostBinding('attr.id')
  public id = `autocomplete-contractor-input-${(TnAutocompleteContractorComponent.nextId += 1)}`;

  public orgControl = new FormControl('', [Validators.minLength(MIN_ORGANIZATION), Validators.maxLength(MAX_ORGANIZATION)]);

  public filteredOptions: { inn: string; name: string; address: string }[] = [];

  // private inn = '';
  private company = null;

  private skipChanged = false;

  private selectedInn = false;

  constructor(
    public fm: FocusMonitor,
    public elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef,
    private readonly controlContainer: ControlContainer,
    private readonly servicePassportComponent: TnServicePassportComponent,
  ) {
    super(fm, elRef, ngControl);
  }

  public ngOnInit() {
    this.orgControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(KEYDOWN_DELAY),
        filter(data => typeof data === 'string'),
      )
      .subscribe(value => {
        if (this.skipChanged) {
          this.skipChanged = false;
          return false;
        }
        this.inputValue = void 0;
        if (Boolean(value) && value.length >= MIN_ORGANIZATION && value.length <= MAX_ORGANIZATION) {
          this.getData(value);
        }
        this.value = value;
        if (!Boolean(value)) {
          // Если отправили пустоту то передать пустоту главному контролеру формы
          this.propagateChange(value);
        }
        this.setCompanyInfo(value);
        this.selectedInn = false;
        return true;
      });
  }

  public ngDoCheck() {
    if (Boolean(this.ngControl.touched) && !this.orgControl.touched) this.orgControl.markAsTouched();
  }

  private getData(val: string) {
    this.servicePassportComponent.getOrganizations(val).subscribe(res => {
      this.filteredOptions = res.slice(0, MAX_LIST_ORGANIZATION);
      this.cdr.detectChanges();
    });
  }

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    this.skipChanged = true;
    this.selectedInn = true;
    this.orgControl.setValue(event.option.value.name);
    this.setCompanyInfo(event.option.value);
  }

  private setCompanyInfo(val) {
    if (Boolean(val)) {
      this.company = val;
      this.propagateChange(val);
      this.orgControl.setErrors(null);
    } else {
      this.propagateChange(null);
    }
    this.cdr.detectChanges();
  }

  public onBlurOrgInput(ev) {
    // Если ввели инн и не выбрали из списка. То вывести ошибку
    if (!this.selectedInn && Boolean(this.orgControl.value)) {
      this.orgControl.setErrors({ notSelected: true });
      this.setCompanyInfo(null);
    }
  }
}
