import { FocusMonitor } from '@angular/cdk/a11y';
import { ChangeDetectorRef, Component, DoCheck, ElementRef, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Optional, Output, Self } from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, Validators } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TnBaseMatInputDirective } from '@transport/ui-components';
import {
  INN_LEGAL_LENGTH,
  INN_PHYS_LENGTH,
  isValidINN,
  KEYDOWN_DELAY,
  MAX_LIST_ORGANIZATION,
  MAX_ORGANIZATION,
  MIN_ORGANIZATION,
  ORG_CAN_CREATE,
} from '@transport/ui-utils';
import { TnServicePassportComponent } from 'libs/transport-passport/src/lib/services/transport-passport.service';
import { debounceTime, filter, startWith } from 'rxjs/operators';

@UntilDestroy()
// eslint-disable-next-line @angular-eslint/prefer-on-push-component-change-detection -- Tech dept // We don't know how to track when its outside FromControl gets "touched" status.
@Component({
  selector: 'transport-autocomplete',
  templateUrl: './autocomplete.component.html',
  styleUrls: ['./autocomplete.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TnAutocompleteComponent,
    },
  ],
})
export class TnAutocompleteComponent
  extends TnBaseMatInputDirective
  implements OnInit, ControlValueAccessor, OnDestroy, DoCheck, MatFormFieldControl<unknown> {
  public static nextId = 0;

  @Input() public clearInputAfterSelect = false;
  @Input() public checkExist = true;
  @Input() public filterByLegalForm: string = '';

  @Input() public matIcon = '';
  @Output() public optionSelectedChange = new EventEmitter<any>();

  @HostBinding('attr.id')
  public id = `autocomplete-input-${(TnAutocompleteComponent.nextId += 1)}`;

  public orgControl = new FormControl('', [
    // Validators.minLength(MIN_ORGANIZATION),
    Validators.maxLength(MAX_ORGANIZATION),
  ]);

  public filteredOptions: { inn: string; name: string; address: string }[] = [];

  private inn = '';

  private skipChanged = false;

  private selectedInn = false;

  constructor(
    public fm: FocusMonitor,
    public elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private readonly cdr: ChangeDetectorRef,
    private readonly servicePassportComponent: TnServicePassportComponent,
  ) {
    super(fm, elRef, ngControl);
  }

  private get isValidCurrentINN(): boolean | null {
    if (
      Boolean(Number(this.orgControl.value)) &&
      (this.orgControl.value.length === INN_PHYS_LENGTH || this.orgControl.value.length === INN_LEGAL_LENGTH)
    ) {
      return isValidINN(this.orgControl.value);
    }
    return null;
  }

  public ngOnInit() {
    this.orgControl.valueChanges
      .pipe(
        untilDestroyed(this),
        debounceTime(KEYDOWN_DELAY),
        filter(data => typeof data === 'string'),
      )
      .subscribe(value => {
        if (this.skipChanged) {
          this.skipChanged = false;
          return false;
        }
        this.inputValue = void 0;
        if (Boolean(value) && value.length >= MIN_ORGANIZATION && value.length <= MAX_ORGANIZATION) {
          this.getData(value);
        }
        this.value = value;
        if (!Boolean(value)) {
          // Если отправили пустоту то передать пустоту главному контролеру формы
          this.propagateChange(value);
        }
        this.setInn(value);
        this.selectedInn = false;
        return true;
      });
  }

  public ngDoCheck() {
    if (Boolean(this.ngControl.touched) && !this.orgControl.touched) this.orgControl.markAsTouched();
  }

  private getData(val: string) {
    this.servicePassportComponent.getOrganizations(val, this.filterByLegalForm || null).subscribe(res => {
      if (res.length === 0) {
        this.orgControl.setErrors({ organizationsNotFound: true });
      }

      this.filteredOptions = res.slice(0, MAX_LIST_ORGANIZATION);
      this.cdr.detectChanges();
    });
  }

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    this.optionSelectedChange.emit(event.option.value);
    this.skipChanged = true;
    this.selectedInn = true;
    this.orgControl.setValue(!this.clearInputAfterSelect ? event.option.value.name : '');
    this.setInn(event.option.value.inn);
    this.orgIsExist();
  }

  private setInn(val) {
    if (Number(val)) {
      if (val.length === INN_PHYS_LENGTH || val.length === INN_LEGAL_LENGTH) {
        this.inn = val;
        this.propagateChange(val);
        this.orgControl.setErrors(null);
      } else {
        this.propagateChange(null);
        this.orgControl.setErrors({ invalid: true });
      }
    } else {
      this.propagateChange(null);
    }
    this.cdr.detectChanges();
  }

  public onBlurOrgInput(ev) {
    if (!this.orgControl.value) {
      // this.orgControl.setErrors({
      //   required: true,
      // });
      this.setInn(null);
      return;
    }
    // Если ввели инн и не выбрали из списка. Проверяем на регистрацию такой компании
    if (this.isValidCurrentINN) {
      this.orgControl.markAsTouched();
      return this.orgIsExist();
    }
    if (!this.selectedInn) {
      this.orgControl.setErrors({
        notSelected: true,
        organizationsNotFound: this.filteredOptions.length === 0,
      });
      this.setInn(null);
    }
  }

  private orgIsExist() {
    if (Boolean(this.inn) && this.checkExist) {
      this.servicePassportComponent.getOrganizationNameByInn(this.inn).subscribe(res => {
        if (Boolean(res?.status !== ORG_CAN_CREATE)) {
          this.orgControl.setErrors({ isExist: true });
          this.setInn(null);
        }
      });
    }
  }
}
