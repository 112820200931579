import { DatePipe } from '@angular/common';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { MaskApplierService } from 'ngx-mask';

import { TnCargoParams } from './cargo-params/cargo-params.pipe';
import { TnCargoSizePipe } from './cargo-type/cargo-size.pipe';
import { TnCargoPackagingTypePipe } from './cargo-type/cargo-type-packaging-type.pipe';
import { TnCurrencyPipe } from './currency/currency.pipe';
import { TnMultiCurrencyPipe } from './currency/multi-currency.pipe';
import { TnFioPipe } from './fio/fio.pipe';
import { TnLoadingPlaceSelectPipe } from './loading-place-select/loading-place-select.pipe';
import { TnPhonePipe } from './phone/phone-number.pipe';
import { TnPhoneNumberPipe } from './phone-number/phone-number.pipe';
import { TnStsNumberPipe } from './sts-number/sts-number.pipe';
import { TnTimeDurationPipe } from './time-duration/time-duration.pipe';
import { TnToolbarCounterPipe } from './toolbar-counter/toolbar-counter.pipe';
import { TnBlackListTypePipe } from './trucks/black-list-type.pipe';
import { TnBlackListViewDataPipe } from './trucks/black-list-view-data.pipe';
import { TnBlockedTrailerByNomenclaturePipe } from './trucks/blocked-trailer-by-nomenclature.pipe';
import { TnTruckTypePipe } from './trucks/truck-type.pipe';
import { TnVerificationStatusPipe } from './verification-status/verification-status.pipe';

export const tnUiPipesModuleProviders: Provider[] = [
  DatePipe,
  {
    provide: TnPhoneNumberPipe,
    useFactory: (maskApplier: MaskApplierService) => new TnPhoneNumberPipe(maskApplier),
    deps: [MaskApplierService],
  },
  {
    provide: TnStsNumberPipe,
    useFactory: (maskApplier: MaskApplierService) => new TnStsNumberPipe(maskApplier),
    deps: [MaskApplierService],
  },
  {
    provide: TnCurrencyPipe,
    useFactory: (maskApplier: MaskApplierService) => new TnCurrencyPipe(maskApplier),
    deps: [MaskApplierService],
  },
  {
    provide: TnMultiCurrencyPipe,
    useFactory: (maskApplier: MaskApplierService) => new TnMultiCurrencyPipe(maskApplier),
    deps: [MaskApplierService],
  },
  {
    provide: TnTruckTypePipe,
    useFactory: (translate: TranslateService) => new TnTruckTypePipe(translate),
    deps: [TranslateService],
  },
  {
    provide: TnVerificationStatusPipe,
    useFactory: (translate: TranslateService) => new TnVerificationStatusPipe(translate),
    deps: [TranslateService],
  },
  TnFioPipe,
  TnLoadingPlaceSelectPipe,
  TnBlockedTrailerByNomenclaturePipe,
  TnBlackListViewDataPipe,
  TnToolbarCounterPipe,
];

/**
 * Transport shared pipes module.
 */
@NgModule({
  declarations: [
    TnPhoneNumberPipe,
    TnPhonePipe,
    TnCargoParams,
    TnStsNumberPipe,
    TnFioPipe,
    TnTruckTypePipe,
    TnLoadingPlaceSelectPipe,
    TnCurrencyPipe,
    TnMultiCurrencyPipe,
    TnVerificationStatusPipe,
    TnTimeDurationPipe,
    TnBlockedTrailerByNomenclaturePipe,
    TnBlackListTypePipe,
    TnBlackListViewDataPipe,
    TnToolbarCounterPipe,
    TnCargoPackagingTypePipe,
    TnCargoSizePipe,
  ],
  exports: [
    TnPhoneNumberPipe,
    TnPhonePipe,
    TnCargoParams,
    TnStsNumberPipe,
    TnFioPipe,
    TnTruckTypePipe,
    TnLoadingPlaceSelectPipe,
    TnCurrencyPipe,
    TnMultiCurrencyPipe,
    TnTimeDurationPipe,
    TnVerificationStatusPipe,
    TnBlockedTrailerByNomenclaturePipe,
    TnBlackListTypePipe,
    TnBlackListViewDataPipe,
    TnToolbarCounterPipe,
    TnCargoPackagingTypePipe,
    TnCargoSizePipe,
  ],
  providers: [...tnUiPipesModuleProviders],
})
export class TnUiPipesModule {
  public static forRoot(): ModuleWithProviders<TnUiPipesModule> {
    return {
      ngModule: TnUiPipesModule,
      providers: [...tnUiPipesModuleProviders],
    };
  }
}
