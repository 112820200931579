import { createReducer, on } from '@ngrx/store';

import { adminProfileActions } from './admin-profile.actions';
import { adminProfileInitialState } from './admin-profile.state';

const adminProfileReducerCreator = createReducer(
  adminProfileInitialState,
  on(adminProfileActions.loadAdminProfileSuccess, (state, { profile }) => {
    return {
      ...state,
      reactiveFormValue: {
        ...profile,
        legalForm: profile.legalForm ?? null,
        paymentType: profile.paymentType ?? {},
        auctionProlongationParameters: profile.auctionProlongationParameters ?? {},
        auctionLotStepParameters: profile.additionalAuctionParameters
          ? {
              typeOfPriceStep: profile.additionalAuctionParameters.typeOfPriceStep,
              minStepPrice: profile.additionalAuctionParameters.minStepPrice,
              minPriceStepPercent: profile.additionalAuctionParameters.minPriceStepPercent,
            }
          : {},
        earlyFinish: profile.additionalAuctionParameters?.earlyFinish,
        insuranceParameters: profile.insuranceParameters ?? {},
        vat: profile.vat?.id,
        currency: profile.currency,
        facsimileImg: profile.facsimileImg,
      },
      loading: false,
    };
  }),

  on(adminProfileActions.loadAdminProfileStart, state => ({
    ...state,
    loading: true,
  })),

  on(adminProfileActions.loadAdminProfileSubOrganizationsSuccess, (state, { subOrganizations }) => ({
    ...state,
    subOrganizations,
    loading: false,
  })),
);

export function adminProfileReducer(state, action) {
  return adminProfileReducerCreator(state, action);
}
