<div class="card-select-row" fxLayout="column" *ngIf="driverSetted" (click)="changeDriver()">
  <header fxLayout="row">
    <div class="action-icon" fxLayout="column" fxLayoutAlign="center center">
      <mat-icon [svgIcon]="'account-card-details'" class="material-icons"></mat-icon>
    </div>
    <div class="title-head">{{ title || 'owner.order.driverTitle' | translate }}</div>
    <div class="mat-menu" data-test="select-driver-menu" *ngIf="!disabled" transportClickStopPropagation>
      <button
        mat-icon-button
        #menuTrigger="matMenuTrigger"
        [matMenuTriggerFor]="menu"
        title="{{ 'shared.orders.table.column.actions' | translate }}"
        [attr.data-test]="dataTest + 'actions-btn'"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item (click)="changeDriver()" [attr.data-test]="dataTest + 'change-driver'">
          <mat-icon class="material-icons">cached</mat-icon>
          {{ 'shared.chooseDriverDialog.actions.change' | translate }}
        </button>
        <button mat-menu-item (click)="clearDriver()" [attr.data-test]="dataTest + '-clear-driver'">
          <mat-icon class="material-icons">delete_forever</mat-icon>
          {{ 'shared.chooseDriverDialog.actions.delete' | translate }}
        </button>
      </mat-menu>
    </div>
  </header>
  <div class="card-container" fxLayout="column" fxLayoutGap="8px" [attr.data-test]="dataTest + '-selected-driver-data'">
    <div class="property bold">
      <div>{{ driver?.surname }}</div>
      <div>{{ fullname }}</div>
    </div>
    <div
      *ngIf="showAppInfo"
      class="property application"
      fxLayout="row"
      matTooltip="{{
        'shared.directory.drivers.table.tooltip.application.' +
          (driver?.usesDriverMobile ? 'installed' : 'notInstalled') | translate
      }}"
    >
      <span class="title">{{ 'carrier.order.driverCard.application' | translate }}&nbsp;</span>
      <mat-icon
        class="driver-status"
        [svgIcon]="
          driver?.usesDriverMobile
            ? 'application-installed-outlined'
            : 'application-not-installed-outlined'
        "
      >
      </mat-icon>
      <span class="driver-invite" *ngIf="!driver?.usesDriverMobile" (click)="invite($event)">{{
        inviteDriverText | translate
      }}</span>
    </div>
    <div class="property" *ngIf="driver?.phone as phone">
      <span class="title">{{ 'carrier.order.driverCard.phone' | translate }}&nbsp;</span>{{ phone | phoneNumber }}
    </div>
    <div class="property" *ngIf="passport">
      <span class="title">{{ 'carrier.order.driverCard.passport' | translate }}&nbsp;</span>
      <span
        *ngIf="this.driver?.passportSeries"
        matTooltip="{{ 'carrier.order.driverCard.passportSeries' | translate }} {{
          this.driver?.passportSeries
        }}"
        >{{ this.driver?.passportSeries }}</span
      >
      <span
        *ngIf="this.driver?.passportNumber"
        matTooltip="{{ 'carrier.order.driverCard.passportNumber' | translate }} {{
          this.driver?.passportNumber
        }}"
        >,&nbsp;{{ this.driver?.passportNumber }}</span
      >
      <div *ngIf="this.driver?.passportPlace" matTooltip="{{ 'carrier.order.driverCard.passportPlace' | translate }}">
        {{ this.driver?.passportPlace }}
      </div>
    </div>
    <div class="property" *ngIf="license">
      <span class="title">{{ 'carrier.order.driverCard.license' | translate }}&nbsp;</span>
      <span
        *ngIf="this.driver?.licenceSeries"
        matTooltip="{{ 'carrier.order.driverCard.licenseSeries' | translate }} {{
          this.driver?.licenceSeries
        }}"
        >{{ this.driver?.licenceSeries }}</span
      >
      <span
        *ngIf="this.driver?.licenceNumber"
        matTooltip="{{ 'carrier.order.driverCard.licenseNumber' | translate }} {{
          this.driver?.licenceNumber
        }}"
        >,&nbsp;{{ this.driver?.licenceNumber }}</span
      >
    </div>
  </div>
  <ng-content></ng-content>
</div>

<div
  class="card-select-row empty"
  fxLayout="column"
  fxLayoutAlign="center center"
  fxLayoutGap="16px"
  [attr.data-test]="dataTest + 'select-driver-button'"
  *ngIf="!driverSetted"
  (click)="changeDriver()"
>
  <div class="action-icon" fxLayout="column" fxLayoutAlign="center center">
    <mat-icon [svgIcon]="'account-card-details'" class="material-icons"></mat-icon>
  </div>
  <div>{{ label || 'Назначьте водителя' }}</div>
</div>
