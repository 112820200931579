import { Component, EventEmitter, Input, Output } from '@angular/core';
import { AbstractControl, FormGroup, ValidationErrors } from '@angular/forms';

@Component({
  selector: 'common-date-picker[formGroup][controlName]',
  templateUrl: './common-date-picker.component.html',
})
export class CommonDatePickerComponent {
  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public inputFieldClass = '';
  @Input() public label?: string = '';
  @Input() public labelClasses?: string[];
  @Input() public labelAddition?: string;
  @Input() public caption?: string;
  @Input() public captionClasses?: string[];
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public minDate: any;
  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  @Input() public formGroup!: FormGroup;
  @Input() public controlName: string = '';
  @Input() public placeholder: string = '';
  @Input() public dataTest: string = '';

  @Input() public direction: 'row' | 'column' = 'column';
  @Input() public allowClear = false;

  @Output() public readonly clear = new EventEmitter();

  private inputKeyUpValue: string | null = null;

  constructor() {}

  public get formControl(): AbstractControl {
    return this.formGroup.get(this.controlName)!;
  }

  public get isEmpty(): boolean {
    return !(Boolean(this.inputKeyUpValue) || Boolean(this.formControl?.value));
  }

  public get isDisabled(): boolean {
    return Boolean(this.formControl.disabled);
  }

  public get isError(): boolean {
    return Boolean(this.formControl.hasError('matDatepickerParse'));
  }

  public onKeyUp(event: Event) {
    const value = (event?.target as HTMLInputElement).value;
    const newValue = value.replace(/[^0-9.]{0,10}/g, '');
    this.inputKeyUpValue = newValue;
    this.formControl.markAsTouched();
    if (newValue.length !== value.length) {
      (event?.target as HTMLInputElement).value = newValue ?? null;
      if (newValue) { // Не валидировать пустое значение input
        this.formControl?.setValue(moment(newValue).format('DD.MM.yyyy'));
        this.formControl?.updateValueAndValidity();
      } else { // Устанавливая null убираем ошибку matDatepickerParse для пустого поля
        this.formControl?.setValue(null);
        this.formControl?.updateValueAndValidity();
      }
    }
  }

  public clearSelect(e): void {
    e.stopPropagation();
    this.formControl.markAsTouched();
    this.formControl?.setValue(null);
    this.inputKeyUpValue = null;
    this.clear.emit(null);
  }
}
