import { APP_BASE_HREF } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, Inject, NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { EffectsModule } from '@ngrx/effects';
import { NavigationActionTiming, StoreRouterConnectingModule } from '@ngrx/router-store';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { TranslateModule } from '@ngx-translate/core';
import { TransportConfigService } from '@transport/common-utils';
import { TnNotificationsModule } from '@transport/notifications';
import { SidebarModule } from '@transport/sidebar';
import { TnUiLayoutModule } from '@transport/transport-ui-layout';
import { TnUiComponentsModule } from '@transport/ui-components';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { APP_ENV } from '@transport/ui-interfaces';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { TnClientTimeInterceptor, TnUiServicesModule } from '@transport/ui-services';
import {
  DEVTOOLS_EXTENSIONS_CHECKER,
  IDevtoolsExtensionsChecker,
  ITnState,
  metaReducers,
  ngrxErrorInterceptorProvider,
  routerFeatureKey,
  TnAuthTokenInterceptorService,
  TnClSsoFrameModule,
  TnCustomRouteSerializer,
  TnGlobalErrorHandlerService,
  TnTMSSubdomainInterceptorService,
  TnUiStoreModule,
} from '@transport/ui-store';
import { TnUiTranslateModule } from '@transport/ui-translate';
import { ngxMaskConfig } from '@transport/ui-utils';
import { NotificationsModule } from 'libs/notifications/src/lib/notifications.module';
import { OrderQueryInterceptorService } from 'libs/order/order-shared/src/lib/services/api-tracking/query.interceptor.service';
import { TnAnalyticsInterceptorService } from 'libs/transport-ui-store/src/lib/current-user/services/analytics/analytics.interceptor.service';
import { NgxMaskModule } from 'ngx-mask';

import { TnAppComponent } from './app.component';
import { TnAppRoutingModule } from './app-routing.module';
import { AuthTokenService } from './auth-token.service';
import { environment } from './environment';
import { MobilePlaceholderModal } from './mobile-placeholder/mobile-placeholder.component';
import { StoragePointService } from 'apps/transport/src/app/shared/services/storage-point.service';
import { DriversModule } from '@transport/drivers';
import { VehiclesModule } from '@transport/vehicles';
import { DriverService } from 'libs/marketplace-shared/src/lib/edit-driver/driver.service';

/**
 * Transport main application module.
 */
@NgModule({
  declarations: [TnAppComponent, MobilePlaceholderModal],
  imports: [
    SidebarModule,
    BrowserModule,
    BrowserAnimationsModule,
    TnClSsoFrameModule,
    DriversModule,
    VehiclesModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    NgxMaskModule.forRoot(ngxMaskConfig),
    TranslateModule.forRoot(),
    TnUiLayoutModule,
    TnUiComponentsModule,
    TnUiDirectivesModule,
    TnUiTranslateModule.forRoot(),
    TnUiMaterialModule.forRoot(),
    TnUiPipesModule.forRoot(),
    TnUiServicesModule.forRoot(),
    TnUiStoreModule.forRoot(),
    StoreModule.forRoot<Partial<ITnState>>(
      {},
      {
        metaReducers: metaReducers,
        runtimeChecks: {
          strictStateImmutability: true,
          strictActionImmutability: true,
        },
      },
    ),
    EffectsModule.forRoot([]),
    environment.production
      ? []
      : StoreDevtoolsModule.instrument({
          name: 'Transport Store',
          maxAge: 125,
          logOnly: true,
        }),
    TnAppRoutingModule,
    StoreRouterConnectingModule.forRoot({
      stateKey: routerFeatureKey,
      serializer: TnCustomRouteSerializer,
      navigationActionTiming: NavigationActionTiming.PostActivation,
    }),
    TnNotificationsModule,
    NotificationsModule,
    TnUiKitModule,
  ],
  providers: [
    { provide: APP_BASE_HREF, useValue: '/' },
    { provide: APP_ENV, useValue: environment },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TnAuthTokenInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TnTMSSubdomainInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TnClientTimeInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TnAnalyticsInterceptorService,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: OrderQueryInterceptorService,
      multi: true,
    },
    {
      provide: APP_INITIALIZER,
      deps: [TransportConfigService, AuthTokenService],
      multi: true,
      useFactory: (configService: TransportConfigService, ats: AuthTokenService) => async () => {
        await configService.loadConfig(environment);
        await ats.init();
      },
    },
    StoragePointService,
    TransportConfigService,
    ngrxErrorInterceptorProvider,
    { provide: ErrorHandler, useClass: TnGlobalErrorHandlerService },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [TnAppComponent],
})
export class TnAppModule {
  constructor(
    @Inject(DEVTOOLS_EXTENSIONS_CHECKER)
    private readonly extensionsChecker: IDevtoolsExtensionsChecker,
  ) {
    this.extensionsChecker.checkReduxDevtoolsExtension();
  }
}
