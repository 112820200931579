import { InjectionToken } from '@angular/core';
import { transport } from '@transport/proto';

/**
 * Websocket key enumerator.
 */
export enum WEBSOCKET_KEY {
  EVENTS = '/ws/notifications/',
}

/**
 * Websocket notifications data topic type.
 */
export type TTnWebsocketEventDataTopic =
  | 'notification'
  | 'order'
  | 'auction'
  | 'bidding'
  | 'termination_agreement'
  | 'acts_comparison'
  | 'organization_market_attrs'
  | 'vehicle'
  | 'driver'
  | 'draft_order'
  | 'orders_counters'
  | 'tender'
  | 'banner_notification';

/**
 * Websocket notifications data action type.
 */
export type TTnWebsocketEventDataAction =
  | 'assigned'
  | 'bet_placed'
  | 'booked'
  | 'canceled'
  | 'completed'
  | 'created'
  | 'marked_as_read'
  | 'finished_with_winner'
  | 'finished_without_winner'
  | 'freed'
  | 'ready_to_go'
  | 'reassigned'
  | 'signing'
  | 'acts_comparison_completed'
  | 'verification_status_changed'
  | 'order_list_changed'
  | 'draft_order_list_changed'
  | 'tender_closed_without_bids'
  | 'auction_prolonged'
  | 'banner_notification_changed'
  | 'updated.price'
  | 'reserve_transport_documents_created'
  | 'documents_created'
  | 'order_loading_place_time_changed'
  | 'order_price_updated'
  | undefined;

/**
 * Transport websocket event data.
 */
export class TnWebsocketEventData<T = Record<string, unknown>> implements transport.ITnWebsocketEventData {
  public data?: T;

  public topic?: TTnWebsocketEventDataTopic;

  public action?: TTnWebsocketEventDataAction;

  public payload?;

  constructor(event?: Partial<transport.ITnWebsocketEventData>) {
    const newValues = { payload: null, ...event };
    for (const key of Object.keys(newValues)) {
      this[key] = newValues[key];
    }
    if (typeof this.payload === 'string') {
      this.data = JSON.parse(this.payload);
    }
  }
}

export type TWebsocketConfigToken = InjectionToken<transport.IWebSocketConfig>;

export const WS_CONFIG: TWebsocketConfigToken = new InjectionToken<transport.IWebSocketConfig>('WS_CONFIG');
