<form fxFlex fxLayout="column" [formGroup]="form" *ngIf="form" transportConnectForm="truck" [clearFormStateOnDestroy]="true">
  <div fxLayout="row" fxLayout.xs="column">
    <mat-form-field fxFlex="1 1 auto">
      <mat-label>
        {{ 'carrier.directory.vehicle.details.formLabels.markVehicle' | translate }}
      </mat-label>
      <mat-select
        formControlName="vehicleMake"
        [required]="true"
        [transportSelectErrorState]="isUseMatStep"
        data-test="truck-form-vehicle-make"
      >
        <mat-option *ngFor="let option of vehicleMakes" [value]="option">
          {{ option }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form?.controls?.vehicleMake?.errors?.required">
        {{ 'shared.errors.required' | translate }}
      </mat-error>
    </mat-form-field>

    <mat-form-field fxFlex="1 1 auto">
      <mat-label>{{ 'carrier.order.section.transport.country' | translate }}</mat-label>
      <mat-select
        formControlName="vehicleCountry"
        [required]="true"
        data-test="truck-form-vehicle-country"
      >
        <mat-option *ngFor="let option of countries" [value]="option.code">
          {{ option.name }}
        </mat-option>
      </mat-select>
      <mat-error *ngIf="form?.controls?.vehicleCountry?.errors?.required">
        {{ 'shared.errors.required' | translate }}
      </mat-error>
    </mat-form-field>
  </div>
  <mat-form-field>
    <input
      matInput
      [required]="true"
      autocomplete="off"
      transportUppercase
      [mask]="
        isVehicleCountryRussia
          ? regExpConf.ngxMask.vehiclePassport.ru.registrationNumber.mask
          : regExpConf.ngxMask.vehiclePassport.any.registrationNumber.mask
      "
      [patterns]="
        isVehicleCountryRussia
          ? $any(regExpConf.ngxMask.vehiclePassport.ru.registrationNumber.patterns)
          : $any(regExpConf.ngxMask.vehiclePassport.any.registrationNumber.patterns)
      "
      placeholder="{{ 'carrier.directory.vehicle.details.formLabels.gosNum' | translate }}"
      formControlName="vehicleId"
      [validation]="true"
      [transportInputErrorState]="isUseMatStep"
      (input)="onChangeVehicleId($event)"
      data-test="truck-form-vehicle-gos-number"
    />
    <transport-truck-help-icon [showIcon]="showTruckHelpIcon" [type]="truckIdentificatorType.GOS_NUMBER"></transport-truck-help-icon>
    <mat-error *ngIf="form?.controls?.vehicleId?.errors?.required">
      {{ 'shared.errors.required' | translate }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleId?.errors?.mask">
      {{ 'carrier.directory.vehicle.details.validation.wrongPatternGosNum' | translate }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleId?.errors?.maxlength">
      {{ 'shared.errors.maxLength' | translate: limitations.MAX_GOSNUM_LENGTH }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleId?.errors?.trimmedString">
      {{ 'shared.errors.trimmedString' | translate }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleId?.errors?.duplicate">
      {{ 'shared.directory.trucks.message.duplicate.number' | translate }}
    </mat-error>
  </mat-form-field>
  <mat-form-field>
    <input
      matInput
      autocomplete="off"
      [mask]="
        isVehicleCountryRussia
          ? regExpConf.ngxMask.vehiclePassport.ru.registrationCertificate.mask
          : regExpConf.ngxMask.vehiclePassport.any.registrationCertificate.mask
      "
      [patterns]="
        isVehicleCountryRussia
          ? $any(regExpConf.ngxMask.vehiclePassport.ru.registrationCertificate.patterns)
          : $any(regExpConf.ngxMask.vehiclePassport.any.registrationCertificate.patterns)
      "
      placeholder="{{ 'carrier.directory.vehicle.details.formLabels.stsNum' | translate }}"
      formControlName="vehicleCertificate"
      transportUppercase
      [validation]="true"
      data-test="truck-form-vehicle-sts-number"
    />
    <transport-truck-help-icon [showIcon]="showTruckHelpIcon" [type]="truckIdentificatorType.STS"></transport-truck-help-icon>
    <mat-error *ngIf="form?.controls?.vehicleCertificate?.errors?.required">
      {{ 'shared.errors.required' | translate }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleCertificate?.errors?.mask">
      {{ 'carrier.directory.vehicle.details.validation.notPatternSts' | translate }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleCertificate?.errors?.maxlength">
      {{ 'shared.errors.maxLength' | translate: limitations.MAX_STSNUM_LENGTH }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleCertificate?.errors?.trimmedString">
      {{ 'shared.errors.trimmedString' | translate }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleCertificate?.errors?.duplicate">
      {{ 'shared.directory.trucks.message.duplicate.sts' | translate }}
    </mat-error>
  </mat-form-field>

  <mat-form-field>
    <input
      matInput
      autocomplete="off"
      [mask]="regExpConf.ngxMask.vehiclePassport.vin.mask"
      [patterns]="$any(regExpConf.ngxMask.vehiclePassport.vin.patterns)"
      placeholder="{{ 'carrier.directory.vehicle.details.formLabels.vin' | translate }}"
      formControlName="vehicleVin"
      [required]="isRequiredVin"
      transportUppercase
      [validation]="true"
      [transportInputErrorState]="isUseMatStep"
      data-test="truck-form-vehicle-vin-number"
    />
    <transport-truck-help-icon [showIcon]="showTruckHelpIcon" [type]="truckIdentificatorType.VIN"></transport-truck-help-icon>
    <mat-error *ngIf="form?.controls?.vehicleVin?.errors?.required">
      {{ 'shared.errors.required' | translate }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleVin?.errors?.mask">
      {{ 'carrier.directory.vehicle.details.validation.mustLength17' | translate }}
    </mat-error>
    <mat-error *ngIf="form?.controls?.vehicleVin?.errors?.duplicate">
      {{ 'shared.directory.trucks.message.duplicate.vin' | translate }}
    </mat-error>
  </mat-form-field>
</form>
