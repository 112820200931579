<h1 mat-dialog-title fxLayout="row">
  <strong fxFlex>{{ textData.title | translate }}</strong>
  <button mat-icon-button matTooltip="{{ 'shared.actions.close' | translate }}" class="close-button" data-test="close-btn" mat-dialog-close (click)="cancel()">
    <mat-icon class="material-icons dialog-close">close</mat-icon>
  </button>
</h1>

<div mat-dialog-content *ngIf="!textData.markdown">
  {{ textData.content | translate }}
</div>
<div mat-dialog-content *ngIf="textData.markdown" class="markdown">
  <markdown [data]="textData.content"></markdown>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center" fxLayoutAlign.xs="center">
  <button mat-button (click)="cancel()" data-test="comfirm-cancel-btn">
    {{ textData.cancel | translate }}
  </button>
  <button mat-raised-button color="primary" (click)="confirm()" data-test="comfirm-btn" cdkFocusInitial>
    {{ textData.confirm | translate }}
  </button>
</div>
