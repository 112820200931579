import { NgModule } from '@angular/core';
import { AuctionPopupComponent } from './popups/auction-popup/auction-popup.component';
import { PartnerCheckPopupComponent } from './popups/partner-check-popup/partner-check-popup.component';
import { PopupContainerComponent } from './popups/popup-container/popup-container.component';
import { NotAcceptedPopupComponent } from './popups/not-accepted-popup/not-accepted-popup.component';
import { AcceptOrderPopupComponent } from './popups/accept-order-popup/accept-order-popup.component';
import { AuctionPopupService } from './popups/auction-popup/auction-popup.service';
import { CommonModule } from '@angular/common';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { TnUiComponentsModule } from '@transport/ui-components';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { RouterModule } from '@angular/router';
import { VerificationIconComponent } from './verification-icon/verification-icon.component';
import { BiddingPopupComponent } from './popups/bidding-popup/bidding-popup.component';
import { BiddingPopupService } from './popups/bidding-popup/bidding-popup.service';
import { CustomControlsModule } from '@transport/custom-controls';

@NgModule({
  declarations: [
    AuctionPopupComponent,
    BiddingPopupComponent,
    AcceptOrderPopupComponent,
    NotAcceptedPopupComponent,
    PopupContainerComponent,
    PartnerCheckPopupComponent,
    VerificationIconComponent,
  ],
  imports: [RouterModule, CommonModule, TnUiKitModule, TnUiPipesModule, TnUiMaterialModule, TnUiComponentsModule, TnUiDirectivesModule, CustomControlsModule],
  exports: [PopupContainerComponent],
  providers: [AuctionPopupService, BiddingPopupService],
})
export class TakeOrderPopupsModule {}
