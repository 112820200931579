import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors, ValidatorFn, Validators } from '@angular/forms';
import { regExpConfig } from '@transport/ui-utils';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { CITIZEN_NUMBERS, TnSupportedCitizenNumbers, TnSupportedCountry } from '@transport/ui-pipes';
import { getPhoneWithCitizenNumber } from 'libs/transport-ui-pipes/src/lib/phone/phone-number.pipe';
import { distinctUntilChanged, startWith } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'common-phone-input',
  templateUrl: './common-phone-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonPhoneInputComponent implements OnInit {
  public citizenNumbers = CITIZEN_NUMBERS;

  public mask = regExpConfig.ngxMask.phone.ruInput.mask;
  public patterns = regExpConfig.ngxMask.phone.ruInput.patterns;

  public regExpsPhone = {
    NO: {
      mask: regExpConfig.ngxMask.phone.ruInput.mask,
      patterns: regExpConfig.ngxMask.phone.ruInput.patterns,
    },
    RU: {
      mask: regExpConfig.ngxMask.phone.ruInput.mask,
      patterns: regExpConfig.ngxMask.phone.ruInput.patterns,
    },
    KZ: {
      mask: regExpConfig.ngxMask.phone.ruInput.mask,
      patterns: regExpConfig.ngxMask.phone.ruInput.patterns,
    },
    BY: {
      mask: regExpConfig.ngxMask.phone.byInput.mask,
      patterns: regExpConfig.ngxMask.phone.byInput.patterns,
    },
    UZ: {
      mask: regExpConfig.ngxMask.phone.byInput.mask,
      patterns: regExpConfig.ngxMask.phone.byInput.patterns,
    },
    KG: {
      mask: regExpConfig.ngxMask.phone.byInput.mask,
      patterns: regExpConfig.ngxMask.phone.byInput.patterns,
    },
    TJ: {
      mask: regExpConfig.ngxMask.phone.byInput.mask,
      patterns: regExpConfig.ngxMask.phone.byInput.patterns,
    },
  };

  public citizenNumberFormControl = new FormControl({ id: TnSupportedCountry.RU, label: TnSupportedCitizenNumbers.RU });
  public phoneControl = new FormControl('');

  public citizenNumber = TnSupportedCitizenNumbers.RU;

  public regExpPhone = this.regExpsPhone[TnSupportedCountry.RU];

  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public inputFieldClass = '';
  @Input() public label?: string = '';
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public disabled = false;
  @Input() public withCitizenNumber = true;
  @Input() public formGroup?: FormGroup;
  @Input() public control?: FormControl;
  @Input() public controlName = '';

  @Input() public placeholder = '';
  @Input() public prefix = '';
  @Input() public dataTest = '';
  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  constructor(private cdr: ChangeDetectorRef) {}

  public get formControl(): AbstractControl {
    return (this.formGroup?.get(this.controlName) ?? this.control)!;
  }

  public ngOnInit(): void {
    this.formControl.valueChanges
    .pipe(untilDestroyed(this), startWith(this.formControl.value), distinctUntilChanged())
    .subscribe(res => {
      if(!this.phoneControl.value) {
        this.patchPhoneControl(res);
        this.cdr.markForCheck();
      }
    })

    this.citizenNumberFormControl?.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (!value) return;
      this.phoneControl.reset();
      this.formControl.reset();
      this.formControl.clearValidators();
      this.regExpPhone = this.regExpsPhone[value?.id || TnSupportedCountry.RU];
      this.citizenNumber = value.label;
      this.cdr.markForCheck();
      this.updateValidators(value.id);
    });
  }

  public onBlur(event) {
    this.formControl.markAsTouched();
  }

  public onInput(event) {
    const value = this.getUnmaskedValue(event?.target.value);
    const fullNumber = `${this.citizenNumber}${value}`;
    this.formControl.setValue(value ? fullNumber : '', {emitEvent: false});
  }

  private getUnmaskedValue(value: string) {
    const regEx = /[(|)|-|_|-]/g;
    return value.replace(regEx, '');
  }

  private phoneNumberValidator(code): ValidatorFn {
    return formControl => {
      if (!formControl.value) return null;
      if(code === TnSupportedCountry.BY && formControl.value?.length !== 13) return {invalidPhoneNumber: TnSupportedCountry.BY};
      if(code === TnSupportedCountry.UZ && formControl.value?.length !== 13) return {invalidPhoneNumber: TnSupportedCountry.UZ};
      if(code === TnSupportedCountry.TJ && formControl.value?.length !== 13) return {invalidPhoneNumber: TnSupportedCountry.TJ};
      if(code === TnSupportedCountry.KG && formControl.value?.length !== 13) return {invalidPhoneNumber: TnSupportedCountry.KG};
      if(code === TnSupportedCountry.RU && formControl.value?.length !== 12) return {invalidPhoneNumber: TnSupportedCountry.RU};
      return null;
    };
  }

  public getMarginTop() {
    if (this.withCitizenNumber && this.label) return this.size === 'sm' ? 'mt-[21px]' : 'mt-[24px]';
    return '';
  }

  private patchPhoneControl(value?: string | null) {
    const {citizenNumber, phone} = getPhoneWithCitizenNumber(value ?? null) ?? {citizenNumber: TnSupportedCitizenNumbers.RU, phone: ''};
    const code = CITIZEN_NUMBERS.find(c => c.label === citizenNumber);
    this.citizenNumberFormControl.setValue(code, {emitEvent: false});
    this.phoneControl.setValue(phone);
    this.citizenNumber = code?.label as TnSupportedCitizenNumbers;
    this.regExpPhone = this.regExpsPhone[code?.id || TnSupportedCountry.RU];
    this.updateValidators(this.citizenNumberFormControl.value?.id);
  }

  private updateValidators(code) {
    const validators: ValidatorFn[] = [];
    if (this.required) validators.push(Validators.required);
    this.formControl.setValidators([
      ...validators,
      this.phoneNumberValidator(code)
    ]);
    this.formControl.updateValueAndValidity();
  }
}
