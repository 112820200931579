import { ChangeDetectionStrategy, ChangeDetectorRef, Component } from '@angular/core';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { FormControl, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TnCarrierDirectoryApiService } from '@transport/ui-store';
import { TnModalAddTransportService } from '../../../../../transport-notifications/src/lib/components/modal-add-transport/modal-add-transport.service';
import { VehicleService } from 'libs/marketplace-shared/src/lib/edit-vehicle/vehicle.service';
import { IGCarrier } from '@transport/graphql-interface';
import { PRICE_FORM } from '../vehicle.interface';
import { KEYDOWN_DELAY, regExpConfig, setRegistrationNumberPattern } from '@transport/ui-utils';
import { MaskApplierService } from 'ngx-mask';
import { BehaviorSubject, debounceTime, distinctUntilChanged } from 'rxjs';
import { dateComparisonValidator, getTimezoneForMoment } from './publish-vehicle-form.functions';
import moment from 'moment';
import { lessThanNowValidator, timeToMoreThanTimeFromValidator } from '@transport/ui-interfaces';
import { TranslateService } from '@ngx-translate/core';
import { TnCurrentUserService } from '@marketplace/auth';
import { CurrencySigns, TnSupportedCurrency } from '@transport/ui-pipes';

@UntilDestroy()
@Component({
  selector: 'publish-vehicle-form',
  templateUrl: './publish-vehicle-form.component.html',
  styleUrls: ['./publish-vehicle-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default,
  providers: [VehicleService],
})
export class PublishVehicleFormComponent {
  public form = new FormGroup({
    vehicleId: new FormControl('', Validators.required),
    priceForm: new FormGroup({
      price: new FormControl('', Validators.compose([Validators.max(PRICE_FORM.MAX_PRICE)])),
      priceType: new FormControl(IGCarrier.IPriceTypeEnum.PerKm),
    }),
    unloadingPlaceDescription: new FormControl(''),
    placeFrom: new FormControl('', Validators.required),
    placeTo: new FormControl(''),
    date: new FormControl('', Validators.compose([Validators.required, lessThanNowValidator(''), dateComparisonValidator('timeFrom')])),
    timeFrom: new FormControl('', Validators.minLength(5)),
    timeTo: new FormControl('', {validators: [Validators.minLength(5)], updateOn: 'blur'}, ),
    isVatIncluded: new FormControl({ value: false, disabled: true }),
  }, {validators: [timeToMoreThanTimeFromValidator('timeFrom', 'timeTo')]});

  public options = [
    {
      value: IGCarrier.IPriceTypeEnum.PerKm,
      title: '\u20BD/км',
      subtitle: 'Цена за километр',
    },
    {
      value: IGCarrier.IPriceTypeEnum.Total,
      title: '\u20BD',
      subtitle: 'Цена за перевозку',
    },
  ];

  public currencySign = CurrencySigns[TnSupportedCurrency.RUB];

  public loading$ = new BehaviorSubject<boolean>(true);

  vehicleData: { item: any; id: string; label: string }[] = [];

  private regExpConf = regExpConfig;

  public canManageTransportList = Boolean(this.currentUserService.currentUserPermissions.generalReference?.manage);

  constructor(
    private readonly carrierDirectoryService: TnCarrierDirectoryApiService,
    private readonly modalAddTransportService: TnModalAddTransportService,
    private readonly vehicleService: VehicleService,
    private readonly maskService: MaskApplierService,
    public cdr: ChangeDetectorRef,
    private readonly translate: TranslateService,
    private currentUserService: TnCurrentUserService,
  ) {
    this.form.controls.timeFrom.valueChanges.pipe(debounceTime(KEYDOWN_DELAY), untilDestroyed(this)).subscribe(() => {
      this.form.controls.timeTo.updateValueAndValidity({ emitEvent: false });
      this.form.controls.date.updateValueAndValidity({ emitEvent: false });
    });
    this.form.controls.timeTo.valueChanges
      .pipe(debounceTime(KEYDOWN_DELAY), untilDestroyed(this))
      .subscribe(() => this.form.controls.timeFrom.updateValueAndValidity({ emitEvent: false }));
    this.form.controls.date.valueChanges
      .pipe(distinctUntilChanged(), untilDestroyed(this))
      .subscribe(() => this.form.controls.date.updateValueAndValidity({ emitEvent: false }));
  }

  ngOnInit() {
    this.getVehicleData();
    this.modalAddTransportService.afterSaveData.pipe(untilDestroyed(this)).subscribe(() => {
      this.getVehicleData();
    });

    this.form
      .get('priceForm.price')
      ?.valueChanges.pipe(untilDestroyed(this))
      .subscribe(price => {
        if (Boolean(price)) {
          this.form.controls.isVatIncluded.enable();
        } else {
          this.form.controls.isVatIncluded.disable();
        }
      });
  }

  onOpenModalNewVehicle() {
    this.vehicleService.openModal(this.currentUserService.currentUserRole);
  }

  public getRawValue(): IGCarrier.IVehicleAvailabilityInput {
    const value = this.form.value;
    const date = moment(value.date, 'DD.MM.YYYY').format('YYYY-MM-DD');
    const timeFrom = Boolean(value.timeFrom) ? value.timeFrom : '00:00';
    const timeTo = Boolean(value.timeTo) ? value.timeTo : '23:59';
    const loadingTimeFrom = `${date}T${timeFrom}:00${getTimezoneForMoment(value.placeFrom.timezone)}`;
    const loadingTimeTo = `${date}T${timeTo}:00${getTimezoneForMoment(value.placeFrom.timezone)}`;
    // '2012-12-12T00:00:00+00:00'
    return {
      comment: value.unloadingPlaceDescription,
      isVatIncluded: value.isVatIncluded,
      loadingPlaceDescription: value.placeFrom.fullAddress,
      loadingPlaceFiasCode: value.placeFrom.fiasCode,
      loadingPlaceTimezone: value.placeFrom.timezone,
      loadingPlaceSettlement: Boolean(value.placeFrom.cityWithType) ? value.placeFrom.cityWithType : value.placeFrom.settlementWithType,
      loadingPlaceSettlementFiasCode: Boolean(value.placeFrom.cityFiasId) ? value.placeFrom.cityFiasId : value.placeFrom.settlementFiasId,
      loadingTimeFrom,
      loadingTimeTo,
      ...(Boolean(value.priceForm.price) && {
        price: {
          amount: value.priceForm.price,
          currency: 'RUB',
        },
      }),
      ...(Boolean(value.priceForm.price) && { priceType: value.priceForm.priceType }),
      unloadingPlaceDescription: value.placeTo?.fullAddress ? value.placeTo?.fullAddress : 'Россия',
      ...(Boolean(value.placeTo?.fiasCode) && { unloadingPlaceFiasCode: value.placeTo?.fiasCode }),
      vehicleId: value.vehicleId,
    };
  }

  public sendVehicleOnCheck(id: string): void {
    const vehicleType = this.vehicleData.find(el => el.id === id)?.item?.vehicleType;
    this.vehicleService.openModal(this.currentUserService.currentUserRole, id, vehicleType);
    this.vehicleService.dialogRef
      ?.afterClosed()
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.form.controls.vehicleId.markAsPristine();
        this.form.controls.vehicleId.markAsUntouched();
        this.cdr.markForCheck();
        this.getVehicleData();
      });
  }

  private getVehicleData() {
    this.carrierDirectoryService
      .getVehiclesSelector(1, false)
      .pipe(untilDestroyed(this))
      .subscribe(res => {
        this.loading$.next(false);
        this.vehicleData = res.list.map(el => ({
          id: el.id as string,
          label: `${el.vehicleMake} ${setRegistrationNumberPattern(this.maskService, el.vehicleId || '', el?.country || '')}`,
          item: el as any,
          accrStatus: el?.accrStatus,
        }));
      });
  }

  // TODO: need to select correct vat and bet, similar in TnPriceEditModal
  public getVat(): string {
    let price = this.form.get('priceForm.price')?.value;
    const vat = 20;
    const bet = 120;
    const digits = 2;
    if (Boolean(Number(price)) && price >= 0 && price <= PRICE_FORM.MAX_PRICE) {
      price = Number(price);
      return ((price / bet) * vat).toFixed(digits).replace(/\B(?=(\d{3})+(?!\d))/g, ' ');
    }
    return '0';
  }

  public validationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.errors.required');
    if (e?.lessThanNow) return this.translate.instant('shared.errors.lessThanNow');
    if (Boolean(e?.max)) return this.translate.instant('shared.errors.maxNumber', { value: e?.max?.max });
    if (e?.invalidTimeFrom) return this.translate.instant('shared.errors.timeFromMoreTimeTo');
    return '';
  }
}
