  <form *ngIf="vm.form" [formGroup]="vm.form" class="flex flex-col gap-4">
    <div class="block flex flex-col gap-2">
      <div class="title font-medium text-base text-gray-700">{{ 'carrier.directory.marketplaceDriver.blockTitle.common' | translate }}</div>
      <div class="fields flex flex-col">
        <div class="row flex w-full">
          <common-select
            class="w-[50%]"
            (selectionChanged)="setPassportSeriesValidators()"
            label="{{ 'carrier.directory.marketplaceDriver.form.country' | translate }}"
            [allowClear]="false"
            [value]="vm.form.controls.country.value"
            [formGroup]="vm.form"
            controlName="country"
            [required]="true"
            [dataSource]="countries"
            dataTest="driver-form-country"
            [errorToMessage]="validationErrors.bind(this)"
          ></common-select>
        </div>

        <div class="row flex gap-2">
          <common-input
            [control]="$any(vm.form.controls.surname)"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'carrier.directory.marketplaceDriver.form.surname' | translate }}"
            [required]="true"
            dataTest="driver-form-surname">
          </common-input>
          <common-input
            [control]="$any(vm.form.controls.name)"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'carrier.directory.marketplaceDriver.form.name' | translate }}"
            [required]="true"
            dataTest="driver-form-name">
          </common-input>
          <common-input
            [control]="$any(vm.form.controls.patronymic)"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'carrier.directory.marketplaceDriver.form.patronymic' | translate }}"
            dataTest="driver-form-patronymic">
          </common-input>

          <common-date-picker
            [formGroup]="vm.form"
            controlName="birthday"
            fxFlex.xs="0 0 100%"
            [label]="'carrier.directory.marketplaceDriver.form.birthday' | translate"
            placeholder="{{ 'shared.date.placeholder' | translate }}"
            [required]="true"
            [errorToMessage]="validationErrors.bind(this)"
            [dataTest]="'driver-form-birthday'"
            [allowClear]="true"
            >
          </common-date-picker>

        </div>
        <div class="flex flex-wrap gap-2">
          <common-phone-input
            [inputFieldClass]="'w-[200px]'"
            label="{{ 'carrier.directory.marketplaceDriver.form.phone' | translate }}"
            [formGroup]="vm.form"
            [required]="true"
            controlName="phone"
            dataTest="driver-form-phone"
            [errorToMessage]="validationErrorsPhone.bind(this)"
          ></common-phone-input>
          <common-phone-input
            [inputFieldClass]="'w-[200px]'"
            label="{{ 'carrier.directory.marketplaceDriver.form.addPhone' | translate }}"
            [formGroup]="vm.form"
            controlName="addPhone"
            dataTest="driver-form-add-phone"
            [errorToMessage]="validationErrorsPhone.bind(this)"
          ></common-phone-input>
        </div>
      </div>
    </div>

    <div class="block flex flex-col">
      <div class="row w-[200px]">
        <common-input
          *ngIf="isDriverCountryKz"
          [control]="$any(vm.form.controls.iin)"
          [errorToMessage]="validationErrorsIIN.bind(this)"
          label="{{ 'carrier.directory.marketplaceDriver.form.iin' | translate }}"
          [required]="isDriverCountryKz"
          [mask]="regExpConf.ngxMask.iin.kz.mask"
          [patterns]="$any(regExpConf.ngxMask.iin.kz.patterns)"
          dataTest="driver-form-iin">
        </common-input>
      </div>
      <common-multiple-select
        class="my-2 w-[50%]"
        [control]="$any(vm.form.controls.personalDocTypes)"
        [labelClasses]="['font-medium text-md text-gray-700']"
        label="Удостоверение личности"
        [errorToMessage]="validationErrors.bind(this)"
        [required]="!isDriverCountryKz"
        [dataSource]="docTypesOptions"
        dataTest="driver-form-doctype"
      >
      </common-multiple-select>
      <div *ngIf="showIdCard" class="flex flex-col">
        <div class="title font-medium text-sm text-gray-700">
          Удостоверение личности
        </div>
        <div class="flex gap-2">
          <common-input
            [control]="$any(vm.form.controls.identityCardNumber)"
            label="Номер"
            [errorToMessage]="validationErrors.bind(this)"
            [required]="true"
            dataTest="id-card-number">
          </common-input>
          <common-date-picker
            class="w-[155px]"
            [formGroup]="vm.form"
            controlName="identityCardDate"
            [label]="'Дата'"
            placeholder="{{ 'shared.date.placeholder' | translate }}"
            [required]="true"
            [errorToMessage]="validationErrors.bind(this)"
            [dataTest]="'id-card-date-getting'"
            [allowClear]="true"
            >
          </common-date-picker>
        </div>
        <common-input
          [control]="$any(vm.form.controls.identityCardPlace)"
          label="Кем выдан"
          [required]="true"
          [errorToMessage]="validationErrors.bind(this)"
          dataTest="id-card-place">
        </common-input>
      </div>
      <!-- Passport -->
      <div *ngIf="showPassport" class="fields flex flex-col">
        <div class="title font-medium text-sm text-gray-700">
          {{ 'carrier.directory.marketplaceDriver.blockTitle.passport' | translate }}
        </div>
        <div class="row flex gap-2">
          <common-input
            [control]="$any(vm.form.controls.passportSeries)"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'carrier.directory.marketplaceDriver.form.passportSeries' | translate }}"
            [mask]="
              isDriverCountryRussia
                ? regExpConf.ngxMask.citizenPassport.ru.series.mask
                : regExpConf.ngxMask.citizenPassport.any.mask
            "
            [required]="!isDriverCountryKz"
            [patterns]="
              isDriverCountryRussia
                ? $any(regExpConf.ngxMask.citizenPassport.ru.series.patterns)
                : $any(regExpConf.ngxMask.citizenPassport.any.patterns)
            "
            dataTest="driver-form-passport-series">
          </common-input>
          <common-input
            [control]="$any(vm.form.controls.passportNumber)"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'carrier.directory.marketplaceDriver.form.passportNumber' | translate }}"
            [mask]="
              isDriverCountryRussia
                ? regExpConf.ngxMask.citizenPassport.ru.number.mask
                : regExpConf.ngxMask.citizenPassport.any.mask
            "
            [required]="true"
            [patterns]="
              isDriverCountryRussia
                ? $any(regExpConf.ngxMask.citizenPassport.ru.number.patterns)
                : $any(regExpConf.ngxMask.citizenPassport.any.patterns)
            "
            dataTest="driver-form-passport-number">
          </common-input>

          <common-date-picker
            [formGroup]="vm.form"
            controlName="passportDate"
            class="w-[155px]"
            [label]="'carrier.directory.marketplaceDriver.form.dateGetting' | translate"
            placeholder="{{ 'shared.date.placeholder' | translate }}"
            [required]="true"
            [errorToMessage]="validationErrors.bind(this)"
            [dataTest]="'driver-form-date-getting'"
            [allowClear]="true"
            >
          </common-date-picker>

        </div>
        <div class="row">
          <common-input
            [control]="$any(vm.form.controls.passportPlace)"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'carrier.directory.marketplaceDriver.form.passportPlace' | translate }}"
            [required]="true"
            dataTest="driver-form-passport-place">
          </common-input>
        </div>
      </div>
    </div>

    <div class="block flex flex-col gap-2">
      <div class="title font-medium text-base text-gray-700">
        {{ 'carrier.directory.marketplaceDriver.blockTitle.licence' | translate }}
      </div>
      <div class="fields flex flex-col">
        <div class="row flex gap-2">
          <common-input
            [control]="$any(vm.form.controls.licenceSeries)"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'carrier.directory.marketplaceDriver.form.licenceSeries' | translate }}"
            [required]="true"
            [mask]="getLicenceSeriesMask()"
            [patterns]="$any(getLicenceSeriesPatterns())"
            dataTest="driver-form-licence-series">
          </common-input>
          <common-input
            [control]="$any(vm.form.controls.licenceNumber)"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'carrier.directory.marketplaceDriver.form.licenceNumber' | translate }}"
            [required]="true"
            [mask]="getLicenceNumberMask()"
            [patterns]="$any(getLicenceNumberPatterns())"
            dataTest="driver-form-licence-number">
          </common-input>

          <common-date-picker
            [formGroup]="vm.form"
            controlName="licenceDate"
            class="w-[155px]"
            [label]="'carrier.directory.marketplaceDriver.form.dateGetting' | translate"
            placeholder="{{ 'shared.date.placeholder' | translate }}"
            [required]="true"
            [errorToMessage]="validationErrors.bind(this)"
            [dataTest]="'driver-form-licence-date'"
            [allowClear]="true"
            >
          </common-date-picker>

        </div>
        <div class="row">
          <common-input
            class="w-full"
            [control]="$any(vm.form.controls.licencePlace)"
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'carrier.directory.marketplaceDriver.form.licensePlace' | translate }}"
            dataTest="driver-form-licence-place">
          </common-input>
        </div>
      </div>
    </div>
    <div *ngIf="isAgentOrExpeditor" class="row">
      <common-select
        [labelClasses]="['font-medium']"
        [control]="$any(vm.form.controls.agentCarrierId)"
        [dataSource]="vm.organizations$ | async"
        [allowClear]="true"
        [asyncSearch]="true"
        [enableSearch]="true"
        [label]="'shared.mp.vehicles.vehicle.form.organization.label' | translate"
        [placeholder]="'shared.mp.vehicles.vehicle.form.organization.placeholder' | translate"
        (search)="searchAgentCarriers($event)"
        dataTest="driver-form-organization-choose">
    </common-select>
    </div>
    <div class="flex items-center gap-2">
      <common-number-input
        [control]="$any(vm.form.controls.inn)"
        label="{{ 'carrier.directory.marketplaceDriver.form.inn' | translate }}"
        [onlyIntegers]="true"
        [errorToMessage]="validationErrors.bind(this)"
        dataTest="driver-form-inn">
      </common-number-input>
      <mat-icon class="!w-5 !h-5 mt-5"
                [svgIcon]="'info-outline'"
                transportTooltip
                [context]="{ message: 'Для водителей, которые работают в РФ' }">
      </mat-icon>
    </div>
    <ng-container *ngIf="!vm.isSubdomainPage && !(vm?.hasAgentCarrier$ | async)">
      <div class="block flex flex-col gap-2">
        <transport-accreditation-block [driverStatus]="$any(vm.driver$?.value?.accrStatus)">
          <div class="max-w-[258px]">
            <label class="mb-1 text-sm font-normal text-gray-700">
              {{ 'carrier.directory.marketplaceDriver.form.accreditation.passportMainPage' | translate }}
            </label>
            <transport-file-selector [errorToMessage]="validationErrors.bind(this)" [control]="$any(passportFormGroup.controls.mainPage)" [attr.data-test]="'driver-form-file-passportMainPage'">
            </transport-file-selector>
          </div>
          <div class="max-w-[258px]">
            <label class="mb-1 text-sm font-normal text-gray-700">
              {{ 'carrier.directory.marketplaceDriver.form.accreditation.passportRegistration' | translate }}
            </label>
            <transport-file-selector [errorToMessage]="validationErrors.bind(this)" [control]="$any(passportFormGroup.controls.registration)" [attr.data-test]="'driver-form-file-passportRegistration'">
            </transport-file-selector>
          </div>
        </transport-accreditation-block>
      </div>
    </ng-container>
    <ng-container *ngIf="!vm.isSubdomainPage && (vm?.hasAgentCarrier$ | async)">

      <div class="mb-6 p-6">
        <div class="flex justify-between text-gray-700 bg-gray-50 border border-gray-100 rounded-md p-4">
          <div class="flex flex-col">
            <span class="font-medium text-lg">
              {{ 'carrier.directory.marketplaceDriver.form.accreditation.title' | translate }}
            </span>
            <span class="text-gray-500 font-normal text-xs flex items-start">
              <mat-icon class="text-orange-500 !h-[14px] !w-[14px] !text-[14px] mr-[3px] mt-[2px]">info</mat-icon>
              {{ 'carrier.directory.marketplaceDriver.form.accreditation.thirdPartyDescription' | translate }}
            </span>
          </div>
          <div
            *ngIf="(vm.driver$ | async)?.accrStatus === 'not_passed'"
            class="flex h-[18px] bg-red-500 rounded-sm text-white font-bold text-xs leading-4 px-1"
            transportTooltip
            [context]="{ message: (vm.driver$ | async)?.accrComment }"
          >
            <span class="m-auto whitespace-nowrap">
              {{ 'carrier.drivers.statuses.notPassed' | translate }}
            </span>
          </div>
        </div>
      </div>
    </ng-container>
  </form>
