import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Output,
  Self,
} from '@angular/core';
import { NgControl, ValidationErrors } from '@angular/forms';
import { FormBuilder, FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import { TnCustomNgNeatReactiveFormControlDirective } from '@transport/ui-directives';
import { IVehicleTrailerForm, TRUCK_IDENTIFICATOR_TYPE, VEHICLE_FORM_CONSTANTS } from '@transport/ui-interfaces';
import { TnSupportedCountry } from '@transport/ui-pipes';
import { createTrailerInfoFormGroup, mapTruckInputValueToInner } from '@transport/ui-store';
import { getTranslateParams, regExpConfig } from '@transport/ui-utils';
import { MaskApplierService } from 'ngx-mask';
import { of } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'transport-trailer-info',
  templateUrl: './trailer-info.component.html',
  styleUrls: ['./trailer-info.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
/* eslint-disable prettier/prettier -- prettier conflicts with eslint (brace style), eslint takes precedence here */
export class TnTrailerInfoComponent
  extends TnCustomNgNeatReactiveFormControlDirective<IVehicleTrailerForm>
  implements OnInit, OnChanges, OnDestroy {
    /* eslint-enable prettier/prettier -- prettier conflicts with eslint in this case (brace style), eslint takes precedence here */
  @Input() public isTitle = false;

  @Input() public isUseMatStep = false;

  @Input() public isDeleteTrailerBtnVisible = false;

  @Input() public isVehicleCountryRussia = false;

  @Input() public vehicleCountry? = TnSupportedCountry.RU;

  @Input() public loadingTypes: transport.Vehicle.Body.IType[] = [];

  @Input() public bodyTypes: transport.Vehicle.Body.IType[] = [];

  @Input() public bodySubtypes: Record<string, transport.Vehicle.Body.ISubtype[]> = {};

  @Input() public isDisabled = false;

  @Output() public readonly setVehicleType = new EventEmitter();

  public regExpConf = regExpConfig;

  public limitations = getTranslateParams<typeof VEHICLE_FORM_CONSTANTS>(VEHICLE_FORM_CONSTANTS);

  public truckIdentificatorType = TRUCK_IDENTIFICATOR_TYPE;

  public get showTruckHelpIcon() {
    return !this.isDisabled && this.isVehicleCountryRussia;
  }

  constructor(
    protected readonly fb: FormBuilder,
    @Optional() @Self() public controlDir: NgControl,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly maskService: MaskApplierService,
  ) {
    super(fb);
    if (Boolean(controlDir)) {
      controlDir.valueAccessor = this;
    }
  }

  public ngOnChanges() {
    if (this.vehicleCountry === TnSupportedCountry.RU) {
      const trailerIdValue = this.form.controls.trailerId.value;
      const tGosNum = Boolean(trailerIdValue)
        ? this.maskService.applyMaskWithPattern(trailerIdValue, [
            regExpConfig.ngxMask.vehiclePassport.ru.trailerNumber.mask,
            regExpConfig.ngxMask.vehiclePassport.ru.trailerNumber.patterns ?? {},
          ])
        : null;

      this.form.patchValue({
        trailerId: tGosNum,
      });
    }
  }

  public ngOnDestroy() {
    this.controlDir.control?.clearValidators();
    this.controlDir.control?.updateValueAndValidity();
  }

  public ngOnInit() {
    this.controlDir.control?.setValidators([this.validate.bind(this)]);
    this.controlDir.control?.updateValueAndValidity();
    this.changeDetector.detectChanges();

    this.form.disabledWhile(of(this.isDisabled));

    void this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
    });

    if (Boolean(this.controlDir.control)) {
      void (this.controlDir.control as FormControl).touch$.pipe(untilDestroyed(this)).subscribe(touch => {
        if (Boolean(touch)) {
          this.form.markAllAsTouched();
          this.changeDetector.detectChanges();
        }
      });
    }
  }

  public validate(): ValidationErrors | null {
    return this.form.invalid ? { trailerInfo: true } : null;
  }

  public writeValue(data: IVehicleTrailerForm): void {
    if (Boolean(data)) {
      this.form.patchValue(mapTruckInputValueToInner<IVehicleTrailerForm>(data), {
        emitEvent: false,
      });
    }
  }

  public setVehicleTypeTruck() {
    this.setVehicleType.emit();
  }

  protected createFormGroup() {
    return createTrailerInfoFormGroup();
  }
}
