<div class="form-wrap">
  <div class="form-title">{{ 'shared.uploadContract.title' | translate }}</div>
  <form
    fxLayout="column"
    [formGroup]="form"
    (ngSubmit)="onSubmit($event)"
    novalidate
    class="passport-form mat-body-2"
    cdkTrapFocus
  >
    <common-input
      [formGroup]="form"
      class=""
      size="sm"
      label="{{ 'shared.uploadContract.contractId' | translate }}"
      [required]="true"
      controlName="contractId"
      [errorToMessage]="validationErrors.bind(this)"
      errorsSize="xs"
      dataTest="contract-id"
    ></common-input>

    <div class="cargo-info flex mt-2 mb-2">
      <common-date-picker
        class="mr-2"
        size="sm"
        [formGroup]="form"
        controlName="dateStart"
        [required]="true"
        [errorToMessage]="validationErrors.bind(this)"
        label="{{'shared.uploadContract.dateStart' | translate}}"
        placeholder="{{'shared.mp.cargo.filter.dateFrom.placeholder' | translate}}"
        [allowClear]="true"
      >
      </common-date-picker>
      <common-date-picker
        [formGroup]="form"
        controlName="dateEnd"
        size="sm"
        [errorToMessage]="validationErrors.bind(this)"
        label="{{'shared.uploadContract.dateEnd' | translate}}"
        placeholder="{{'shared.mp.cargo.filter.dateFrom.placeholder' | translate}}"
        [allowClear]="true"
      >
      </common-date-picker>
    </div>

    <div fxLayout="column" fxLayoutGap="8px">
      <transport-files-input
        class="upload-area"
        [formControl]="$any(form.controls.file)"
        [accept]="'.doc, .docx, .jpeg, .pdf, .jpg'"
        [acceptDrag]="'application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf, image/jpeg'"
        [errorAccept]="'pdf, docx, doc, jpeg, jpg'"
        [multiple]="false"
        placeholder="{{ 'shared.uploadContract.uploadTooltip'| translate }}"
        (invalidFileSizeSelected)="onErrorSize($event)"
      ></transport-files-input>
      <transport-button
        class="btn-add"
        fxFlexAlign="end"
        [label]="'shared.uploadContract.addContract' | translate "
        type="action"
        [iconPosition]="'left'"
        icon="plus">
      </transport-button>
    </div>
  </form>
</div>
