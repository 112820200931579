<h4 mat-dialog-title class="title text-gray-700 font-medium">{{service?.vm?.title || ''}}</h4>

<ng-container *ngIf="(service?.vm?.driver$ | async) as data">
  <div *ngIf="data?.blackListDriver?.message?.length" class="mb-3 !mt-[-24px]">
    <span class="underline underline-offset-2 decoration-dashed cursor-pointer text-sm text-orange-500"
          (click)="showLimitations = !showLimitations">{{'shared.mp.drivers.blackList.hasWarning' | translate}}</span>
    <div *ngIf="showLimitations" class="flex flex-col p-2 rounded-md bg-gray-100 max-h-[200px] overflow-auto">
      <span *ngFor="let item of data?.blackListDriver?.message" class="text-xs">
        <strong>{{item?.cargoOwner?.name}}</strong>:
        {{(item?.workProhibited ? 'shared.mp.drivers.blackList.inBlackList' : 'shared.mp.drivers.blackList.hasWarning') | translate}}.<br>
        {{'shared.mp.drivers.blackList.reason' | translate}}: {{item?.reason}}<br>
        {{'shared.mp.drivers.blackList.description' | translate}}: {{item?.description}}<br>
      </span>
    </div>
  </div>
</ng-container>

<div mat-dialog-content *ngIf="service?.vm">
  <driver-form [vm]="service.vm!"></driver-form>
</div>
<div class="footer flex justify-end gap-3" mat-dialog-actions>
  <button (click)="service?.close()" class="tw-button-ghost-sm !text-blue-500">
    {{ 'carrier.directory.marketplaceDriver.buttons.cancel' | translate }}
  </button>
  <button (click)="action()" class="tw-button-solid-sm" [disabled]="service?.vm?.loading$ | async">
    <span class="flex gap-2">
      <mat-spinner *ngIf="service?.vm?.loading$ | async" [diameter]="20" [strokeWidth]="2"> </mat-spinner>
      <span>{{service?.vm?.actionBtnTxt}}</span>
    </span>
  </button>
</div>
