<form [formGroup]="form" *ngIf="form" transportConnectForm="driver" [clearFormStateOnDestroy]="true">
  <transport-form-block-new
    [isBorder]="isBorder"
    [title]="'carrier.directory.driver.form.passportData' | translate"
    [svgIconName]="isBorder ? 'passport' : ''"
    data-test="passportData"
  >
    <div class="block-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <ng-content></ng-content>
    </div>
    <div class="block-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.surname' | translate }}"
          [required]="true"
          formControlName="surname"
          [transportInputErrorState]="isUseMatStep"
          (blur)="onBlurInput('surname')"
          [attr.data-test]="dataTest + '-driver-form-surname'"
        />
        <mat-error *ngIf="form?.controls?.surname?.errors?.required">{{
          'shared.errors.required' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.surname?.errors?.trimmedString">{{
          'shared.errors.trimmedString' | translate
        }}</mat-error>
<!--        <mat-error *ngIf="form?.controls?.surname?.errors?.sameFieldValue">{{-->
<!--          'carrier.directory.driver.errors.surnameMatch' | translate-->
<!--        }}</mat-error>-->
      </mat-form-field>
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.name' | translate }}"
          [required]="true"
          formControlName="name"
          [transportInputErrorState]="isUseMatStep"
          (blur)="onBlurInput('name')"
          [attr.data-test]="dataTest + '-driver-form-name'"
        />
        <mat-error *ngIf="form?.controls?.name?.errors?.required">{{
          'shared.errors.required' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.name?.errors?.trimmedString">{{
          'shared.errors.trimmedString' | translate
        }}</mat-error>
<!--        <mat-error *ngIf="form?.controls?.name?.errors?.sameFieldValue">{{-->
<!--          'carrier.directory.driver.errors.nameMatch' | translate-->
<!--        }}</mat-error>-->
      </mat-form-field>
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.patronymic' | translate }}"
          formControlName="patronymic"
          (blur)="onBlurInput('patronymic')"
          [attr.data-test]="dataTest + '-driver-form-patronymic'"
        />
        <mat-error *ngIf="form?.controls?.patronymic?.errors?.trimmedString">{{
          'shared.errors.trimmedString' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.patronymic?.errors?.sameFieldValue">{{
          'carrier.directory.driver.errors.patronymicMatch' | translate
        }}</mat-error>
      </mat-form-field>
    </div>

    <div class="block-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <mat-label>Гражданство</mat-label>
        <mat-select
          formControlName="country"
          [required]="true"
          [transportSelectErrorState]="isUseMatStep"
          [attr.data-test]="dataTest + '-driver-form-country'"
        >
          <mat-option *ngFor="let option of countries" [value]="option.code">
            {{ option.name }}
          </mat-option>
        </mat-select>
        <mat-error *ngIf="form?.controls?.country?.errors?.required">{{
          'shared.errors.required' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.passportSeries' | translate }}"
          [mask]="
            isDriverCountryRussia
              ? regExpConf.ngxMask.citizenPassport.ru.series.mask
              : regExpConf.ngxMask.citizenPassport.any.mask
          "
          [required]="true"
          formControlName="passportSeries"
          [validation]="true"
          [transportInputErrorState]="isUseMatStep"
          [patterns]="
            isDriverCountryRussia
              ? $any(regExpConf.ngxMask.citizenPassport.ru.series.patterns)
              : $any(regExpConf.ngxMask.citizenPassport.any.patterns)
          "
          [attr.data-test]="dataTest + '-driver-form-passport-series'"
        />
        <mat-error *ngIf="form?.controls?.passportSeries?.errors?.required">{{
          'shared.errors.required' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.passportSeries?.errors?.mask">{{
          'carrier.directory.driver.errors.passportSeries' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.passportSeries?.errors?.maxlength">{{
          'shared.errors.maxLength' | translate: limitations.MAX_PASSPORT_SERIES_LENGTH
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.passportSeries?.errors?.trimmedString">{{
          'shared.errors.trimmedString' | translate
        }}</mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.passportNumber' | translate }}"
          [mask]="
            isDriverCountryRussia
              ? regExpConf.ngxMask.citizenPassport.ru.number.mask
              : regExpConf.ngxMask.citizenPassport.any.mask
          "
          [required]="true"
          formControlName="passportNumber"
          [validation]="true"
          [transportInputErrorState]="isUseMatStep"
          [patterns]="
            isDriverCountryRussia
              ? $any(regExpConf.ngxMask.citizenPassport.ru.number.patterns)
              : $any(regExpConf.ngxMask.citizenPassport.any.patterns)
          "
          [attr.data-test]="dataTest + '-driver-form-passport-number'"
        />
        <mat-error *ngIf="form?.controls?.passportNumber?.errors?.required">{{
          'shared.errors.required' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.passportNumber?.errors?.mask">{{
          'carrier.directory.driver.errors.documentNumber' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.passportNumber?.errors?.maxlength">{{
          'shared.errors.maxLength' | translate: limitations.MAX_PASSPORT_NUMBER_LENGTH
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.passportNumber?.errors?.trimmedString">{{
          'shared.errors.trimmedString' | translate
        }}</mat-error>
      </mat-form-field>
    </div>

    <div class="block-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="32px">
      <mat-form-field fxFlex="0 0 66%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.placeGetting' | translate }}"
          [required]="true"
          formControlName="passportPlace"
          [transportInputErrorState]="isUseMatStep"
          [attr.data-test]="dataTest + '-driver-form-passport-place'"
        />
        <mat-error *ngIf="form?.controls?.passportPlace?.errors?.required">
          {{ 'shared.errors.required' | translate }}
        </mat-error>
        <mat-error *ngIf="form?.controls?.passportPlace?.errors?.trimmedString">
          {{ 'shared.errors.trimmedString' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          [matDatepicker]="pickerPassportDate"
          (focus)="pickerPassportDate.open()"
          placeholder="{{ 'carrier.directory.driver.form.dateGetting' | translate }}"
          [required]="true"
          formControlName="passportDate"
          [transportInputErrorState]="isUseMatStep"
          [attr.data-test]="dataTest + '-driver-form-date-getting'"
        />
        <mat-error *ngIf="form?.controls?.passportDate?.errors?.required">
          {{ 'shared.errors.required' | translate }}
        </mat-error>
        <mat-error *ngIf="form?.controls?.passportDate?.errors?.moreThanNow">
          {{ 'shared.errors.moreThanNow' | translate }}
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="pickerPassportDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerPassportDate></mat-datepicker>
      </mat-form-field>
    </div>
  </transport-form-block-new>

  <transport-form-block-new
    [isBorder]="isBorder"
    [title]="'carrier.directory.driver.form.licenceData' | translate"
    [svgIconName]="isBorder ? 'account-card-details' : ''"
    data-test="passportData"
  >
    <div class="block-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.surname' | translate }}"
          [required]="true"
          formControlName="licenceSurname"
          [transportInputErrorState]="isUseMatStep"
          [attr.data-test]="dataTest + '-driver-form-licence-surname'"
        />
        <mat-error *ngIf="form?.controls?.licenceSurname?.errors?.required">{{
          'shared.errors.required' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.licenceSurname?.errors?.trimmedString">{{
          'shared.errors.trimmedString' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.licenceSurname?.errors?.sameFieldValue">{{
          'carrier.directory.driver.errors.licenceSurnameMatch' | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.name' | translate }}"
          [required]="true"
          formControlName="licenceName"
          [transportInputErrorState]="isUseMatStep"
          [attr.data-test]="dataTest + '-driver-form-licence-name'"
        />
        <mat-error *ngIf="form?.controls?.licenceName?.errors?.required">{{
          'shared.errors.required' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.licenceName?.errors?.trimmedString">{{
          'shared.errors.trimmedString' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.licenceName?.errors?.sameFieldValue">{{
          'carrier.directory.driver.errors.licenceNameMatch' | translate
        }}</mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.patronymic' | translate }}"
          formControlName="licencePatronymic"
          [attr.data-test]="dataTest + '-driver-form-licence-patronymic'"
        />
        <mat-error *ngIf="form?.controls?.licencePatronymic?.errors?.trimmedString">{{
          'shared.errors.trimmedString' | translate
        }}</mat-error>
        <mat-error *ngIf="form?.controls?.licencePatronymic?.errors?.sameFieldValue">{{
          'carrier.directory.driver.errors.licencePatronymicMatch' | translate
        }}</mat-error>
      </mat-form-field>
    </div>

    <div class="block-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <div class="passport-inputs" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px" fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <mat-form-field fxFlex="1 0 0">
          <input
            class="text-uppercase"
            matInput
            placeholder="{{ 'carrier.directory.driver.form.licenceSeries' | translate }}"
            [mask]="isDriverCountryRussia ? regExpConf.ngxMask.driverLicence.ru.series.mask : ''"
            [patterns]="
              isDriverCountryRussia ? $any(regExpConf.ngxMask.driverLicence.ru.series.patterns) : {}
            "
            [required]="true"
            formControlName="licenceSeries"
            [validation]="true"
            [transportInputErrorState]="isUseMatStep"
            [attr.data-test]="dataTest + '-driver-form-licence-series'"
          />
          <mat-error *ngIf="form?.controls?.licenceSeries?.errors?.required">
            {{ 'shared.errors.required' | translate }}
          </mat-error>
          <mat-error *ngIf="form?.controls?.licenceSeries?.errors?.mask">
            {{ 'carrier.directory.driver.errors.documentSeries' | translate }}
          </mat-error>
          <mat-error *ngIf="form?.controls?.licenceSeries?.errors?.maxlength">
            {{ 'shared.errors.maxLength' | translate: limitations.MAX_LICENSE_SERIES_LENGTH }}
          </mat-error>
          <mat-error *ngIf="form?.controls?.licenceSeries?.errors?.trimmedString">
            {{ 'shared.errors.trimmedString' | translate }}
          </mat-error>
        </mat-form-field>
        <mat-form-field fxFlex="1 0 0" fxFlex.lt-md="0 0 auto">
          <input
            matInput
            placeholder="{{ 'carrier.directory.driver.form.licenceNumber' | translate }}"
            [mask]="isDriverCountryRussia ? regExpConf.ngxMask.driverLicence.ru.number.mask : ''"
            [required]="true"
            formControlName="licenceNumber"
            [validation]="true"
            [transportInputErrorState]="isUseMatStep"
            [attr.data-test]="dataTest + '-driver-form-licence-number'"
          />
          <mat-error *ngIf="form?.controls?.licenceNumber?.errors?.required">
            {{ 'shared.errors.required' | translate }}
          </mat-error>
          <mat-error *ngIf="form?.controls?.licenceNumber?.errors?.mask">
            {{ 'carrier.directory.driver.errors.documentNumber' | translate }}
          </mat-error>
          <mat-error *ngIf="form?.controls?.licenceNumber?.errors?.maxlength">
            {{ 'shared.errors.maxLength' | translate: limitations.MAX_LICENSE_NUMBER_LENGTH }}
          </mat-error>
          <mat-error *ngIf="form?.controls?.licenceNumber?.errors?.trimmedString">
            {{ 'shared.errors.trimmedString' | translate }}
          </mat-error>
        </mat-form-field>
      </div>
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          placeholder="{{ 'carrier.directory.driver.form.placeGetting' | translate }}"
          [required]="true"
          formControlName="licencePlace"
          [transportInputErrorState]="isUseMatStep"
          [attr.data-test]="dataTest + '-driver-form-licence-place'"
        />
        <mat-error *ngIf="form?.controls?.licencePlace?.errors?.required">
          {{ 'shared.errors.required' | translate }}
        </mat-error>
        <mat-error *ngIf="form?.controls?.licencePlace?.errors?.trimmedString">
          {{ 'shared.errors.trimmedString' | translate }}
        </mat-error>
      </mat-form-field>

      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          [matDatepicker]="pickerLicenseDate"
          (focus)="pickerLicenseDate.open()"
          placeholder="{{ 'carrier.directory.driver.form.dateGetting' | translate }}"
          [required]="true"
          formControlName="licenceDate"
          [transportInputErrorState]="isUseMatStep"
          [attr.data-test]="dataTest + '-driver-form-licence-date'"
        />
        <mat-error *ngIf="form?.controls?.licenceDate?.errors?.required">
          {{ 'shared.errors.required' | translate }}
        </mat-error>
        <mat-error *ngIf="form?.controls?.licenceDate?.errors?.moreThanNow">
          {{ 'shared.errors.moreThanNow' | translate }}
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="pickerLicenseDate"></mat-datepicker-toggle>
        <mat-datepicker #pickerLicenseDate></mat-datepicker>
      </mat-form-field>
    </div>
  </transport-form-block-new>

  <transport-form-block-new
    [isBorder]="isBorder"
    [title]="'carrier.directory.driver.form.info' | translate"
    [svgIconName]="isBorder ? 'text-snippet' : ''"
    data-test="passportData"
  >
    <div class="block-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <div fxLayout="column" fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto" class="driver-phone">
        <mat-form-field class="transport-hidden-input">
          <transport-phone-input
            [required]="true"
            placeholder="{{ 'carrier.directory.driver.form.phone' | translate }}"
            formControlName="phone"
            [dataTest]="dataTest + '-driver-form-phone'"
          ></transport-phone-input>
          <mat-error *ngIf="form?.controls?.phone?.errors?.required" class="driver-phone__error">
            {{ 'shared.errors.required' | translate }}
          </mat-error>
          <mat-error *ngIf="form?.controls?.phone?.errors?.pattern" class="driver-phone__error">
            {{ 'shared.errors.phone' | translate }}
          </mat-error>
        </mat-form-field>
        <div class="driver-status" *ngIf="mode !== 'create'">
          <transport-driver-status-label [usesDriverMobile]="usesDriverMobile"></transport-driver-status-label>
          <span class="driver-status__invite" *ngIf="isShowInvite" (click)="invite()">{{
            inviteDriverText | translate
          }}</span>
        </div>
      </div>
      <mat-form-field class="transport-hidden-input" fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <transport-phone-input
          placeholder="{{ 'carrier.directory.driver.form.addPhone' | translate }}"
          formControlName="addPhone"
          [dataTest]="dataTest + '-driver-form-add-phone'"
        ></transport-phone-input>
        <mat-error *ngIf="form?.controls?.addPhone?.errors?.pattern">
          {{ 'shared.errors.phone' | translate }}
        </mat-error>
      </mat-form-field>
      <mat-form-field fxFlex="0 0 33%" fxFlex.lt-md="0 0 auto">
        <input
          matInput
          [matDatepicker]="pickerBirthday"
          (focus)="pickerBirthday.open()"
          placeholder="{{ 'carrier.directory.driver.form.birthday' | translate }}"
          [required]="true"
          formControlName="birthday"
          [transportInputErrorState]="isUseMatStep"
          [attr.data-test]="dataTest + '-driver-form-birthday'"
        />
        <mat-error *ngIf="form?.controls?.birthday?.errors?.required">
          {{ 'shared.errors.required' | translate }}
        </mat-error>
        <mat-error *ngIf="form?.controls?.birthday?.errors?.lessThanAdultAge">
          {{ 'shared.errors.lessThanAdultAge' | translate }}
        </mat-error>
        <mat-error *ngIf="form?.controls?.birthday?.errors?.moreThanNow">
          {{ 'shared.errors.moreThanNow' | translate }}
        </mat-error>
        <mat-datepicker-toggle matSuffix [for]="pickerBirthday"></mat-datepicker-toggle>
        <mat-datepicker #pickerBirthday></mat-datepicker>
      </mat-form-field>
    </div>
    <div class="block-content" fxLayout="row" fxLayout.lt-md="column" fxLayoutGap="16px">
      <div  fxFlex.lt-md="0 0 auto">
        <label id="gender-label">
          <span class="ng-star-inserted">{{ 'shared.gender.title' | translate }}</span>
        </label>
        <mat-radio-group fxLayout="row" fxLayoutGap="20px" formControlName="gender" aria-labelledby="gender-label" [required]="true">
          <mat-radio-button value="MALE" [attr.data-test]="dataTest + '-driver-form-male'">
            {{ 'shared.gender.male' | translate }}
          </mat-radio-button>
          <mat-radio-button value="FEMALE" [attr.data-test]="dataTest + '-driver-form-female'">
            {{ 'shared.gender.female' | translate }}
          </mat-radio-button>
        </mat-radio-group>
      </div>

      <mat-form-field  fxFlex.lt-md="0 0 auto">
        <input
          transportInnMax
          matInput
          placeholder="{{ 'carrier.directory.driver.form.inn' | translate }}"
          formControlName="inn"
          [attr.data-test]="dataTest + '-driver-form-inn'"
        />
        <mat-error *ngIf="form?.controls?.inn?.errors?.pattern">
          {{ 'carrier.directory.driver.errors.inn' | translate }}
        </mat-error>
      </mat-form-field>
    </div>
  </transport-form-block-new>
</form>
