<div class="dialog-title" mat-dialog-title>
  <h4 class="text-gray-700 font-medium">{{'shared.mp.publications.modal.title' | translate}}</h4>
  <mat-icon class="material-icons dialog-close !h-3.5 !w-3.5" mat-dialog-close>clear</mat-icon>
</div>
<div class="divider"></div>
<div class="!py-3" mat-dialog-content>
  <publish-vehicle-form #formComponent></publish-vehicle-form>
</div>
<div class="actions">
  <div class="help">
    {{'shared.mp.publications.modal.help' | translate}}
    <a [href]="supportPhone.text">{{supportPhone.text}}</a>
  </div>
  <button class="tw-button-solid-sm ml-3" (click)="onOk()">
    <span fxLayout="row" fxLayoutGap="8px">
      <mat-spinner *ngIf="isLoading" [diameter]="20" [strokeWidth]="2"> </mat-spinner>
      <span>{{'shared.mp.publications.modal.submit' | translate}}</span>
    </span>
  </button>
</div>
