<div class="passport-header__navbar items-center" fxLayout="column">
  <ng-container *ngIf="isInvite else defaultLogo">
    <ng-container *ngIf="organizationLoading; then spinner; else logo"></ng-container>
    <ng-template #spinner>
      <div fxLayoutAlign="center" fxLayoutJustify="center">
        <mat-spinner [diameter]="20" strokeWidth="2"> </mat-spinner>
      </div>
    </ng-template>

    <ng-template #logo>
      <img class="passport-header__logo" [src]="organizationLogo" *ngIf="organizationLogo"/>
    </ng-template>
  </ng-container>

  <ng-template #defaultLogo>
    <img class="passport-header__logo" [src]="logoSrc" />
  </ng-template>

  <span *ngIf="organizationName" class="text-gray-700 font-normal text-sm text-center">{{organizationName}}</span>
</div>
