<transport-tw-input-field
  [class]="inputFieldClass"
  [labelClasses]="labelClasses"
  [control]="$any(formControl)"
  [size]="size"
  [errorsSize]="errorsSize"
  [label]="label"
  [labelAddition]="labelAddition"
  [caption]="caption"
  [captionClasses]="captionClasses"
  [showErrors]="showErrors"
  [required]="required"
  [errorToMessage]="errorToMessage"
>
  <transport-tw-input class="w-full" [control]="$any(formControl)" [size]="size">
    <mat-select
      #commonSelect
      disableOptionCentering
      class="text-sm text-gray-700"
      [panelClass]="'tw-menu-block common-select-panel w-full !overflow-x-hidden ' + panelClass"
      [ngModel]="value"
      [placeholder]="placeholder"
      [ngClass]="{'arrow-up': isOpen}"
      [required]="required"
      [disabled]="disabled"
      [attr.data-test]="dataTest"
      (openedChange)="isOpen = $event"
      (selectionChange)="selectionChange($event)"
      (blur)="touch(false)"
    >
      <mat-select-search class="common-select-search" *ngIf="enableSearch" [formControl]="filterCtrl" (blur)="touch(true)">
        <button class="tw-button-ghost-xs absolute right-[34px] top-[4px]" *ngIf='onAdd' (click)='onAdd()'>+ {{'shared.actions.add' | translate }}</button>
      </mat-select-search>
      <mat-select-trigger>
        <div class="flex items-center justify-between">
          <span class="whitespace-nowrap overflow-hidden text-ellipsis">{{value?.label || ''}}</span>
          <div
            *ngIf="allowClear && !disabled && !isEmpty"
            class="flex items-center justify-center cursor-pointer w-5 h-5 mx-1"
            [ngClass]="{'z-[1010]': isOpen}"
          >
            <mat-icon class="!w-[11px] !h-[11px] text-gray-400" svgIcon="close" (click)="clearSelect($event)"></mat-icon>
          </div>
        </div>
      </mat-select-trigger>
      <ng-container *ngIf="(options | async) as optionsList">
        <mat-option [disabled]="true" [ngClass]="{'!hidden': optionsList.length}">
          <div class="flex">
            <transport-tw-menu-option
              class="whitespace-nowrap overflow-hidden text-ellipsis w-full"
              variant="center"
              [text]="'owner.order.publishTypeNew.noOptions' | translate"
              [size]="size">
            </transport-tw-menu-option>
          </div>
        </mat-option>
        <mat-option
          *ngFor="let option of optionsList"
          [value]="option"
          [ngClass]="{'!hidden': (hideSelectedValue && option.id === value?.id) || option.hidden}"
        >
          <div class="flex" [ngClass]='option.hint ? "items-center" : ""'>
            <transport-tw-menu-option
              *ngIf="!isOptionWithTooltip"
              class=" w-full"
              [size]="size"
              [text]="option.label"
              [additionalTextRight]="option.hint || ''"
            ></transport-tw-menu-option>
            <transport-tw-menu-option
              *ngIf="isOptionWithTooltip"
              class="whitespace-nowrap overflow-hidden text-ellipsis w-full"
              [size]="size"
              [text]="option.label"
              [matTooltip]="option.label"
              [matTooltipTouchGestures]="'auto'"
              matTooltipShowDelay="0"
              matTooltipHideDelay="0"
              touchendHideDelay="1000"
            ></transport-tw-menu-option>
          </div>
        </mat-option>
      </ng-container>
    </mat-select>
  </transport-tw-input>
</transport-tw-input-field>
