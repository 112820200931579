import { Injectable } from '@angular/core';
import { OWNER_MUTATIONS, SHARED_MUTATION } from '@transport/gql';
import { transport } from '@transport/proto';
import { IAdminOrganizationDto, ICargoType, ILoadingPlaceForm, IUser, TStateRelationship, USER_ROLE } from '@transport/ui-interfaces';
import { TModify } from '@transport/ui-utils';
import { DocumentNode } from 'graphql';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { TnGqlClientSharedService } from '../gql-client-shared/graphql-client-shared.service';

/**
 * GraphQL client mutations service for owner.
 */
@Injectable({
  providedIn: 'root',
})
export class TnGqlClientOwnerMutationsService {
  /**
   * Service user role.
   */
  protected serviceUserRole: USER_ROLE = USER_ROLE.OWNER;

  /**
   * Constructor.
   */
  constructor(private readonly sharedGraphQlClient: TnGqlClientSharedService) {}

  /**
   * mutation - a write followed by a fetch.
   */
  public mutate<T>(mutation: DocumentNode, variables: Record<string, unknown> = {}, withSpinner?: boolean): Observable<T> {
    return this.sharedGraphQlClient.mutate<T>(this.serviceUserRole, mutation, variables, withSpinner);
  }

  public deleteDraft(draftId: string, withSpinner?: boolean): Observable<Record<string, unknown>> {
    return this.mutate<Record<string, unknown>>(OWNER_MUTATIONS.deleteDraft, { draftId }, withSpinner);
  }

  /**
   * Make mutation to remove cargo type.
   */
  public archiveCargoType(cargoTypeId: string, withSpinner?: boolean): Observable<{ sendCargoTypeToArchive: ICargoType }> {
    return this.mutate<{ sendCargoTypeToArchive: ICargoType }>(OWNER_MUTATIONS.archiveCargoType, { cargoTypeId }, withSpinner);
  }

  /**
   * Make mutation to create new cargo type.
   */
  public addCargoType(
    cargoTypeParam: Record<string, unknown>,
    withSpinner?: boolean,
  ): Observable<{ addCargoType: Pick<ICargoType, 'id'> }> {
    return this.mutate<{ addCargoType: Pick<ICargoType, 'id'> }>(OWNER_MUTATIONS.addCargoType, cargoTypeParam, withSpinner);
  }

  /**
   * Make mutation to create new place.
   */
  public addLoadingUnloadingPlace(img: FileList, place: ILoadingPlaceForm, withSpinner?: boolean) {
    if (Boolean(img) && img.length > 0) {
      return this.mutate<{ addLoadingUnloadingPlace: transport.IPlace }>(OWNER_MUTATIONS.addPlaceWithDrivingImg, {
        drivingDirectionsImg: img[0],
        input: place,
        withSpinner,
      });
    }
    return this.mutate<{ addLoadingUnloadingPlace }>(OWNER_MUTATIONS.addPlace, { input: place }, withSpinner);
  }

  /**
   * Make mutation to update place.
   */
  public editLoadingUnloadingPlace(img: FileList, place: ILoadingPlaceForm, withSpinner?: boolean) {
    return this.mutate<{ editLoadingUnloadingPlace }>(OWNER_MUTATIONS.editPlaceWithDrivingImg, {
      drivingDirectionsImg: Boolean(img) && img.length > 0 ? img[0] : '',
      input: place,
      withSpinner,
    });
  }

  /**
   * Make mutation to remove place.
   */
  public sendLoadingUnloadingPlaceToArchive(placeId: number, withSpinner?: boolean): Observable<Record<string, unknown>> {
    return this.mutate<Record<string, unknown>>(OWNER_MUTATIONS.archivePlace, { storagePointId: placeId }, withSpinner);
  }

  /**
   * Make mutation to update cargo type.
   */
  public editCargoType(
    cargoTypeParam: Record<string, unknown>,
    withSpinner?: boolean,
  ): Observable<{ editCargoType: Pick<ICargoType, 'id'> }> {
    return this.mutate<{ editCargoType: Pick<ICargoType, 'id'> }>(OWNER_MUTATIONS.editCargoType, cargoTypeParam, withSpinner);
  }

  /**
   * Make mutation to edit carrier contract
   */
  public editCarrierOrganizationContract(contractData: Record<string, unknown>, withSpinner?: boolean) {
    return this.mutate<{ editCarrierContract }>(OWNER_MUTATIONS.editCarrierContract, contractData, withSpinner).pipe(
      map(data => data.editCarrierContract),
    );
  }

  /**
   * Make mutation to add carrier contract.
   */
  public addCarrierOrganizationContract(contractData: Record<string, unknown>, withSpinner?: boolean) {
    return this.mutate<{ addCarrierContract }>(OWNER_MUTATIONS.addCarrierContract, contractData, withSpinner).pipe(
      map(data => data.addCarrierContract),
    );
  }

  public addCargoContract(
    input: {
      startDate: string;
      contractNumber: string;
      carrierOrganization: string;
      endDate?: string;
    },
    file: File,
    withSpinner?: boolean,
  ) {
    return this.mutate<{ addCarrierContract }>(OWNER_MUTATIONS.addCargoContract, { input, file }, withSpinner).pipe(
      map(data => data.addCarrierContract),
    );
  }

  /**
   * Make mutation to remove carrier contract.
   */
  public archiveContract(contractId: string, withSpinner?: boolean): Observable<Record<string, unknown>> {
    return this.mutate<Record<string, unknown>>(OWNER_MUTATIONS.archiveContract, { carrierContractId: contractId }, withSpinner);
  }

  /**
   * Make mutation to add order.
   */
  public addOrder(order: transport.IAddOrderInput, withSpinner?: boolean) {
    return this.mutate(OWNER_MUTATIONS.addOrder, { input: order }, withSpinner);
  }

  /**
   * Make mutation to add auction.
   */
  public addAuction(order: transport.IAddAuctionOrderInput, withSpinner?: boolean) {
    return this.mutate(OWNER_MUTATIONS.addAuction, { input: order }, withSpinner);
  }

  public finishAuctionOrder(orderId: string, withSpinner?: boolean) {
    return this.mutate(OWNER_MUTATIONS.finishAuctionOrder, { orderId }, withSpinner);
  }

  public cancelOrderTrading(orderId: string, withSpinner?: boolean) {
    return this.mutate(OWNER_MUTATIONS.cancelOrderTrading, { orderId }, withSpinner);
  }

  public acceptCounterOffer(counterOfferId: string, withSpinner?: boolean) {
    return this.mutate<{ acceptCounterOffer: { ok: boolean } }>(OWNER_MUTATIONS.acceptCounterOffer, { counterOfferId }, withSpinner);
  }

  public acceptCounterOfferAsBet(counterOfferId: string, withSpinner?: boolean) {
    return this.mutate<{ acceptCounterOfferAsBet: { ok: boolean } }>(
      OWNER_MUTATIONS.acceptCounterOfferAsBet,
      { counterOfferId },
      withSpinner,
    );
  }

  public acceptCounterOfferAsBetForBidding(counterOfferId: string, withSpinner?: boolean) {
    return this.mutate<{ acceptCounterOfferAsBetForBidding: { ok: boolean } }>(
      OWNER_MUTATIONS.acceptCounterOfferAsBetForBidding,
      { counterOfferId },
      withSpinner,
    );
  }

  public addSla(sla: transport.ISlaInput, withSpinner?: boolean) {
    return this.mutate(OWNER_MUTATIONS.addSla, { input: sla }, withSpinner);
  }

  public editSla(sla: transport.IEditSlaInput, withSpinner?: boolean) {
    return this.mutate(OWNER_MUTATIONS.editSla, { input: sla }, withSpinner);
  }

  public deleteSla(slaId: string, withSpinner?: boolean) {
    return this.mutate(OWNER_MUTATIONS.deleteSla, { slaId }, withSpinner);
  }

  /**
   * Make mutation to assing carrier.
   */
  public assignCarrier(carrierId: string, orderId: string, withSpinner?: boolean) {
    return this.mutate(OWNER_MUTATIONS.assignCarrier, { carrierId, orderId }, withSpinner);
  }

  public startOrderTrading(orderId: string, isMarket: boolean, lot) {
    return this.mutate(OWNER_MUTATIONS.startOrderTrading, { orderId, isMarket, lot });
  }

  public updateOrder(input) {
    return this.mutate(OWNER_MUTATIONS.updateOrder, { input });
  }

  /**
   * Make mutation to cancel order.
   */
  public cancelOrder(orderId: string, withSpinner?: boolean) {
    return this.mutate(OWNER_MUTATIONS.cancelOrder, { orderId }, withSpinner);
  }

  /**
   * Remove (add to archive) user profile.
   */
  public removeUser(employeeId: string, withSpinner = false): Observable<{ user: IUser }> {
    return this.mutate<{ user: IUser }>(
      SHARED_MUTATION.removeEmployee,
      {
        employeeId,
      },
      withSpinner,
    );
  }

  public resetPassword(id: string, withSpinner = true) {
    return this.mutate<{ resetEmployeePassword: Partial<IUser> }>(
      SHARED_MUTATION.resetProfilePassword,
      {
        employeeId: id,
      },
      withSpinner,
    ).pipe(
      map(value => {
        return value.resetEmployeePassword;
      }),
    );
  }

  /**
   * Save user profile.
   */
  public saveUser(user: transport.IEditProfileInput, withSpinner = false): Observable<{ user: Partial<IUser> }> {
    return this.mutate<{ user: Partial<IUser> }>(
      SHARED_MUTATION.editEmployee,
      {
        input: { ...user },
      },
      withSpinner,
    );
  }

  public inviteUser(user: transport.IEditProfileInput, withSpinner = false): Observable<{ user: Partial<IUser> }> {
    return this.mutate<{ user: Partial<IUser> }>(
      SHARED_MUTATION.addEmployee,
      {
        input: { ...user },
      },
      withSpinner,
    );
  }

  public inviteUserAgain(userId, withSpinner = false): Observable<{ user: Partial<IUser> }> {
    return this.mutate<{ user: Partial<IUser> }>(
      SHARED_MUTATION.resendInvitation,
      {
        employeeId: userId,
      },
      withSpinner,
    );
  }

  /**
   * Save organization.
   */
  public editOrganization(profileDto: IAdminOrganizationDto): Observable<transport.IAdminOrganization> {
    const vat = profileDto.vat;
    delete profileDto.vat;
    return this.mutate<transport.IAdminOrganization>(OWNER_MUTATIONS.saveOrganizationProfile, {
      input: { ...profileDto, vat },
    });
  }

  /**
   * Make mutation to remove sub org.
   */
  public sendSubOrganizationToArchive(organizationId: string, withSpinner?: boolean): Observable<unknown> {
    return this.mutate(OWNER_MUTATIONS.archiveSubOrganization, { organizationId }, withSpinner);
  }

  /**
   * Make mutation to create new sub org.
   */
  public addSubOrganization(subOrganizationDto: IAdminOrganizationDto, withSpinner?: boolean): Observable<transport.IAdminOrganization> {
    return this.mutate<transport.IAdminOrganization>(OWNER_MUTATIONS.addSubOrganization, { input: subOrganizationDto }, withSpinner);
  }

  /**
   * Save carrier group.
   */
  public saveGroup(
    input: Partial<transport.Carrier.ICarrierGroup>,
    withSpinner = false,
  ): Observable<{ group: Partial<transport.Carrier.ICarrierGroup> }> {
    return this.mutate(
      OWNER_MUTATIONS.saveCarrierGroup,
      {
        input: {
          groupId: input.id,
          name: input.name,
          carriersIds: input.carriers?.map(c => c.id),
        },
      },
      withSpinner,
    );
  }

  /**
   * Create a carrier group.
   */
  public createGroup(
    input: Partial<transport.Carrier.ICarrierGroup>,
    withSpinner = false,
  ): Observable<{ group: Partial<transport.Carrier.ICarrierGroup> }> {
    return this.mutate(
      OWNER_MUTATIONS.addCarrierGroup,
      {
        input: {
          name: input.name,
          carriersIds: input.carriers?.map(c => c.id),
        },
      },
      withSpinner,
    );
  }

  public editBusinessRelationship(carrierOrgId: string, state: TStateRelationship): Observable<{ state: TStateRelationship }> {
    return this.mutate(OWNER_MUTATIONS.editBusinessRelationship, { carrierOrgId, state }, false);
  }

  public editBusinessRelationshipFacsimileImg(
    carrierOrgId: string,
    carrierFacsimileImg: File | null,
  ): Observable<{ state: TStateRelationship }> {
    return this.sharedGraphQlClient.mutate(USER_ROLE.OWNER, OWNER_MUTATIONS.editBusinessRelationshipFacsimileImg, {
      carrierOrgId,
      carrierFacsimileImg,
    });
  }

  public rejectCarrier(
    carrierOrgId: string,
    state: TStateRelationship,
    lastChangeReason: string,
  ): Observable<{ state: TStateRelationship }> {
    return this.mutate(OWNER_MUTATIONS.rejectCarrier, { carrierOrgId, state, lastChangeReason }, false);
  }

  /**
   * Remove carrier group
   */
  public removeGroup(groupId: string, withSpinner = false): Observable<{ group: transport.Carrier.ICarrierGroup }> {
    return this.mutate(OWNER_MUTATIONS.archiveCarrierGroup, { groupId }, withSpinner);
  }

  public createNewInsuranceContract(
    input: TModify<Partial<transport.Insurance.IContract>, { insuranceCompanyCodename: string; tariff: number }>,
    withSpinner = false,
  ): Observable<{ contract: Partial<transport.Insurance.IContract> }> {
    return this.mutate(
      OWNER_MUTATIONS.addInsuranceContract,
      {
        input,
      },
      withSpinner,
    );
  }

  public saveInsuranceContract(
    input: TModify<Partial<transport.Insurance.IContract>, { insuranceCompanyCodename: string; tariff: number }>,
    withSpinner = false,
  ): Observable<{ contract: Partial<transport.Insurance.IContract> }> {
    return this.mutate(
      OWNER_MUTATIONS.editInsuranceContract,
      {
        input,
      },
      withSpinner,
    );
  }

  public removeInsuranceContract(contractId: string, withSpinner = false): Observable<{ contract: Partial<transport.Insurance.Contract> }> {
    return this.mutate(OWNER_MUTATIONS.archiveInsuranceContract, { contractId }, withSpinner);
  }
}
