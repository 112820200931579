<div class="flex flex-col h-full" [ngClass]="{'border-r border-gray-300': mainSidebarComponent}" *ngIf="(burgerService.menuState$ | async) as menuState">
  <div [ngClass]="{'min-h-[60px]': mainSidebarComponent}">
    <ng-content select="[logo]"></ng-content>
  </div>
  <div class="mx-4 relative" *ngIf="mainSidebarComponent">
    <mat-divider></mat-divider>
    <div
      class="sidebar-arrow"
      (click)="burgerMenuClick()"
      *ngIf="!burgerService.isMobileDevice"
    >
      <mat-icon *ngIf="menuState === MENU_STATE.OUT" class="!h-3 !w-3 mt-[4.5px] ml-[6px]" svgIcon="arrow-right"></mat-icon>
      <mat-icon *ngIf="menuState === MENU_STATE.IN" class="!h-3 !w-3 mt-[4.5px] ml-[4px]" svgIcon="arrow-left"></mat-icon>
    </div>
  </div>

  <div class="flex flex-col h-full overflow-auto">
    <div *ngIf="!(isRegCompleted$ | async) && mainSidebarComponent" class="w-full rounded-md bg-gray-50 p-4 shadow-md">
      <ng-container *ngIf="menuState === MENU_STATE.IN">
        <p class="text-xs text-gray-700 text-center">{{'shared.profile.user.organization.completeRegistration' | translate}} <b>{{'shared.profile.user.organization.settings' | translate}}</b></p>
        <button class="tw-button-solid-sm w-full mt-1" (click)="goToProfile()">{{'shared.profile.user.organization.goTo' | translate}}<mat-icon>arrow_forward</mat-icon></button>
      </ng-container>
      <ng-container *ngIf="menuState === MENU_STATE.OUT">
        <mat-icon class="text-orange-300"
                  [matTooltip]="'shared.profile.user.organization.completeRegistration' | translate"
                  matTooltipShowDelay="0"
                  matTooltipHideDelay="0">info
        </mat-icon>
      </ng-container>
    </div>
    <ng-content select="[verification]"></ng-content>
    <ng-content select="[other]"></ng-content>
    <notifications-popup class="flex pl-4 py-3 hover:bg-gray-200" *ngIf="mainSidebarComponent" [withTitle]="menuState === MENU_STATE.IN" [isMarketplace]="isMarket"></notifications-popup>

    <ng-container *ngFor="let item of items">
      <ng-container *ngIf="!item.children?.length">
        <mat-divider *ngIf="item.hasDivider" class="divider"></mat-divider>
        <a
          [attr.data-test]="'sidebar-item-' + item.dataTest ?? ''"
          fxLayout
          routerLinkActive="active-link"
          [routerLink]="item.route"
          [queryParams]="item.queryParams"
          class="child cursor-pointer"
          [ngClass]="getItemClasses(item)"
        >
          <img
            class="h-6 w-6 mr-3"
            fxFlexAlign="center"
            [src]="getIconSrc(item)"
            [matTooltip]="menuState === MENU_STATE.OUT ? (item.title | translate) : ''"
            matTooltipShowDelay="0"
            matTooltipHideDelay="0"
            *ngIf="item.icon"
          >
          <span *ngIf="menuState === MENU_STATE.IN" class="mr-1">{{item.title | translate}}</span>
        </a>
      </ng-container>

      <ng-container *ngIf="item.children?.length">
        <mat-divider *ngIf="item.hasDivider" class="divider"></mat-divider>
        <div class="title" [ngClass]="{'hidden': !item.title}" *ngIf="menuState === MENU_STATE.IN">
          {{item.title | translate}}
        </div>
        <sidebar [items]="item.children ?? []" [mainSidebarComponent]="false"></sidebar>
      </ng-container>
    </ng-container>

    <ng-content select="[mpPartners]"></ng-content>

    <div class="flex flex-col" *ngIf="mainSidebarComponent">
      <mat-divider class="divider"></mat-divider>
      <transport-user-info
        class="info hover:bg-gray-200 !rounded-none !px-0"
        fxFlexOffset.xs="0"
        [isWarnMode]="isSupervisor"
        [endSessionTime]="isMarket ? currentUserService.currentUser.tokenExpiresDate ?? '' : currentUserFacade.currentUser.tokenExpiresDate ?? ''"
        (completeWork)="completeWork()"
      >
        <button data-test="menu-button" [matMenuTriggerFor]="menu" class="flex items-center pl-4 py-3 w-full">
          <mat-icon
            svgIcon="sidebar-user"
            class="profile-icon"
            [matTooltip]="menuState === MENU_STATE.OUT ? ('shared.passport.tooltip' | translate) : ''"
            matTooltipShowDelay="0"
            matTooltipHideDelay="0"
          ></mat-icon>
          <span class="ml-2 text-sm" *ngIf="menuState === MENU_STATE.IN">{{(user$ | async)?.shortName}}</span>
        </button>
        <mat-menu #menu="matMenu" overlapTrigger="false" class="user-mat-menu bg-white">
          <div *ngIf="user$ | async as user" transportTooltip class="user-info pt-3 px-4 pt-2" [isGlobalMode]="true">
            <div class="user-info__name text-wrapper" tn-tip="true">{{ user.shortName }}</div>
            <div class="user-info__email text-wrapper" tn-tip="true">{{ user.email }}</div>
            <div class="user-info__company text-wrapper" tn-tip="true">{{ user.companyName }}</div>
          </div>
          <span mat-menu-item [routerLink]="['/profile/user/info']" routerLinkActive="router-link-active">
            <div class="flex border-gray-200 rounded-md items-center">
              <mat-icon class="material-icons-svg" svgIcon="sidebar-user"></mat-icon>
              <span class="label">{{ 'shared.passport.settings' | translate }}</span>
            </div>
          </span>

          <span class="logout-menu-item" data-test="logout-menu-item" mat-menu-item (click)="logout()">
            <mat-icon class="material-icons" svgIcon="logout"></mat-icon>
            <span class="label">{{ 'shared.passport.logout.title' | translate }}</span>
          </span>
        </mat-menu>
      </transport-user-info>

      <ng-container *ngIf="menuState === MENU_STATE.IN">
        <mat-divider class="divider"></mat-divider>
        <div class="flex flex-col pl-4 mt-3 mb-1">
          <span class="text-sm mb-2">{{'shared.masterTms.sidebar.items.title.support' | translate}}</span>
          <a class="text-sm text-gray-500 mb-2 hover:underline" [href]="'tel:' + supportPhone.text">{{supportPhone.text}}</a>
          <a class="text-sm text-gray-500 hover:underline" [href]="'mailto:' + supportEmail.text">{{supportEmail.text}}</a>
        </div>
      </ng-container>
    </div>
  </div>
</div>
