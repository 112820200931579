import { Overlay, OverlayModule, OverlayRef } from '@angular/cdk/overlay';
import { NgModule, Provider } from '@angular/core';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { TnGlobalProgressBarComponent } from './global-progress-bar/global-progress-bar.component';
import { TnProgressEffects } from './progress.effects';
import { progressReducer } from './progress.reducers';
import { TnProgressService } from './progress.service';

/**
 * Progress service factory.
 * @param overlay overlay service
 */
export function createTnProgressService(overlay: Overlay) {
  const spinnerTopRef: OverlayRef = overlay.create({
    hasBackdrop: true,
    backdropClass: 'global-spinner-backdrop-dark',
    positionStrategy: overlay.position().global().centerHorizontally().centerVertically(),
  });
  return new TnProgressService(spinnerTopRef);
}

export const tnProgressModuleProviders: Provider[] = [
  {
    provide: TnProgressService,
    useFactory: (overlay: Overlay) => createTnProgressService(overlay),
    deps: [Overlay],
  },
];

export const PROGRESS_EFFECTS = [TnProgressEffects];

@NgModule({
  imports: [
    OverlayModule,
    MatProgressBarModule,
    StoreModule.forFeature('progress', progressReducer),
    EffectsModule.forFeature(PROGRESS_EFFECTS),
  ],
  exports: [StoreModule, EffectsModule],
  declarations: [TnGlobalProgressBarComponent],
  entryComponents: [TnGlobalProgressBarComponent],
  providers: [...tnProgressModuleProviders],
})
export class TnProgressModule {}
