import { FocusMonitor } from '@angular/cdk/a11y';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DoCheck,
  ElementRef,
  HostBinding,
  Input,
  OnDestroy,
  Optional,
  Renderer2,
  Self,
  ViewChild,
} from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormControl, NgControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatDialog } from '@angular/material/dialog';
import { MatFormFieldControl } from '@angular/material/form-field';
import { UntilDestroy } from '@ngneat/until-destroy';
import { TnDadataFacade } from '@transport/ui-store';
import { TIMEOUT } from '@transport/ui-utils';
import { Subject } from 'rxjs';
import { debounceTime, filter, first, takeUntil, tap } from 'rxjs/operators';

import { TnBaseMatInputDirective } from '../base-mat-input';
import { TnAddressInputDialogComponent } from './address-input-dialog/address-input-dialog.component';
import { TnSupportedCountry } from '@transport/ui-pipes';

interface IAddressAutocompleteItem {
  label: string;
  value: IAddressAutocompleteItemValue;
}

export interface IAddressAutocompleteItemValue {
  fullAddress: string;
  isUserInput: boolean;
}

const trimmedStringInputValidator = (control: AbstractControl) => {
  const value = control.value;
  const canTrimmed = typeof value === 'string';
  if (Boolean(value) && canTrimmed && (value as string).trim().length === 0) {
    return {
      trimmedString: true,
    };
  }
  return null;
};

const NUMBER_OF_ITEMS_IN_AUTOCOMPLETE_LIST = 10;

@UntilDestroy()
@Component({
  selector: 'transport-address-input',
  templateUrl: './address-input.component.html',
  styleUrls: ['./address-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: TnAddressInputComponent,
    },
  ],
})
/* eslint-disable prettier/prettier -- prettier conflicts with eslint (brace style), eslint takes precedence here */
export class TnAddressInputComponent
  extends TnBaseMatInputDirective<{ fullAddress: string, isUserInput: boolean }>
  implements ControlValueAccessor, OnDestroy, DoCheck, AfterViewInit, MatFormFieldControl<unknown> {
  /* eslint-enable prettier/prettier -- prettier conflicts with eslint in this case (brace style), eslint takes precedence here */

  @Input() public formControl!: FormControl;

  @Input() public address? = '';

  @Input() public country = TnSupportedCountry.RU;

  @Input() public latitude? = '';

  @Input() public longitude? = '';

  @Input() public dataTest? = '';

  @Input() public withMap? = true;

  @HostBinding('attr.id')
  public id = `address-input-1`;

  public searchInputControl = new FormControl('', trimmedStringInputValidator);

  private readonly shouldCompleteSearchInputMonitoringSubject = new Subject<boolean>();

  public selectData$ = this.tnDadataFacade.dadataSuggestions$;

  @ViewChild('inputElement') public inputElementRef?: ElementRef<HTMLInputElement>;

  public bindedValue = (value: IAddressAutocompleteItem) => {
    return value?.label ?? '';
  };

  @HostBinding('class.floating')
  public get shouldLabelFloat() {
    return true;
  }

  constructor(
    public fm: FocusMonitor,
    public elRef: ElementRef<HTMLElement>,
    @Optional() @Self() public ngControl: NgControl,
    private readonly tnDadataFacade: TnDadataFacade,
    private readonly dialog: MatDialog,
    private readonly renderer: Renderer2,
    private readonly cdr: ChangeDetectorRef,
  ) {
    super(fm, elRef, ngControl);
  }

  public getDaData = (address: string) =>
    this.tnDadataFacade.getMapPointsByAddress({
      address,
      country: this.country,
      countResults: NUMBER_OF_ITEMS_IN_AUTOCOMPLETE_LIST,
    });

  public optionSelected(event: MatAutocompleteSelectedEvent) {
    const option: IAddressAutocompleteItem = event.option.value;
    this.value = option.value;
    this.propagateChange(this.value);
  }

  public setRequiredFormError(): void {
    this.searchInputControl.setErrors({ required: true });
    this.searchInputControl.markAsTouched();
    this.cdr.markForCheck();
  }

  public openAddressInputDialog(event: Event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    const dialogRef = this.dialog.open(TnAddressInputDialogComponent, {
      width: '80%',
      height: '550px',
      data: {
        fullAddress: this.address ?? '',
        latitude: this.latitude ?? '',
        longitude: this.longitude ?? '',
        country: this.country,
      },
    });

    void dialogRef
      .afterClosed()
      .pipe(first())
      .subscribe((value: { fullAddress: string; isUserInput: boolean }) => {
        if (Boolean(value)) {
          this.onUpdateFromExternalSource(value);
        }
      });
  }

  public ngAfterViewInit() {
    if (Boolean(this.address)) {
      setTimeout(() => this.onUpdateFromExternalSource({ fullAddress: this.address as string, isUserInput: true }));
    } else {
      this.initSearchInputMonitoring();
    }
  }

  private onUpdateFromExternalSource(value: { fullAddress: string; isUserInput: boolean }) {
    this.shouldCompleteSearchInputMonitoringSubject.next(true);
    this.value = value;
    this.propagateChange(this.value);
    this.renderer.setProperty(this.inputElementRef?.nativeElement, 'value', value.fullAddress);
    this.inputElementRef?.nativeElement.dispatchEvent(new Event('input', { bubbles: true }));

    this.initSearchInputMonitoring();
  }

  private initSearchInputMonitoring() {
    void this.searchInputControl.valueChanges
      .pipe(
        debounceTime(TIMEOUT.DEFAULT),
        filter(value => typeof value === 'string'),
        tap((value: string) => {
          if (Boolean(value) && this.country !== TnSupportedCountry.NO) {
            this.getDaData(value);
          } else {
            this.inputValue = void 0;
          }
          this.value = { fullAddress: value, isUserInput: true };
          this.propagateChange(this.value);
        }),
        takeUntil(this.shouldCompleteSearchInputMonitoringSubject),
      )
      .subscribe();
  }
}
