import { NgModule } from '@angular/core';
import { TnVerificationModalComponent } from 'apps/marketplace/src/app/verification/verification-modal/verification-modal.component';
import { TnUiLayoutModule } from '@transport/transport-ui-layout';
import { TnUiComponentsModule } from '@transport/ui-components';
import { TnClSsoFrameModule } from '@transport/ui-store';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { TnContactsFormComponent } from 'apps/marketplace/src/app/verification/forms/contacts-from/contacts-form.component';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnVehicleFormComponent } from './forms/vehicle-form/vehicle-form.component';
import { TnVerificationModalFooterComponent } from 'apps/marketplace/src/app/verification/verification-modal/verification-modal-footer.component';
import { TnSendingFormComponent } from './forms/sending-form/sending-form.component';
import { NotificationComponent } from './verification-modal/notification/notification.component';
import { TnOrganizationFormComponent } from 'apps/marketplace/src/app/verification/forms/organization-form/organization-form.component';
import { TnIndividualBusinessmanComponent } from 'apps/marketplace/src/app/verification/forms/organization-form/individual-businessman.component';
import { InfoStepComponent } from './verification-modal/info-step/info-step.component';
import { VerificationFlowService } from './verification-flow.service';

@NgModule({
  declarations: [
    TnVerificationModalComponent,
    TnContactsFormComponent,
    TnVerificationModalFooterComponent,
    TnVehicleFormComponent,
    TnSendingFormComponent,
    NotificationComponent,
    TnOrganizationFormComponent,
    TnIndividualBusinessmanComponent,
    InfoStepComponent,
  ],
  imports: [TnUiKitModule, TnUiLayoutModule, TnUiComponentsModule, TnClSsoFrameModule, TnUiDirectivesModule],
  providers: [VerificationFlowService],
})
export class VerificationModule {}
