import { NgModule } from '@angular/core';
import { Route, RouterModule } from '@angular/router';
import { TnAuthenticatedGuard, TnHelpGuard, TnPermissionGuard } from '@transport/ui-store';
import { NotificationsComponent } from 'libs/notifications/src/lib/components';
import { NotFoundComponent } from 'libs/transport-ui-layout/src/lib/components/not-found/not-found.component';
import { OwnerProfileModule } from '@transport/owner-profile';
import { CheckMobileGuard } from './check-mobile.guard';
import { USER_ROLE } from '@transport/ui-interfaces';

export enum TRANSPORT_APP_ROUTES_LINK {
  PASSPORT = 'passport',
  SUPERVISOR = 'supervisor',
  NOTIFICATION = 'notifications',
  ORDER = 'order',
  ORDERS = 'orders',
  MONITORING = 'monitoring',
  RECONCILIATION = 'reconciliation',
  SERVICE_REGISTRY = 'service-registry',
  INSURANCE = 'insurance',
  DIRECTORY = 'directory',
  CARRIERS = 'carriers',
  TRACKING = 'tracking',
  PROFILE = 'profile',
  OWNER_PROFILE = 'owner-profile',
  ADMIN = 'admin',
  AGENT_CARRIERS = 'agent-carriers',
  REPORTS = 'reports',
  SLA = 'sla',
  INTEGRATION = 'integration',
  HELP = 'help',
  ROUTING = 'routing',
  TENDERS = 'tenders',
  QUATAS = 'quotas',
  PRETENSIONS = 'pretensions',
  BLACKLIST = 'black-list',
  VEHICLES = 'vehicles',
  DRIVERS = 'drivers',
}
/**
 * Application routes.
 */
const APP_ROUTES: Array<Route> = [
  {
    path: TRANSPORT_APP_ROUTES_LINK.PASSPORT,
    loadChildren: () => import('@transport/passport').then(mod => mod.TnPassportModule),
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.SUPERVISOR,
    loadChildren: () => import('@transport/supervisor').then(mod => mod.TnSupervisorModule),
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.NOTIFICATION,
    component: NotificationsComponent,
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.ORDER,
    loadChildren: () => import('@transport/order/owner-order').then(mod => mod.OwnerOrderModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.TENDERS,
    loadChildren: () => import('@transport/tender').then(mod => mod.TenderModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
    data: {
      roles: [USER_ROLE.OWNER],
    },
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.QUATAS,
    loadChildren: () => import('@transport/quotas').then(mod => mod.QuotasModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
    data: {
      roles: [USER_ROLE.OWNER],
    },
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.PRETENSIONS,
    loadChildren: () => import('@transport/pretensions').then(mod => mod.PretensionsModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
    data: {
      roles: [USER_ROLE.OWNER],
    },
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.BLACKLIST,
    loadChildren: () => import('./black-list/black-list-routing.module').then(mod => mod.BlackListRoutingModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.ORDERS,
    loadChildren: () => import('@transport/order/owner-order-list').then(mod => mod.OwnerOrdersListModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.MONITORING,
    loadChildren: () => import('@transport/transport-monitoring').then(mod => mod.TransportMonitoringModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.DIRECTORY,
    loadChildren: () => import('@transport/directory').then(mod => mod.TnDirectoryModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.TRACKING,
    loadChildren: () => import('@transport/tracking').then(mod => mod.TnTrackingModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.OWNER_PROFILE,
    loadChildren: () => import('@transport/owner-profile').then(mod => mod.OwnerProfileModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.PROFILE,
    loadChildren: () => import('@transport/profile').then(mod => mod.TnProfileModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.CARRIERS,
    loadChildren: () => import('@transport/carriers').then(mod => mod.OwnerCarriersModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.VEHICLES,
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
    loadChildren: () => import('@transport/vehicles').then(mod => mod.VehiclesModule),
    data: {
      roles: [USER_ROLE.OWNER],
    },
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.DRIVERS,
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
    loadChildren: () => import('@transport/drivers').then(mod => mod.DriversModule),
    data: {
      roles: [USER_ROLE.OWNER],
    },
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.ADMIN,
    loadChildren: () => import('@transport/admin').then(mod => mod.TnAdminModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.REPORTS,
    loadChildren: () => import('@transport/transport-report').then(mod => mod.TnTransportReportModule),
    canActivate: [TnAuthenticatedGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.SLA,
    loadChildren: () => import('@transport/transport-sla').then(mod => mod.TnSlaModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.INTEGRATION,
    loadChildren: () => import('@transport/transport-integration').then(mod => mod.TransportIntegrationModule),
    canActivate: [TnAuthenticatedGuard, TnPermissionGuard, CheckMobileGuard],
    data: { permissionName: 'integrationToken' },
  },
  {
    path: TRANSPORT_APP_ROUTES_LINK.ROUTING,
    loadChildren: () => import('@transport/transport-routings').then(mod => mod.TransportRoutingsModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  //App has no links to /help route, cuz https://tndl.kaiten.ru/space/50858/card/5161434
  {
    path: TRANSPORT_APP_ROUTES_LINK.HELP,
    loadChildren: () => import('@transport/help').then(mod => mod.TnTransportHelpModule),
    canActivate: [TnAuthenticatedGuard, TnHelpGuard],
  },
  {
    path: 'r',
    loadChildren: () => import('@transport/transport-redirect').then(mod => mod.TnTransportRedirectModule),
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'passport/login',
  },
  {
    path: '**',
    component: NotFoundComponent,
    canActivate: [TnAuthenticatedGuard, CheckMobileGuard],
  },
];

/**
 * Application routing module.
 */
@NgModule({
  imports: [
    RouterModule.forRoot(APP_ROUTES, {
      onSameUrlNavigation: 'reload',
      relativeLinkResolution: 'legacy',
    }),
  ],
  exports: [RouterModule],
})
export class TnAppRoutingModule {}
