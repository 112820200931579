import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatChipsModule } from '@angular/material/chips';
import { RouterModule } from '@angular/router';
import { TnYamapsCoreModule } from '@transport/client-yamaps-core';
import { CustomControlsModule } from '@transport/custom-controls';
import { TnUiDirectivesModule } from '@transport/ui-directives';
import { TnUiKitModule } from '@transport/ui-kit';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiPipesModule } from '@transport/ui-pipes';
import { TnFormModule } from '@transport/ui-store';
import { TnUiTranslateModule } from '@transport/ui-translate';
import { ngfModule } from 'angular-file';
import { AddSelectorWithTemplateOptionsComponent } from 'libs/transport-notifications/src/lib/components/add-selector-with-template-options/add-selector-with-template-options.component';
import { NgxMaskModule } from 'ngx-mask';

import { TnAddSelectComponent } from '../../../transport-notifications/src/lib/components/add-select/add-select.component';
import { TnGroupSelectComponent } from '../../../transport-notifications/src/lib/components/group-select/group-select.component';
import { TnModalInviteComponent } from '../../../transport-notifications/src/lib/components/modal-invite/modal-invite.component';
import { TnAuctionLotStepSettingsComponent } from './auction-lot-step-settings/auction-lot-step-settings.component';
import { TnBackToListButtonComponent } from './back-to-list-button/back-to-list-button.component';
import { TnBlackListCardComponent } from './black-list-card/black-list-card.component';
import { TnBlacklistColumnComponent } from './black-list-column/black-list-column.component';
import { TnBlackListModalComponent } from './black-list-modal/black-list-modal.component';
import { TnAddDriverModalComponent } from './card-pointer-driver/add-driver-modal/add-driver-modal.component';
import { TnCardPointerDriverComponent } from './card-pointer-driver/card-pointer-driver.component';
import { TnChooseDriverModal } from './card-pointer-driver/choose-driver-dialog/choose-driver-dialog.component';
import { TnContactsOrderComponent } from './contacts-order/contacts-order.component';
import { TnAutocompletePositionDirective } from './directives';
import { TnDragIconComponent } from './drag-icon/drag-icon.component';
import { TnDriverFormComponent } from './driver/driver-form.component';
import { TnDriverInviteModal } from './driver/driver-invite-dialog/driver-invite-dialog.component';
import { TnDriverStatusLabelComponent } from './driver/driver-status-label/driver-status-label.component';
import { TnEmptyListComponent } from './empty-list/empty-list.component';
import { TnEntityFlowButtonsComponent } from './entity-flow-buttons/entity-flow-buttons.component';
import { TnFileIconComponent } from './file-icon/file-icon.component';
import { TnFilesListComponent } from './files-list/files-list.component';
import { TnFilterDialogComponent } from './filter-dialog/filter-dialog.component';
import { TnFormBlockNewComponent } from './form-block-new/form-block.component';
import { TnAddressInputComponent } from './inputs/address-input/address-input.component';
import { TnAddressInputDialogComponent } from './inputs/address-input/address-input-dialog/address-input-dialog.component';
import { AddressInputComponentKit } from './inputs/address-input-kit/address-input-kit.component';
import { TnAutocompleteComponent } from './inputs/autocomplete/autocomplete.component';
import { TnAutocompleteContractorComponent } from './inputs/autocomplete-contractor/autocomplete-contractor.component';
import { TnAutocompleteWithAdditionComponent } from './inputs/autocomplete-with-addition/autocomplete-with-addition.component';
import { TnDateRangeInputComponent } from './inputs/date-range-input/date-range-input.component';
import { TnFiasAutocompleteInputComponent } from './inputs/fias-autocomplete-input/fias-autocomplete-input.component';
import { TnFilesInputComponent } from './inputs/files-input/files-input.component';
import { TnNumberInputComponent } from './inputs/number-input/number-input.component';
import { TnPhoneInputComponent } from './inputs/phone-input/phone-input.component';
import { TnTimeInputComponent } from './inputs/time-input/time-input.component';
import { TnFileAdditionalAttributesContainerComponent } from './order-documents/components/additional-attributes/file-additional-attributes-container/file-additional-attributes-container.component';
import { TnWaybillFormComponent } from './order-documents/components/additional-attributes/waybill-form/waybill-form.component';
import { TnUploadAreaComponent } from './order-documents/components/upload-area/upload-area.component';
import { TnUploadModal } from './order-documents/components/upload-dialog/upload-dialog.component';
import { TnWaybillViewDialogModal } from './order-documents/components/waybill-view-dialog/waybill-view-dialog.component';
import { TnPageTitleComponent } from './page-title/page-title/page-title.component';
import { TnMpPdfImageViewerModule } from './pdf-image-viewer/pdf-image-viewer.module';
import { TnPlaceStepBetComponent } from './place-step-bet/place-step-bet.component';
import { TnSettingsContainerComponent } from './settings-container/settings-container.component';
import { TnStatusChipComponent } from './status-chip/status-chip.component';
import { TnColumnReorderDirective } from './table/column-reorder.directive';
import { TnColumnResizerDirective } from './table/column-resizer.directive';
import { TnTableComponent } from './table/table.component';
import { TnTableCellPipe } from './table/table-cell.pipe';
import { TnTableColumnComponent } from './table/table-column/table-column.component';
import { TnTableColumnBadgeComponent } from './table/table-column-badge/table-column-badge.component';
import { TnTableColumnTextComponent } from './table/table-column-text/table-column-text.component';
import { TnTableSearchCellComponent } from './table/table-search-cell/table-search-cell.component';
import { TnTableItemActionsButtonsComponent } from './table-item-actions-buttons/table-item-actions-buttons.component';
import { TnTableItemUploadContractComponent } from './table-item-upload-contract/table-item-upload-contract.component';
import { TnTableSettingsComponent } from './table-settings/table-settings.component';
import { TnToolbarComponent } from './toolbar/toolbar.component';
import { TnBodyInfoComponent } from './trucks/body-info/body-info.component';
import { TnTrailerInfoComponent } from './trucks/trailer-info/trailer-info.component';
import { TnTruckFormComponent } from './trucks/truck-form/truck-form.component';
import { TnTruckHelpIconComponent } from './trucks/truck-help-icon/truck-help-icon.component';
import { TnTruckIdentificatorTooltipComponent } from './trucks/truck-identificator-tooltip/truck-identificator-tooltip.component';
import { TnVerificationStatusComponent } from './verification-status/verification-status.component';
import { TnWarningBlockComponent } from './warning-block/warning-block.component';
import { TnWarningMessageComponent } from './warning-message/warning-message.component';
import { WarningTerminationAgreementSignComponent } from './warning-termination-agreement-sign/warning-termination-agreement-sign.component';

@NgModule({
  imports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule,
    NgxMaskModule,
    TnUiTranslateModule,
    TnUiMaterialModule,
    TnUiPipesModule,
    TnUiDirectivesModule,
    TnFormModule,
    ngfModule,
    TnYamapsCoreModule,
    TnUiKitModule,
    MatChipsModule,
    OverlayModule,
    TnMpPdfImageViewerModule,
    CustomControlsModule,
  ],
  declarations: [
    TnEntityFlowButtonsComponent,
    TnPhoneInputComponent,
    TnDateRangeInputComponent,
    TnTimeInputComponent,
    TnCardPointerDriverComponent,
    TnChooseDriverModal,
    TnNumberInputComponent,
    TnContactsOrderComponent,
    TnFormBlockNewComponent,
    TnTableItemActionsButtonsComponent,
    TnFiasAutocompleteInputComponent,
    TnTableSettingsComponent,
    TnSettingsContainerComponent,
    TnFilesListComponent,
    TnFileIconComponent,
    TnDragIconComponent,
    TnWarningBlockComponent,
    TnBackToListButtonComponent,
    TnUploadAreaComponent,
    TnWaybillViewDialogModal,
    TnUploadModal,
    TnFilesInputComponent,
    TnTrailerInfoComponent,
    TnBodyInfoComponent,
    TnTruckFormComponent,
    TnDriverFormComponent,
    TnDriverStatusLabelComponent,
    TnDriverInviteModal,
    TnVerificationStatusComponent,
    TnEmptyListComponent,
    TnBlacklistColumnComponent,
    TnBlackListCardComponent,
    TnFileAdditionalAttributesContainerComponent,
    TnWaybillFormComponent,
    TnTableComponent,
    TnTableColumnComponent,
    TnTableSearchCellComponent,
    TnColumnResizerDirective,
    TnColumnReorderDirective,
    TnTableCellPipe,
    TnPageTitleComponent,
    TnStatusChipComponent,
    TnBlackListModalComponent,
    TnFilterDialogComponent,
    TnTruckIdentificatorTooltipComponent,
    TnTruckHelpIconComponent,
    TnAddressInputComponent,
    TnAddressInputDialogComponent,
    TnModalInviteComponent,
    TnAddSelectComponent,
    TnToolbarComponent,
    TnWarningMessageComponent,
    TnPlaceStepBetComponent,
    TnAutocompleteComponent,
    TnAutocompleteContractorComponent,
    TnAddDriverModalComponent,
    TnAutocompleteWithAdditionComponent,
    TnTableColumnTextComponent,
    TnTableColumnBadgeComponent,
    TnTableItemUploadContractComponent,
    TnAutocompletePositionDirective,
    TnGroupSelectComponent,
    AddSelectorWithTemplateOptionsComponent,
    AddressInputComponentKit,
    TnAuctionLotStepSettingsComponent,
    WarningTerminationAgreementSignComponent,
  ],
  entryComponents: [TnChooseDriverModal, TnWaybillFormComponent, TnAddressInputDialogComponent],
  exports: [
    CommonModule,
    FlexLayoutModule,
    FormsModule,
    ReactiveFormsModule,
    TnUiMaterialModule,
    TnUiPipesModule,
    TnUiTranslateModule,
    NgxMaskModule,
    TnEntityFlowButtonsComponent,
    TnPhoneInputComponent,
    TnDateRangeInputComponent,
    TnTimeInputComponent,
    TnCardPointerDriverComponent,
    TnChooseDriverModal,
    TnNumberInputComponent,
    TnContactsOrderComponent,
    TnFormBlockNewComponent,
    TnTableItemActionsButtonsComponent,
    TnFiasAutocompleteInputComponent,
    TnTableSettingsComponent,
    TnSettingsContainerComponent,
    TnFilesListComponent,
    TnWarningBlockComponent,
    TnBackToListButtonComponent,
    TnUploadAreaComponent,
    TnWaybillViewDialogModal,
    TnUploadModal,
    TnFileIconComponent,
    TnDragIconComponent,
    TnFilesInputComponent,
    TnTrailerInfoComponent,
    TnBodyInfoComponent,
    TnTruckFormComponent,
    TnDriverFormComponent,
    TnDriverInviteModal,
    TnVerificationStatusComponent,
    TnEmptyListComponent,
    TnBlacklistColumnComponent,
    TnBlackListCardComponent,
    TnFileAdditionalAttributesContainerComponent,
    TnTableComponent,
    TnTableColumnComponent,
    TnTableSearchCellComponent,
    TnPageTitleComponent,
    TnStatusChipComponent,
    TnBlackListModalComponent,
    TnFilterDialogComponent,
    TnTruckIdentificatorTooltipComponent,
    TnTruckHelpIconComponent,
    TnAddressInputComponent,
    TnAddressInputDialogComponent,
    TnModalInviteComponent,
    TnAddSelectComponent,
    TnToolbarComponent,
    TnWarningMessageComponent,
    TnPlaceStepBetComponent,
    MatChipsModule,
    TnAutocompleteComponent,
    TnAutocompleteContractorComponent,
    TnAutocompleteWithAdditionComponent,
    TnTableColumnTextComponent,
    TnTableColumnBadgeComponent,
    TnTableItemUploadContractComponent,
    TnAutocompletePositionDirective,
    TnGroupSelectComponent,
    AddSelectorWithTemplateOptionsComponent,
    AddressInputComponentKit,
    TnMpPdfImageViewerModule,
    TnAuctionLotStepSettingsComponent,
    WarningTerminationAgreementSignComponent
  ],
})
export class TnUiComponentsModule {}
