import { A11yModule } from '@angular/cdk/a11y';
import { ClipboardModule } from '@angular/cdk/clipboard';
import { OverlayModule } from '@angular/cdk/overlay';
import { ModuleWithProviders, NgModule, Provider } from '@angular/core';
import { MatAutocompleteModule } from '@angular/material/autocomplete';
import { MatBadgeModule } from '@angular/material/badge';
import { MatButtonModule } from '@angular/material/button';
import { MatButtonToggleModule } from '@angular/material/button-toggle';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import {
  DateAdapter,
  MAT_DATE_FORMATS,
  MAT_DATE_LOCALE,
  MatDateFormats,
  MatNativeDateModule,
  MatOptionModule,
  MatRippleModule,
} from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatDialogModule } from '@angular/material/dialog';
import { MatDividerModule } from '@angular/material/divider';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import {
  MAT_PAGINATOR_DEFAULT_OPTIONS,
  MatPaginatorDefaultOptions,
  MatPaginatorIntl,
  MatPaginatorModule,
} from '@angular/material/paginator';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatRadioModule } from '@angular/material/radio';
import { MatSelectModule } from '@angular/material/select';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSliderModule } from '@angular/material/slider';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatSortModule } from '@angular/material/sort';
import { MatStepperModule } from '@angular/material/stepper';
import { MatTableModule } from '@angular/material/table';
import { MatTabsModule } from '@angular/material/tabs';
import { MatToolbarModule } from '@angular/material/toolbar';
import { MAT_TOOLTIP_DEFAULT_OPTIONS, MatTooltipDefaultOptions, MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import {
  MAT_MOMENT_DATE_ADAPTER_OPTIONS,
  MatMomentDateAdapterOptions,
  MatMomentDateModule,
  MomentDateAdapter,
} from '@angular/material-moment-adapter';
import { HAMMER_GESTURE_CONFIG, HammerModule } from '@angular/platform-browser';
import { NgSelectModule } from '@ng-select/ng-select';

import { TnHammerGestureConfig } from './configs/hammerjs/hammerjs-gesture.config';
import { TnMatPaginatorIntl } from './services/mat-paginator-intl.service.ts/transport-mat-paginator-intl.service';

/**
 * Returns mat tooltip options.
 */
function matTooltipOptions(): MatTooltipDefaultOptions {
  const options: MatTooltipDefaultOptions = {
    showDelay: 1000,
    hideDelay: 1000,
    touchendHideDelay: 1000,
  };
  return options;
}

function matPaginatorOptions(): MatPaginatorDefaultOptions {
  const options: MatPaginatorDefaultOptions = {
    pageSize: 20,
    formFieldAppearance: 'legacy',
  };
  return options;
}

/**
 * Material moment date adapter options factory.
 */
function matMomentDateAdapterOptionsFactory(): MatMomentDateAdapterOptions {
  return {
    useUtc: false,
  };
}

const CUSTOM_DATE_FORMATS: MatDateFormats = {
  parse: {
    dateInput: 'DD.MM.YYYY',
  },
  display: {
    dateInput: 'DD.MM.YYYY',
    monthYearLabel: 'MMM YYYY',
    dateA11yLabel: 'LL',
    monthYearA11yLabel: 'MMMM YYYY',
  },
};

/**
 * Custom Material Module providers.
 */
const tnUiMaterialModuleProviders: Provider[] = [
  {
    provide: MAT_TOOLTIP_DEFAULT_OPTIONS,
    useFactory: matTooltipOptions,
  },
  {
    provide: MAT_DATE_LOCALE,
    useValue: 'en',
  },
  {
    provide: MAT_MOMENT_DATE_ADAPTER_OPTIONS,
    useFactory: matMomentDateAdapterOptionsFactory,
  },
  { provide: MAT_DATE_FORMATS, useValue: CUSTOM_DATE_FORMATS },
  { provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE] },
  {
    provide: HAMMER_GESTURE_CONFIG,
    useFactory: () => new TnHammerGestureConfig(),
  },
  { provide: MatPaginatorIntl, useClass: TnMatPaginatorIntl },
  {
    provide: MAT_PAGINATOR_DEFAULT_OPTIONS,
    useFactory: matPaginatorOptions,
  },
];

/**
 * Custom material module without providers.
 * Exports material modules only.
 */
@NgModule({
  imports: [
    // Form controls
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatRadioModule,
    // Navigation
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    // Layout
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    // Buttons and indicators
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    // Popups and modals
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    // Data table
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    // Misc
    MatOptionModule,
    MatRippleModule,
    // Divider
    MatDividerModule,
    // Tree
    MatTreeModule,
    // Badge
    MatBadgeModule,
    // CDK
    OverlayModule,
    // Accessibility: https://material.angular.io/cdk/a11y/api
    A11yModule,
    // Touch gestures
    HammerModule,
    // Custom
    NgSelectModule,
    ClipboardModule,
  ],
  exports: [
    // Form controls
    MatAutocompleteModule,
    MatCheckboxModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatMomentDateModule,
    MatInputModule,
    MatSelectModule,
    MatSliderModule,
    MatSlideToggleModule,
    MatRadioModule,
    // Navigation
    MatMenuModule,
    MatSidenavModule,
    MatToolbarModule,
    // Layout
    MatListModule,
    MatGridListModule,
    MatCardModule,
    MatStepperModule,
    MatTabsModule,
    MatExpansionModule,
    // Buttons and indicators
    MatButtonModule,
    MatButtonToggleModule,
    MatChipsModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatProgressBarModule,
    // Popups and modals
    MatDialogModule,
    MatSnackBarModule,
    MatTooltipModule,
    // Data table
    MatTableModule,
    MatSortModule,
    MatPaginatorModule,
    // Misc
    MatOptionModule,
    MatRippleModule,
    // Divider
    MatDividerModule,
    // Tree
    MatTreeModule,
    // Badge
    MatBadgeModule,
    // CDK
    OverlayModule,
    // Accessibility: https://material.angular.io/cdk/a11y/api
    A11yModule,
    // Touch gestures
    HammerModule,
    // Custom
    NgSelectModule,
    ClipboardModule,
  ],
  providers: [...tnUiMaterialModuleProviders],
})
export class TnUiMaterialModule {
  /**
   * Provides services.
   */
  public static forRoot(): ModuleWithProviders<TnUiMaterialModule> {
    return {
      ngModule: TnUiMaterialModule,
      providers: [...tnUiMaterialModuleProviders],
    };
  }
}
