import { ChangeDetectionStrategy, Component, Input, OnDestroy, OnInit, TemplateRef, ViewChild } from '@angular/core';
import { AUCTION_CRITERION_CHOICES, ORDER_STATUS, TOAST_DURATION } from '@transport/ui-interfaces';
import { BehaviorSubject, tap, delayWhen, timer, catchError, finalize, take, of, switchMap } from 'rxjs';
import { AuctionPopupService, AuctionPopupViewEnum } from './auction-popup.service';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { MAX_LENGTH_200, TIMEOUT } from '@transport/ui-utils';
import { TnAlertService } from '@transport/ui-kit';
import { IAuctionPopupContext } from '../popup-container/popup.interface';
import { TranslateService } from '@ngx-translate/core';
import { ALERT_STATE, IAlertInputs } from 'libs/transport-ui-kit/src/lib/alert/alert.interfaces';
import { FormControl, ValidationErrors, Validators } from '@angular/forms';
import { CurrencySigns, TnSupportedCurrency } from '@transport/ui-pipes';
import { OwnerPublicOfferService } from 'libs/marketplace-shared/src/lib/services/owner-public-offer.service';

@UntilDestroy(this)
@Component({
  selector: 'marketplace-auction-popup',
  templateUrl: './auction-popup.component.html',
  styleUrls: ['./auction-popup.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AuctionPopupComponent implements OnInit, OnDestroy {
  @Input() popupContext!: IAuctionPopupContext;

  @ViewChild('placeBetSuccess') public orderSuccessRef!: TemplateRef<never>;
  @ViewChild('placeBetError') public orderErrorRef!: TemplateRef<never>;

  public currentPrice = 0;
  public lotStartPrice = 0;

  public showSendCounterOffer = true;

  public popupView = AuctionPopupViewEnum;

  public currentView$ = this.auctionPopupService.currentView$.asObservable();

  public counterOfferPriceControl = new FormControl(0, [Validators.required, Validators.min(1)]);
  public counterOfferVatControl = new FormControl(true);
  public counterOfferComment = new FormControl(null, Validators.maxLength(MAX_LENGTH_200));
  public placeStepBetComment = new FormControl(null, Validators.maxLength(MAX_LENGTH_200));

  public loading = new BehaviorSubject(false);
  public loadingPrice = new BehaviorSubject(false);
  private spinnerTimeout: number = TIMEOUT.SHORTEST;
  public lastBet: number = 0;

  constructor(
    public readonly auctionPopupService: AuctionPopupService,
    public alertService: TnAlertService,
    private translateService: TranslateService,
    private ownerPublicOfferService: OwnerPublicOfferService,
  ) {}

  ngOnInit(): void {
    this.popupContext.price$
      .pipe(
        tap(() => this.loadingPrice.next(true)),
        delayWhen(() => timer(this.spinnerTimeout)),
        untilDestroyed(this),
      )
      .subscribe(price => {
        this.spinnerTimeout = TIMEOUT.DEFAULT;
        if (!price) {
          this.currentPrice = this.popupContext.startPrice;
          this.lotStartPrice = this.popupContext.startPrice;
        } else {
          this.currentPrice = price;
          this.lotStartPrice = price;
        }
        this.lastBet = price;
        this.loadingPrice.next(false);
      });
  }

  ngOnDestroy() {
    this.auctionPopupService.currentView$.next(this.popupView.bet);
  }

  public get isCancelled() {
    return this.popupContext.status$.value === ORDER_STATUS.CANCELED;
  }

  public get hasCounterOffer(): boolean {
    return this.popupContext.hasMyCounterOffer;
  }

  public placeBet(bet: {bet: number, additionalCriteriaData?: {type: AUCTION_CRITERION_CHOICES, value: number}[]}): void {
    if (!this.placeStepBetComment.valid) {
      return;
    }
    this.ownerPublicOfferService
      .openOwnerPublicOfferDialog(this.popupContext.publicOffer)
      .pipe(
        take(1),
        switchMap(value => {
          if (!value) return of(false);
          this.loading.next(true);

          return this.auctionPopupService.placeBet(
            bet,
            this.popupContext.lotId,
            this.popupContext.acceptingPlace,
            this.placeStepBetComment.value,
          );
        }),
        tap(response => {
          if (response) {
            this.alertService.openAlertFromTemplate(this.orderSuccessRef, TOAST_DURATION.LONG, {
              title: this.translateService.instant('shared.mp.cargo.table.popup.auction.betSuccess'),
            });
          }

          return response;
        }),
        catchError((err: Error) => {
          this.openAlertFailure(err);
          return of(null);
        }),
      )
      .subscribe(() => {
        this.loading.next(false);
      });
  }

  public sendCounterOffer(): void {
    if (this.counterOfferPriceControl.valid && this.counterOfferComment.valid) {
      this.ownerPublicOfferService
        .openOwnerPublicOfferDialog(this.popupContext.publicOffer)
        .pipe(
          take(1),
          switchMap(value => {
            if (!value) return of(false);

            this.loading.next(true);
            return this.auctionPopupService.addOrderCounterOffer(
              {
                amount: this.counterOfferPriceControl.value,
                currency: this.popupContext.currency ?? TnSupportedCurrency.RUB,
                vatIncluded: this.counterOfferVatControl.value,
                orderId: this.popupContext.lotId as unknown as number,
                comment: this.counterOfferComment.value,
              },
              this.popupContext.acceptingPlace,
            );
          }),
          tap(response => {
            if (response) {
              this.orderSuccessRef &&
                this.alertService.openAlertFromTemplate(this.orderSuccessRef, TOAST_DURATION.LONG, {
                  title: this.translateService.instant('shared.mp.counterOfferPopup.counterOfferSuccess'),
                });
            }
          }),
          catchError(err => {
            this.openAlertFailure(err);
            return of(null);
          }),
          finalize(() => {
            this.loading.next(false);
          }),
        )
        .subscribe();
    }
  }

  public priceControlErrorToMessage: (errors: ValidationErrors | null) => string = errors => {
    if (typeof errors?.required !== 'undefined') return this.translateService.instant('owner.order.errors.required');
    if (typeof errors?.min !== 'undefined')
      return this.translateService.instant('owner.order.errors.lessThanStep', { value: 1, currency: this.currencySign });
    return '';
  };

  private openAlertFailure(err: Error) {
    this.alertService.openAlert(
      {
        title: this.translateService.instant('shared.mp.cargo.table.popup.auction.betError'),
        state: ALERT_STATE.ERROR,
        message: err.message,
      } as IAlertInputs,
      TOAST_DURATION.SHORT,
    );
  }

  public get currencySign() {
    return CurrencySigns[this.popupContext.currency ?? TnSupportedCurrency.RUB];
  }

  public validationErrors(e: ValidationErrors | null) {
    if (e?.maxlength) {
      return this.translateService.instant('shared.errors.maxCommentLength', { value: 200 });
    }
    return '';
  }
}
