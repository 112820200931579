<form *ngIf="form" [fxLayout]="direction" [formGroup]="form">
  <mat-form-field [ngClass]="addClassByDerection" [fxFlex]="flexFieldParam">
    <input
      matInput
      transportClearInput
      autocomplete="off"
      [matDatepicker]="pickerFrom"
      (focus)="pickerFrom.open()"
      placeholder="{{ fromPlaceholder | translate }}"
      formControlName="fromDate"
      data-test="from-date"
      [required]="fromDateRequired"
    />
    <mat-error *ngIf="form.controls.fromDate.errors?.required">{{
      'shared.errors.required' | translate
    }}</mat-error>
    <mat-error *ngIf="form.controls.fromDate.errors?.outOfBounds">{{
      'shared.errors.dateStartBounds' | translate
    }}</mat-error>
    <mat-datepicker-toggle matSuffix [for]="pickerFrom"></mat-datepicker-toggle>
    <mat-datepicker #pickerFrom></mat-datepicker>
  </mat-form-field>

  <mat-form-field [fxFlex]="flexFieldParam">
    <input
      matInput
      transportClearInput
      autocomplete="off"
      [matDatepicker]="pickerTo"
      (focus)="pickerTo.open()"
      placeholder="{{ toPlaceholder | translate }}"
      formControlName="toDate"
      data-test="to-date"
      [required]="toDateRequired"
    />
    <mat-error *ngIf="form.controls.toDate.errors?.required">{{
      'shared.errors.required' | translate
    }}</mat-error>
    <mat-error *ngIf="form.controls.toDate.errors?.outOfBounds">{{
      'shared.errors.dateEndBounds' | translate
    }}</mat-error>
    <mat-datepicker-toggle matSuffix [for]="pickerTo"></mat-datepicker-toggle>
    <mat-datepicker #pickerTo></mat-datepicker>
  </mat-form-field>
</form>
