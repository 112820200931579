export enum COUNTER {
  ZERO = 0,
  ONE = 1,
  TWO = 2,
  THREE = 3,
  FOUR = 4,
  FIVE = 5,
  SIX = 6,
  SEVEN = 7,
  EIGHT = 8,
  NINE = 9,
  TEN = 10,
  ELEVEN = 11,
  TWELVE = 12,
  THIRTEEN = 13,
  FOURTEEN = 14,
  FIFTEEN = 15,
  SIXTEEN = 16,
  SEVENTEEN = 17,
  EIGHTEEN = 18,
  NINETEEN = 19,
  TWENTY = 20,
}

export enum TIMEOUT {
  SHORTEST = 100,
  SHORTER = 250,
  SHORT = 500,
  DEFAULT = 1000,
  MEDIUM = 3000,
  LONG = 5000,
  HUGE = 10000,
  GIANT = 300000,
}

export const SEND_CODE_TIMEOUT = 45000;

export const MAX_INTEGER = 2147483647;
export const MAX_EMAIL_LENGTH = 254;
export const MAX_LENGTH_150 = 150;
export const MAX_LENGTH_200 = 200;
export const MAX_USER_NAME_LENGTH = 30;
export const MIN_PASSWORD_LENGTH = 8;

export const MAX_DOMAIN_LENGTH = 63;
export const MIN_DOMAIN_LENGTH = 2;

export const DEFAULT_MAX_FILE_SIZE = 20971520;
export const DOC_FILE_ACCEPT_EXTENSIONS = 'application/pdf,image/jpeg,image/png,image/tiff';
export const DOC_FILE_ACCEPT_EXTENSIONS_SHORT = '.doc, .docx, .jpeg, .pdf, .jpg, .png, .tiff';
export const DOC_FILE_ACCEPT_EXTENSIONS_SHORT_ARRAY = ['.doc', '.docx', '.jpeg', '.pdf', '.jpg', '.png', '.tiff'];

export const CONFIRMATION_WIDTH_DEFAULT = 375;

export const DIALOG_WIDTH_DEFAULT = 420;
export const DIALOG_WIDTH_LARGE = 800;
export const DIALOG_WIDTH_HUGE = 1204;
export const DIALOG_HEIGHT_HUGE = 640;

export const DEFAULT_API_DOMAIN_URL = 'api.dev-tms.tn-dl.ru';
export const TEST_API_DOMAIN_URL = 'api.test-tms.tn-dl.ru';
export const DEMO_API_DOMAIN_URL = 'api.demo-tms.tn-dl.ru';
export const APP_VERSION = '1.20.41514';
export const TRANSPORT_DEFAULT_API_DOMAIN_URL = 'transport-api.tms-demo1.tn-dl.ru';
export const LOGIN_DOMAIN_URL = 'transport.tms-demo1.tn-dl.ru';

export const LICENSE_AGREEMENT_URL = 'https://tn-dl.ru/legal/';
export const PRIVACY_POLICY_URL = 'https://tn-dl.ru/legal/';

// Для работы с сабдоменами
export const DEV_DOMAIN_URL = 'dev-tms.tn-dl.ru';
export const DEV1_DOMAIN_URL = 'dev1-tms.tn-dl.ru';
export const DEMO_DOMAIN_URL = 'demo-tms.tn-dl.ru';
export const TEST_DOMAIN_URL = 'test-tms.tn-dl.ru';
export const STAGING_DOMAIN_URL = 'transport-staging.tms-staging.tn-dl.ru';
export const STAGE_DOMAIN_URL = 'stage-tms.tn-dl.ru';
export const STAGE_DEMO_DOMAIN_URL = 'stage-demo-tms.tn-dl.ru';
export const DEVOPS_DOMAIN_URL = 'tnbk.ru';
export const DEL01_DOMAIN_URL = 'front1.rnd.tn-dl.ru';
export const DEL02_DOMAIN_URL = 'front2.rnd.tn-dl.ru';
export const DEL_DOMAIN_URL = 'front.rnd.tn-dl.ru';
export const LOADTEST_DOMAIN_URL = 'loadtest-tms.tn-dl.ru';
export const PROD_DOMAIN_URL = 'master-tms.ru';

export const SERVER_MARKETPLACE_URL = 'market';

export const DEV_MARKETPLACE_URL = 'market.dev-tms.tn-dl.ru';
export const DEV1_MARKETPLACE_URL = 'market.dev1-tms.tn-dl.ru';
export const TEST_MARKETPLACE_URL = 'market.test-tms.tn-dl.ru';
export const DEMO_MARKETPLACE_URL = 'market.demo-tms.tn-dl.ru';
export const STAGE_MARKETPLACE_URL = 'market.stage-tms.tn-dl.ru';
export const STAGE_DEMO_MARKETPLACE_URL = 'market.stage-demo-tms.tn-dl.ru';
export const PROD_MARKETPLACE_URL = 'market.master-tms.ru';
export const LOADTEST_MARKETPLACE_URL = 'market.loadtest-tms.tn-dl.ru';
export const LOADTEST_MARKETPLACE = 'loadtest-tms.tn-dl.ru';
export const LOCAL_HOST_DOMAIN = 'lv4.lv3.lv2.localhost';

export const LOCAL_SSO_URL = 'sso.localhost:4400';
export const DEV_SSO_URL = 'sso.dev-tms.tn-dl.ru';
export const DEV1_SSO_URL = 'sso.dev1-tms.tn-dl.ru';
export const TEST_SSO_URL = 'sso.test-tms.tn-dl.ru';
export const LOADTEST_SSO_URL = 'sso.loadtest-tms.tn-dl.ru';
export const DEMO_SSO_URL = 'sso.demo-tms.tn-dl.ru';
export const STAGE_SSO_URL = 'sso.stage-tms.tn-dl.ru';
export const STAGE_DEMO_SSO_URL = 'sso.stage-demo-tms.tn-dl.ru';
export const PROD_SSO_URL = 'sso.master-tms.ru';

// При изменение портов поменять в package.json
export const DEV_TRANSPORT_PORT = '4200';
export const DEV_MARKETPLACE_PORT = '4300';
export const DEV_SSO_PORT = '4400';

export const LIVE_TOOLTIP = 3000;

export const CLOUD_TMS_LOGO_URL = 'assets/tms-default-black-logo.svg';
export const CLOUD_TMS_LOGO_URL_MINI = 'assets/tms-logo-mini.svg';
export const CLOUD_EXCHANGE_LOGO_URL = 'assets/TN-logo.svg';
export const CLOUD_EXCHANGE_LOGO_MINI_URL = 'assets/TN-logo-mini.svg';

export const TONNA_MARKETPLACE_LOGO_URL = 'assets/TN-logo.svg';
export const TONNA_MARKETPLACE_LOGO_MINI_URL = 'assets/TN-logo-mini.svg';
export const TONNA_MARKETPLACE_BURGER_URL = 'assets/icons/burger.svg';

export const TONNA_MARKETPLACE_ARROW_RIGHT_URL = 'assets/icons/arrow_right.svg';
export const TONNA_MARKETPLACE_DOT_BLUE_URL = 'assets/icons/dot_blue.svg';
export const TONNA_MARKETPLACE_DOT_BLACK_URL = 'assets/icons/dot_black.svg';
export const TONNA_MARKETPLACE_TIMER_URL = 'assets/icons/timer.svg';
export const TONNA_MARKETPLACE_MONEY_URL = 'assets/icons/money_paper.svg';
export const TONNA_MARKETPLACE_GAVEL_URL = 'assets/icons/gavel_outline.svg';
export const TONNA_MARKETPLACE_PRIZE_URL = 'assets/icons/prize.svg';

export const MASTER_ARROW_LEFT_URL = 'assets/icons/arrow_left.svg';
export const MASTER_ARROW_RIGHT_URL = 'assets/icons/arrow_right.svg';

export const KEYDOWN_DELAY = 720;

export const KEYDOWN_DELAY_SHORT = 360;

export const MIN_ORGANIZATION = 3;
export const MAX_ORGANIZATION = 300;
export const MAX_LIST_ORGANIZATION = 3;

export const INN_PHYS_LENGTH = 12;
export const INN_LEGAL_LENGTH = 10;

export const ORG_CAN_CREATE = 'ORGANIZATION_CAN_BE_CREATED';
export const ORG_EXISTS = 'ORGANIZATION_ALREADY_EXISTS';
export const ORG_DOES_NOT_EXISTS = 'ORGANIZATION_DOES_NOT_EXIST';
export const ORG_NOT_ACTIVE = 'ORGANIZATION_IS_NOT_ACTIVE';

export enum PLACE_TYPE_POINT {
  LOADING = 'LOADING',
  UNLOADING = 'UNLOADING',
}

export const CARGO_MIN_PRICE = 0.01;
export const CARGO_MAX_PRICE = 999999999.99;

export const SECRET_FERNET = '4BFRoHhzncKSmT34aEEq7vgf64x2Ll2ZfMEkOb4A6po=';

export const CLASS_PRINT_IFRAME = 'printPdf';

export const REFRIGERATOR_ID = '4';
export const CONTAINER_ID = '7';

export enum MODULES {
  ROUTING = 'routing',
  MONITORING = 'monitoring',
  COMMON_TENDERS = 'common_tenders',
  TN_TENDERS = 'tenders',
  PRETENSIONS = 'pretensions',
  BLACKLIST = 'black_list',
}
