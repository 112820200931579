<transport-tw-input-field
  [class]="inputFieldClass"
  [control]="formControl"
  [size]="size"
  [errorsSize]="errorsSize"
  [label]="label"
  [labelClasses]="labelClasses"
  [labelAddition]="labelAddition"
  [caption]="caption"
  [captionClasses]="captionClasses"
  [showErrors]="showErrors"
  [required]="required"
  [errorToMessage]="errorToMessage"
>
  <transport-tw-input class="relative" [size]="size" [control]="formControl" [leftAddon]="leftAddon" [rightAddon]="rightAddon">
    <ng-content></ng-content>
    <input
      tw-input
      [className]="inputClasses"
      [name]="autocomplete"
      [autocomplete]="autocomplete"
      [mask]="mask"
      [patterns]="patterns"
      [formControl]="$any(formControl)"
      [placeholder]="placeholder"
      [required]="required"
      [attr.disabled]="disabled ? true : null"
      [attr.data-test]="dataTest"
      [type]="inputType"
    />
  </transport-tw-input>
</transport-tw-input-field>
