<h4 mat-dialog-title class="title text-gray-700 font-medium !mb-3">{{service?.vm?.title || ''}}</h4>

<ng-container *ngIf="(service?.vm?.vehicle$ | async) as data">
  <div *ngIf="data?.blackListVehicle?.message?.length || data?.blackListTrailer?.message?.length" class="mb-3 !mt-[-24px]">
    <span class="underline underline-offset-2 decoration-dashed cursor-pointer text-sm text-orange-500"
          (click)="showLimitations = !showLimitations">Есть ограничения</span>
    <div *ngIf="showLimitations" class="flex flex-col p-2 rounded-md bg-gray-100 max-h-[200px] overflow-auto">
      <span *ngIf="data?.blackListVehicle?.message?.length">{{'shared.mp.vehicles.table.header.tractor' | translate | titlecase}}</span>
      <p *ngFor="let item of data?.blackListVehicle?.message" class="text-xs">
        <strong>{{item?.cargoOwner?.name}}</strong>:
        {{(item?.workProhibited ? 'shared.mp.vehicles.blackList.inBlackList' : 'shared.mp.vehicles.blackList.hasWarning') | translate}}.<br>
        {{'shared.mp.vehicles.blackList.reason' | translate}}: {{item?.reason}}<br>
        {{'shared.mp.vehicles.blackList.description' | translate}}: {{item?.description}}<br>
      </p>
      <span *ngIf="data?.blackListTrailer?.message?.length">{{'shared.mp.vehicles.table.header.trailer' | translate | titlecase}}</span>
      <p *ngFor="let item of data?.blackListTrailer?.message" class="text-xs">
        <strong>{{item?.cargoOwner?.name}}</strong>:
        {{(item?.workProhibited ? 'shared.mp.vehicles.blackList.inBlackList' : 'shared.mp.vehicles.blackList.hasWarning') | translate}}.<br>
        {{'shared.mp.vehicles.blackList.reason' | translate}}: {{item?.reason}}<br>
        {{'shared.mp.vehicles.blackList.description' | translate}}: {{item?.description}}<br>
      </p>
    </div>
  </div>
</ng-container>

<div *ngIf="service && service.vm" class="!max-h-screen" mat-dialog-content>
  <vehicle-form [vm]="service.vm"></vehicle-form>
</div>
<div class="flex justify-end !min-h-0" fxLayoutGap="12px" *ngIf="service && service.vm" mat-dialog-actions>
  <button (click)="service.close()" class="tw-button-ghost-sm !text-blue-500">
    {{ 'carrier.directory.marketplaceDriver.buttons.cancel' | translate }}
  </button>
  <button (click)="action()" class="tw-button-solid-sm" [disabled]="service.vm.loading$ | async">
    <span fxLayout="row" fxLayoutGap="8px">
      <mat-spinner *ngIf="service.vm.loading$ | async" [diameter]="20" [strokeWidth]="2"> </mat-spinner>
      <span>{{service.vm.actionBtnTxt}}</span>
    </span>
  </button>
</div>
