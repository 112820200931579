import { transport } from '@transport/proto';
import moment from 'moment';

import { createNewCargoOwner } from '../cargo-owner/cargo-owner.interface';
import { TCargoPackagingTypeCode } from '../cargo-type';
import { createNewCargoType } from '../cargo-type/cargo-type.interface';
import { createNewCarrier } from '../carrier/carrier.interface';
import { createNewDriver } from '../driver/driver.interface';
import { createNewInsurance, createNewOwnerInsuranceContract } from '../insurance';
import { createNewLot } from '../lot/lot.interface';
import { createNewPlaces } from '../place/place.interface';
import { createNewVehicle } from '../vehicle/vehicle.interface';
import { ORDER_ALLOCATION_TYPE, ORDER_SIGNING_WAY, ORDER_STATUS } from './order-common.interface';
import { TnSupportedCurrency } from 'libs/transport-ui-pipes/src/lib/currency/multi-currency.pipe';
import { PriceChange } from 'libs/order/owner-order/src/lib/components/shared/bet-history-modal/bet-history.service';

export const MS_AMOUNT_OF_SECOND = 1000;

export const createNewVehicleRequirementsNullableFields = (input: Partial<transport.Order.IVehicleRequirements> = {}) => {
  const res = transport.Order.VehicleRequirements.toObject(new transport.Order.VehicleRequirements(input), {
    defaults: true,
  }) as transport.Order.IVehicleRequirements;
  return {
    ...res,
    capacity: input?.capacity ?? null,
    height: input?.height ?? null,
    length: input?.length ?? null,
    volume: input?.volume ?? null,
    width: input?.width ?? null,
    bodySubtype: input?.bodySubtype ?? null,
  };
};

export interface IPlaceRequirements extends transport.Order.IPlaceRequirements {
  packagingType: TCargoPackagingTypeCode;
}

export const createNewPlaceRequirementsWithNullableFields = (input?: Partial<IPlaceRequirements>) => {
  const res = transport.Order.PlaceRequirements.toObject(new transport.Order.PlaceRequirements(input), {
    defaults: true,
  }) as IPlaceRequirements;

  return {
    ...res,
    height: input?.height ?? null,
    cargoPackagesCount: input?.cargoPackagesCount ?? null,
    length: input?.length ?? null,
    packagingType: input?.packagingType ?? null,
    width: input?.width ?? null,
  };
};

export enum PROPOSAL_DECISION {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface IOrderProposal<K extends keyof IOrder> {
  approvingOrgId: number | string | null;
  id?: number | string | null;
  changeId?: number | string | null;
  objectType: 'ORDER';
  objectId: number | string;
  changes: {
    name: K;
    oldValue: IOrder[K];
    newValue: IOrder[K];
  }[];
  decision?: PROPOSAL_DECISION | null;
}

export enum EDM_STATUS {
  /** Ошибка формирования документа */
  DOCUMENT_CREATING_ERROR = 'DOCUMENT_CREATING_ERROR',
  /** На подписании у Заказчика */
  CARGO_OWNER_SIGNING = 'CARGO_OWNER_SIGNING',
  /** Подписано Заказчиком. На проверке в КриптоТН */
  CARGO_OWNER_SIGNATURE_CHECKING = 'CARGO_OWNER_SIGNATURE_CHECKING',
  /** Подписано Заказчиком */
  CARGO_OWNER_SIGNATURE_OK = 'CARGO_OWNER_SIGNATURE_OK',
  /** Ошибка электронной подписи ГО */
  CARGO_OWNER_SIGNATURE_ERROR = 'CARGO_OWNER_SIGNATURE_ERROR',
  /** Подписано Грузоперевозчиком. На проверке */
  CARRIER_SIGNATURE_CHECKING = 'CARRIER_SIGNATURE_CHECKING',
  /** Подписано */
  SIGNED = 'SIGNED',
  /** Ошибка электронной подписи ГП */
  CARRIER_SIGNATURE_ERROR = 'CARRIER_SIGNATURE_ERROR',
  /** на подписании у ГП */
  CARRIER_SIGNING = 'CARRIER_SIGNING',
  /** подписано ГП и проверено ГВ(пока не используется) */
  CARRIER_SIGNATURE_OK = 'CARRIER_SIGNATURE_OK',
}

export interface IOrder extends transport.IOrder {
  betsCount?: number;
  status: ORDER_STATUS;
  signingWay: ORDER_SIGNING_WAY;
  allocationType: ORDER_ALLOCATION_TYPE;
  waiverRequired?: boolean;
  isFactPointDatetimeRequired?: boolean;
  edmStatuses?: {
    contract: EDM_STATUS | null;
    termAgreement: EDM_STATUS | null;
  };
  tenderNumber: string | null;
  carriagePrice?: {
    name: string | null;
    price: string | null;
    vatAmount: string | null;
    currency: TnSupportedCurrency | null;
  };
  priceHistory?: PriceChange[];
  customFields?: { key: string; title: string; value: string }[] | null;
}

export type TPartialOrder = Partial<IOrder>;

// eslint-disable-next-line complexity -- TODO: tech debt
export const createNewOrder = (input: Partial<IOrder> = {}) => {
  input.loadingPlace = input.loadingPlace ?? null;
  input.unloadingPlace = input.unloadingPlace ?? null;
  input.loadingPlaces = input.loadingPlaces ?? createNewPlaces();
  input.unloadingPlaces = input.unloadingPlaces ?? createNewPlaces();
  input.carrier = input.carrier ?? createNewCarrier();
  input.cargoType = input.cargoType ?? createNewCargoType();
  input.vehicleRequirements = input.vehicleRequirements ?? createNewVehicleRequirementsNullableFields();
  input.cargoPlaceRequirements = input.cargoPlaceRequirements ?? createNewPlaceRequirementsWithNullableFields();
  input.insurance = input.insurance ?? createNewInsurance();
  input.ownerInsuranceContract = input.ownerInsuranceContract ?? createNewOwnerInsuranceContract();
  input.vehicle = input.vehicle ?? createNewVehicle();
  input.lot = Boolean(input.lot) ? input.lot : input.allocationType === 'AUCTION_ALLOCATION' ? createNewLot() : input.lot;
  input.owner = input.owner ?? createNewCargoOwner();
  input.driver = !Boolean(input.owner) ? createNewDriver() : input.driver;
  input.price = {
    amount: null,
    currency: null,
  };
  input.comment = '';
  input.paymentPeriod = input.paymentPeriod ?? '';
  input.tenderNumber = input.tenderNumber ?? '';
  const result = transport.Order.fromObject(new transport.Order(input));
  result.paymentType = input.paymentType ?? null;
  return result as IOrder;
};

export const isOrderFreeAndPreviouslyAssigned = ({ status, isPreviouslyAssigned }: Partial<IOrder>) => {
  return Boolean(isPreviouslyAssigned) && status === ORDER_STATUS.FREE;
};

const getProcessDeadline = (order: IOrder) => {
  const { status, gracePeriod, viewProcessDeadline } = order;
  if (!Boolean(viewProcessDeadline)) {
    return null;
  }
  let processDeadline = new Date(order.viewProcessDeadline as string);
  if ((status === ORDER_STATUS.TRANSPORT_RESERVED || status === ORDER_STATUS.CONTRACT_ATTACHED) && Boolean(gracePeriod)) {
    processDeadline = new Date(processDeadline.getTime() + (gracePeriod as number) * MS_AMOUNT_OF_SECOND);
  }
  return processDeadline.toISOString();
};

export const getOrderDeadline = (order: IOrder): string => {
  const lifeTimeDeadline = order.viewEndDatetime;
  const processDeadline = getProcessDeadline(order);

  if (Boolean(lifeTimeDeadline) && Boolean(processDeadline)) {
    const minDate = moment.min(moment.utc(lifeTimeDeadline), moment.utc(processDeadline));
    return minDate.toString();
  } else if (Boolean(lifeTimeDeadline)) {
    return lifeTimeDeadline as string;
  } else if (Boolean(processDeadline)) {
    return processDeadline as string;
  }
  return '';
};
