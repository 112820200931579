import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import {
  FormControl,
  FormGroup,
  ValidationErrors, Validators
} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { TnMarketplaceAuthService } from '@marketplace/auth';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { TranslateService } from '@ngx-translate/core';
import {
  ISignUpEmployee,
  MAX_FIO_LENGTH,
  MAX_PHONE_NUMBER_LENGTH,
  TOAST_DURATION,
  trimmedStringValidator
} from '@transport/ui-interfaces';
import { CountryNames, TnSupportedCountry } from '@transport/ui-pipes';
import { TnPassportFacade, TnToasterFacade, TOAST_TYPE } from '@transport/ui-store';
import { LICENSE_AGREEMENT_URL, regExpConfig } from '@transport/ui-utils';
import { catchError, throwError } from 'rxjs';

@UntilDestroy()
@Component({
  selector: 'transport-passport-registration-employee',
  templateUrl: './passport-registration-employee.component.html',
  styleUrls: ['./passport-registration-employee.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class MpPassportRegistrationEmployeeComponent implements OnInit {
  public registrationForm = new FormGroup({
    fullName: new FormControl('', Validators.compose([
      Validators.required,
      trimmedStringValidator,
      Validators.maxLength(MAX_FIO_LENGTH),
    ])),
    phoneNumber: new FormControl('',
      Validators.compose([Validators.required])
      ),
    country: new FormControl(null, Validators.required),
  });

  public isLoading = false;

  public licenseAgreementURL = LICENSE_AGREEMENT_URL;

  countries = Object.keys(CountryNames).map(item => ({id: CountryNames[item], label: item}));


  constructor(
    public readonly translate: TranslateService,
    private readonly authHttpService: TnMarketplaceAuthService,
    private readonly toastFacade: TnToasterFacade,
    private readonly router: Router,
    public passportFacade: TnPassportFacade,
    private readonly route: ActivatedRoute,
  ) {}

  public ngOnInit(): void {
    this.passportFacade.setTitle('');
  }

  public get fullNameControl() {
    return this.registrationForm?.controls?.fullName;
  }

  public get phoneControl() {
    return this.registrationForm?.controls?.phoneNumber;
  }

  public get countrtControl() {
    return this.registrationForm?.controls?.country;
  }

  public submit(event?: Event) {
    event?.preventDefault();
    this.registrationForm?.markAllAsTouched();
    this.registrationForm?.updateValueAndValidity();
    if (Boolean(this.registrationForm?.valid)) {
      const data: ISignUpEmployee = {
        fullName: this.fullNameControl?.value.trim(),
        phoneNumber: this.phoneControl?.value,
        country: this.countrtControl?.value?.id ?? TnSupportedCountry.RU,
        userKey: this.route.snapshot.queryParamMap.get('token') ?? '',
      };

      this.authHttpService
        .signUpEmployee(data)
        .pipe(
          untilDestroyed(this),
          catchError(error => {
            this.toastFacade.showMessage(error, TOAST_TYPE.ERROR, TOAST_DURATION.SHORT);
            return throwError(() => error);
          }),
        )
        .subscribe((res) => {
          this.isLoading = false;
          this.authHttpService.authenticationData = {
            code: '',
            email: res.email,
          };
          this.authHttpService.sendCodeAfterOpenForm = true;
          this.router.navigate(['passport', 'submit-code']);
        });
    }
  }

  public fioValidationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.auth.registration.form.errors.noFio');
    if (e?.trimmedString) return this.translate.instant('shared.errors.trimmedString');
    if (e?.maxlength) return `${this.translate.instant('shared.auth.registration.form.errors.fioMaxlength')} ${this.fullNameControl?.errors?.maxlength?.requiredLength}`;

    return '';
  }

  public phoneValidationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.auth.registration.form.errors.noPhone');
    if (e?.pattern) return this.translate.instant('shared.auth.registration.form.errors.incorrectPhone');
    if (e?.invalidPhoneNumber) {
      if (e?.invalidPhoneNumber === TnSupportedCountry.BY) {
        return this.translate.instant('shared.errors.phoneBY');
      }
      return this.translate.instant('shared.errors.phone');
    }

    return '';
  }

  public countryValidationErrors(e: ValidationErrors | null) {
    if (e?.required) return this.translate.instant('shared.auth.registration.form.errors.noCountry');

    return '';
  }
}
