<div [ngClass]="{'text-sm p-2': isSmallView}">
  <div class="place-step-bet__row">
    <button class="place-step-bet__button"
            [ngClass]="{'!h-[30px] !w-[30px]': isSmallView}"
            [disabled]="currentBet <= minStepPrice"
            (click)="setIncrement($event, increment - 1)"
            [attr.data-test]="'place-step-bet-minus'">
            <mat-icon svgIcon="minus"></mat-icon>
    </button>
    <h2 class="place-step-bet__bet" [ngClass]="{'font-medium !text-sm': isSmallView}" [attr.data-test]="'place-step-bet-amount'">
      {{ currentBet | currencyMulti: currency: false }}
    </h2>
    <button class="place-step-bet__button"
            [ngClass]="{'!h-[30px] !w-[30px]': isSmallView}"
            [disabled]="maxBet !== startPrice ? ((currentBet + minStepPrice) >= maxBet) : ((currentBet + minStepPrice) > maxBet)"
            (click)="setIncrement($event, increment + 1)"
            [attr.data-test]="'place-step-bet-plus'">
            <mat-icon svgIcon="plus"></mat-icon>
    </button>
  </div>
  <div class="place-step-bet__row">
    <span class="place-step-bet__step-price" [attr.data-test]="'place-step-bet-price'">
      {{ 'shared.placeBet.auction.step' | translate }} {{ minStepPrice | currencyMulti: currency: false }}
    </span>
    &nbsp;
    <ng-container *ngIf="isVatIncluded; then vatSuffix else withoutVatSuffix"></ng-container>
  </div>
  <ng-container *ngFor="let creteria of additionalAuctionCriteria">
    <div *ngIf="creteria.type === AUCTION_CRITERION_CHOICES.FIRST_LOADING_DATE" class="place-step-bet__row">
      <common-select
        class="mb-2 w-[200px]"
        [disabled]="false"
        [size]="'sm'"
        [label]="'Дата подачи'"
        [allowClear]="false"
        dataTest="place-step-bet-additional-criteria-date"
        [dataSource]="dateOptions$ | async"
        [control]="dateAdditionalCriteriaControl">
      </common-select>
      <mat-icon class="mat-icon-sm text-gray-500 mt-3 ml-1"
                [matTooltip]="'Увеличение срока подачи на 1 день эквивалентно повышению ставки на ' + (firstDateCriteriaWeight | currencyMulti: currency: false) + ' и учитывается при выборе победителя'"
                matTooltipShowDelay="0"
                matTooltipHideDelay="0">
                info
      </mat-icon>
    </div>
    <div *ngIf="creteria.type === AUCTION_CRITERION_CHOICES.IS_VAT_INCLUDED" class="place-step-bet__row">
      <common-checkbox
        class="mb-2"
        [size]="'sm'"
        [label]="'НДС включен в цену'"
        dataTest="place-step-bet-additional-criteria-vat"
        [control]="vatAdditionalCriteriaControl">
      </common-checkbox>
    </div>
    <div class="place-step-bet__row" [attr.data-test]="'place-step-bet-equivalent'">
      Эквивалент ставки {{getOffer | currencyMulti: currency: false}}
    </div>
    <div class="place-step-bet__row text-sm text-gray-500" [attr.data-test]="'place-step-bet-current-equivalent'">
      Текущий эквивалент {{(lastBetEquivalent || startPrice) | currencyMulti: currency: false}}
    </div>
    <ng-container *ngIf="!isLastBetByMyOrganization">
      <mat-error *ngIf="!isValidMaxEquivalent" class="text-xs place-step-bet__row">
        Эквивалент ставки должен быть меньше {{(lastBetEquivalent || startPrice) | currencyMulti: currency}}
      </mat-error>
      <mat-error *ngIf="!isValidMinEquivalent" class="text-xs place-step-bet__row">
        Эквивалент ставки должен быть больше шага торгов
      </mat-error>
    </ng-container>
  </ng-container>
  <div *ngIf="tradingParticipantsCount" class="text-gray-500 w-full text-center mb-1">
    {{ 'shared.mp.cargo.container.tradingParticipantsCount' | translate:{ count: tradingParticipantsCount } }}
  </div>
  <div *ngIf="commentControl">
    <common-textarea
      [control]="$any(commentControl)!"
      [placeholder]="'shared.stepBetComment' | translate"
      [errorToMessage]="validationErrors.bind(this)"
      [dataTest]="'place-step-bet-comment'"
      [rows]="3"
      size="sm"
    >
    </common-textarea>
  </div>
  <button
    class="tw-button-solid-sm w-full"
    type="action-confirm"
    [disabled]="(currentBet <= minStepPrice) || disabled || isLastBetByMyOrganization || !commentControl.valid || !isValidAdditionalCriteria"
    (click)="onPlaceBet()"
    [attr.data-test]="'place-step-bet-send'"
    >{{'shared.placeBet.auction.label' | translate}}
  </button>
</div>

<ng-content></ng-content>

<ng-template #vatSuffix>
  <span class="place-step-bet__vat-suffix">
    {{ 'shared.withVat' | translate }}
  </span>
</ng-template>

<ng-template #withoutVatSuffix>
  <span class="place-step-bet__vat-suffix">
    {{ 'shared.withoutVat' | translate }}
  </span>
</ng-template>
