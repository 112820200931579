import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { ngfModule } from 'angular-file';
import { NgxMaskModule } from 'ngx-mask';

@NgModule({
  imports: [CommonModule, FormsModule, FlexLayoutModule, ReactiveFormsModule, RouterModule, NgxMaskModule.forChild(), ngfModule],
  exports: [CommonModule, FormsModule, FlexLayoutModule, ReactiveFormsModule, RouterModule, NgxMaskModule, ngfModule],
})
export class TnCoreModule {}
