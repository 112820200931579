<transport-button
    *ngIf="!inOrder else simpleBtn"
    [label]="'shared.inviteModal.invite' | translate "
    [iconPosition]="'left'"
    type="action"
    matIcon="link"
    size="sm"
    backColor="blue-500"
    (action)="onOpenModal()">
</transport-button>

<ng-template #simpleBtn>
  <div class="simple-btn" (click)="onOpenModal()">
    <div class="plus">+</div>
    <div class="text">{{'shared.inviteModal.inviteMore' | translate}}</div>
  </div>
</ng-template>

<ng-template #dialogRef>
  <div class="modal-wrap">
    <div class="modal-title-wrap">
      <div class="modal-title-wrap__title">
        {{ 'shared.inviteModal.title' | translate }}
        <mat-icon class="material-icons dialog-close" (click)="onClose()">clear</mat-icon>
      </div>
      <span class="hint">{{ 'shared.inviteModal.personalLinkHint' | translate }}</span>
      <mat-divider></mat-divider>
    </div>
      <div class="modal-body modal-body-wrap">
        <div class="modal-row">
          <mat-checkbox transportMatInputDataTest="mat-checkbox-5" [(ngModel)]="autoAccreditationSetting" (ngModelChange)="showInviteLinkWasChangedTooltip()">
            <span class="checkbox-label_wrapper">
              <span>{{ 'shared.inviteModal.accreditationCheckboxTitle' | translate }}</span>
              <span class="hint">{{ 'shared.inviteModal.accreditationCheckboxHint' | translate }}</span>
            </span>
          </mat-checkbox>
        </div>
        <div class="modal-row">
          <mat-form-field>
            <input disabled [(ngModel)]="inviteLink" matInput autocomplete="off" type="text" />
            <div matTooltip="{{ 'shared.inviteModal.linkChanged' | translate }} "
              matTooltipPosition="below"
              #inviteLinkTooltip="matTooltip"
            ></div>
          </mat-form-field>
          <ng-container *ngIf="!tooltipPrivate; else tooltip"></ng-container>
          <mat-icon class="icon" (click)="onCopyLink(inviteLink, 'tooltipPrivate', true)">library_books</mat-icon>
        </div>

        <mat-form-field appearance="fill" [formGroup]="emailsform">
          <mat-chip-list #chipList aria-label="Fruit selection">
            <mat-chip *ngFor="let email of emails" (removed)="removeEmail(email)" class="chip" [ngClass]="{'error-chip': !email.isCorrectEmail}">
              {{email.email}}
              <button matChipRemove class="remove" [ngClass]="{'error-chip': !email.isCorrectEmail}">
                <mat-icon>cancel</mat-icon>
              </button>
            </mat-chip>
            <input placeholder="E-mail"
                   formControlName="email"
                   autocomplete="off"
                   [matChipInputFor]="chipList"
                   [matChipInputSeparatorKeyCodes]="separatorKeysCodes"
                   [matChipInputAddOnBlur]="addOnBlur"
                   (matChipInputTokenEnd)="addEmail($event)">
          </mat-chip-list>
        </mat-form-field>
        <div *ngIf="showError" class="error">{{ showCurrentError() | translate }}</div>

        <button class="send-btn" mat-button mat-flat-button color="primary" (click)="onSendEmail()" [disabled]="!isCorrectEmails">
          {{ 'shared.inviteModal.send' | translate }}
        </button>
      </div>
    </div>
  <div class="modal-wrap">
    <div class="modal-title-wrap">
      <div class="modal-title-wrap__title">
        {{ 'shared.inviteModal.publicLinkTitle' | translate }}
        <mat-icon class="modal-title-wrap__info" matTooltip="{{ 'shared.inviteModal.publicLinkInfo' | translate }}" matTooltipHideDelay="0" matTooltipShowDelay="0">info</mat-icon>
      </div>
      <span class="hint">{{ 'shared.inviteModal.publicLinkTitleHint' | translate }}</span>
      <mat-divider></mat-divider>
    </div>
    <div class="modal-body">
      <div class="modal-row toggle-input">
        <mat-slide-toggle
          [(ngModel)]="publicLinkStatus"
          (toggleChange)="onChangePublicLinkStatus()">
          {{ 'shared.inviteModal.publicLinkToggle' | translate }}
        </mat-slide-toggle>
      </div>
      <div class="modal-row" *ngIf="publicLinkStatus">
        <mat-form-field>
          <mat-label>
            {{ 'shared.inviteModal.publicLinkLabel' | translate }}
          </mat-label>
          <input disabled [(ngModel)]="publicLink" matInput autocomplete="off" type="text" />
        </mat-form-field>
        <ng-container *ngIf="!tooltipPublic; else tooltip"></ng-container>
        <mat-icon class="icon" (click)="onCopyLink(publicLink, 'tooltipPublic', true)">library_books</mat-icon>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #tooltip>
  <div class="tooltip arrow-5-bottom">
    {{ 'shared.inviteModal.copy' | translate }}
  </div>
</ng-template>
