<div class="body">
  <form [formGroup]="vm.form" *ngIf="vm.form" fxLayoutGap="grid">
    <div class="block" fxLayout="column" fxLayout.xs="column">
      <div class="fields" fxLayout="column" fxLayoutGap="8px">
        <div class="flex gap-3 items-center flex-wrap">
          <common-select
            label="{{ 'shared.mp.vehicles.vehicle.form.country' | translate }}"
            class="w-full !gap-0"
            fxFlex="0 0 50%"
            fxFlex.xs="0 0 100%"
            size="sm"
            [control]="$any(vm.form.controls.country)"
            [required]="true"
            [dataSource]="$any(vm.baseVm.countries)"
            [allowClear]="false"
            [value]="vm.form.controls.country.value"
            [errorToMessage]="validationErrors.bind(this)"
            dataTest="form-vehicle-country">
          </common-select>
          <common-checkbox
            class="mt-5"
            [control]="$any(vm.form.controls.isThirdParty)"
            [label]="'shared.mp.vehicles.vehicle.form.isThirdParty' | translate"
            dataTest="form-vehicle-isThirdParty">
          </common-checkbox>
        </div>
        <common-select *ngIf="vm.form.controls.isThirdParty.value && isAgentOrExpeditor"
          [control]="$any(vm.form.controls.agentCarrierId)"
          [dataSource]="organizations"
          [label]="'shared.mp.vehicles.vehicle.form.organization.label' | translate"
          [allowClear]="true"
          [enableSearch]="true"
          [asyncSearch]="true"
          [placeholder]="'shared.mp.vehicles.vehicle.form.organization.placeholder' | translate"
          [errorToMessage]="validationErrors.bind(this)"
          dataTest="form-vehicle-organization-choose"
          (search)="searchAgentCarriers($event)"
        >
        </common-select>
        <div class="flex justify-between gap-3 flex-wrap">
          <common-select
            label="{{ 'shared.mp.vehicles.vehicle.form.ownershipType' | translate }}"
            class="flex-grow basis-[50%]"
            size="sm"
            [control]="$any(vm.form.controls.ownershipType)"
            [required]="true"
            [dataSource]="$any(ownershipTypes)"
            [allowClear]="false"
            [errorToMessage]="validationErrors.bind(this)"
            dataTest="form-vehicle-ownershipType">
          </common-select>
          <common-select
            label="{{ 'shared.mp.vehicles.vehicle.form.type' | translate }}"
            class="flex-grow basis-[47%]"
            size="sm"
            [control]="$any(vm.form.controls.type)"
            [required]="true"
            [dataSource]="$any(vehicleTypes)"
            [allowClear]="false"
            [value]="vm.form.controls.type.value"
            [errorToMessage]="validationErrors.bind(this)"
            dataTest="form-vehicle-type">
          </common-select>
        </div>
        <div class="flex justify-between gap-3 flex-wrap">
          <common-select
            class="flex-grow basis-[50%]"
            size="sm"
            [control]="$any(vm.form.controls.make)"
            [dataSource]="vehicleMake$ | async"
            [enableSearch]="true"
            [required]="true"
            [label]="'shared.mp.vehicles.vehicle.form.make' | translate"
            [errorToMessage]="validationErrors.bind(this)"
            dataTest="form-vehicle-make-choose">
          </common-select>
          <common-input
            [errorToMessage]="validationErrors.bind(this)"
            label="{{ 'shared.mp.vehicles.vehicle.form.regNumber' | translate }}"
            [placeholder]="registrationNumberPlaceholder"
            size="sm"
            class="flex-grow basis-[47%]"
            inputClasses="uppercase"
            [required]="true"
            [control]="$any(vm.form.controls.regNumber)"
            [mask]="vehiclePassport?.registrationNumber?.mask"
            [patterns]="$any(vehiclePassport?.registrationNumber?.patterns)"
            dataTest="form-vehicle-regNumber"
          ></common-input>

        </div>

        <ng-container *ngIf="vehicleType === 'TRAILER' || vehicleType === 'COUPLER'">
          <div class="title font-medium text-base text-gray-700">
            {{ getTrailerTitle(vehicleType) | translate }}
          </div>
          <div class="flex justify-between gap-3 flex-wrap">
            <common-select
              class="flex-grow basis-[50%]"
              size="sm"
              [control]="$any(vm.form.controls.trailerMake)"
              [dataSource]="vehicleMake$ | async"
              [enableSearch]="true"
              [required]="true"
              [label]="'shared.mp.vehicles.vehicle.form.make' | translate"
              [labelClasses]="['text-sm']"
              [errorToMessage]="validationErrors.bind(this)"
              dataTest="form-vehicle-trailerMake-choose">
            </common-select>
            <common-input
              [errorToMessage]="validationErrors.bind(this)"
              label="{{ 'shared.mp.vehicles.vehicle.form.regNumber' | translate }}"
              [placeholder]="trailerNumberPlaceholder"
              size="sm"
              class="flex-grow basis-[47%]"
              inputClasses="uppercase"
              [required]="true"
              [control]="$any(vm.form.controls.trailerRegNumber)"
              [mask]="vehiclePassport?.trailerNumber?.mask"
              [patterns]="$any(vehiclePassport?.trailerNumber?.patterns)"
              dataTest="'form-vehicle-trailerRegNumber"
            ></common-input>
          </div>
        </ng-container>

        <ng-container *ngIf="vehicleType">
          <div class="row" fxLayout="row" fxLayout.xs="column" fxLayoutGap="12px" [ngClass]="{ 'mt-6' : vehicleType === 'TRUCK' }">

            <common-select
              class="w-full"
              size="sm"
              [control]="$any(vm.form.controls.trailerBodyType)"
              [dataSource]="bodySubtypes$ | async"
              [enableSearch]="true"
              [required]="true"
              [label]="'shared.mp.vehicles.vehicle.form.trailerBodyType' | translate"
              [errorToMessage]="validationErrors.bind(this)"
              dataTest="form-vehicle-trailerBodyType">
            </common-select>
            <transport-tw-input-field
              [errorToMessage]="validationErrors.bind(this)"
              label="{{ 'shared.mp.vehicles.vehicle.form.loadingTypes' | translate }}"
              size="sm"
              errorsSize="xs"
              class="w-full !gap-0 flex flex-col !h-16"
              fxFlex="0 0 48%"
              fxFlex.xs="0 0 100%"
              [required]="true"
            >
              <section class="loading-types flex text-xs font-medium text-gray-700" [formGroup]="loadingTypesForm">
                <div class="mr-3">
                  <mat-checkbox formControlName="back" [attr.data-test]="'form-vehicle-loadingType-back'">{{ 'shared.mp.vehicles.vehicle.form.back' | translate }}</mat-checkbox>
                </div>
                <div class="mr-3">
                  <mat-checkbox formControlName="up" [attr.data-test]="'form-vehicle-loadingType-up'">{{ 'shared.mp.vehicles.vehicle.form.up' | translate }}</mat-checkbox>
                </div>
                <div class="mr-3">
                  <mat-checkbox formControlName="side" [attr.data-test]="'form-vehicle-loadingType-side'">{{ 'shared.mp.vehicles.vehicle.form.side' | translate }}</mat-checkbox>
                </div>
              </section>
              <div
                class="font-normal text-xs text-red-500 mt-1"
                *ngIf="loadingTypesForm.errors?.requireCheckboxesToBeChecked && loadingTypesForm.touched"
              >
                {{ 'shared.mp.vehicles.vehicle.form.errors.loadingTypes' | translate }}
              </div>
            </transport-tw-input-field>
          </div>

          <div class="row !mb-0" fxLayout="row" fxLayout.xs="column" fxLayoutGap="12px">
            <common-number-input
              size="sm"
              class="w-[150px]"
              label="{{ 'shared.mp.vehicles.vehicle.form.capacity.title' | translate }}"
              [errorToMessage]="validationErrors.bind(this)"
              [control]="$any(vm.form.controls.capacity)"
              [lengthAfterSeparator]="'3'"
              [required]="true"
              rightAddon="{{ 'shared.mp.vehicles.vehicle.form.capacity.addon' | translate }}"
              dataTest="form-vehicle-capacity">
            </common-number-input>
            <common-number-input
              size="sm"
              class="w-[120px]"
              label="{{ 'shared.mp.vehicles.vehicle.form.volume.title' | translate }}"
              [errorToMessage]="validationErrors.bind(this)"
              [control]="$any(vm.form.controls.volume)"
              [lengthAfterSeparator]="'3'"
              [required]="true"
              rightAddon="{{ 'shared.mp.vehicles.vehicle.form.volume.addon' | translate }}"
              dataTest="form-vehicle-volume">
            </common-number-input>

            <transport-tw-autocomplete
              class="box-border mr-2 basis-[47%] min-w-[100px] mb-2 sm:mb-0"
              label="{{'shared.mp.vehicles.vehicle.form.baseLocation.label' | translate}}"
              placeholder="{{'shared.mp.vehicles.vehicle.form.baseLocation.placeholder' | translate}}"
              size="sm"
              [showErrors]="true"
              [fetchFn]="getDaDataPlaceAddress.bind(this)"
              [inputValue]="$any(vm.form?.controls?.baseLocation)?.value?.settlement"
              [control]="$any(vm.form?.controls?.baseLocation)"
              [attr.data-test]="'form-vehicle-baseLocation'"
            >
            </transport-tw-autocomplete>
          </div>
        </ng-container>
        <div class="row my-3 items-end" fxLayout="row" fxLayout.xs="column" fxLayoutGap="12px">
          <div class="block" fxLayout="column" fxLayout.xs="column" fxLayoutGap="8px" fxFlex="0 0 50%">
            <div class="title font-medium text-base text-gray-700">
              {{ 'shared.mp.vehicles.vehicle.title.driver' | translate }}
            </div>
            <ng-container *ngIf="isSubDomain || vm.form.controls.agentCarrierId.value; else driverAccredit">
              <transport-add-select
                style="margin-top: 10px;"
                bindValue="id"
                dataTest="form-vehicle-driver-choose"
                [control]="$any(vm.form.controls.driver)"
                [placeholder]="'shared.mp.vehicles.vehicle.form.driver' | translate"
                [data]="drivers"
                [allowClear]="$any(vm.form.controls.driver)?.value"
                [bindLabel]="'fullName'"
                (addClicked)="selectDriver()"
              >
              </transport-add-select>
            </ng-container>
            <ng-template #driverAccredit>
              <transport-accreditation-autocomplete
                style="margin-top: 10px;"
                bindValue="id"
                dataTest="form-vehicle-driver-choose-accr"
                [bindLabel]="'fullName'"
                [allowClear]="$any(vm.form.controls.driver)?.value"
                [control]="$any(vm.form.controls.driver)"
                [placeholder]="'shared.mp.vehicles.vehicle.form.driver' | translate"
                [data]="drivers"
                (addClicked)="selectDriver()"
                (sendOnCheck)="sendDriverOnCheck($event)"
              >
              </transport-accreditation-autocomplete>
            </ng-template>
          </div>
          <div fxFlex="0 0 48%" fxFlex.xs="0 0 100%" class="font-normal text-xs text-gray-600">
            {{ 'shared.mp.vehicles.vehicle.form.driverInfo' | translate }}
          </div>
        </div>
      </div>

      <div class="accreditation flex flex-col text-gray-700 bg-gray-50 border border-gray-100 rounded-md p-4 mb-6" *ngIf="!isSubDomain">
        <div class="flex justify-between">
          <div class="flex flex-col">
            <span class="font-medium text-lg">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.title' | translate }}
            </span>
            <span class="text-gray-500 font-normal text-xs flex items-start">
              <mat-icon *ngIf="isThirdPartyType" class="text-orange-500 !h-[14px] !w-[14px] !text-[14px] mr-[3px] mt-[2px]">info</mat-icon>
              {{ (isThirdPartyType ? 'carrier.directory.marketplaceVehicle.form.accreditation.thirdPartyDescription' : 'carrier.directory.marketplaceVehicle.form.accreditation.description') | translate }}
            </span>
          </div>
          <div
            *ngIf="(vm.vehicle$ | async)?.accrStatus === 'new' && !isThirdPartyType"
            class="flex h-[18px] bg-gray-500 rounded-sm text-white font-bold text-xs leading-4 px-1"
          >
            <span class="m-auto whitespace-nowrap">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.statuses.new' | translate }}
            </span>
          </div>
          <div
            *ngIf="(vm.vehicle$ | async)?.accrStatus === 'passed' && !isThirdPartyType"
            class="flex h-[18px] bg-green-500 rounded-sm text-white font-bold text-xs leading-4 px-1"
          >
            <span class="m-auto whitespace-nowrap">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.statuses.passed' | translate }}
            </span>
          </div>
          <div
            *ngIf="(vm.vehicle$ | async)?.accrStatus === 'pending' && !isThirdPartyType"
            class="flex h-[18px] bg-orange-500 rounded-sm text-white font-bold text-xs leading-4 px-1"
          >
            <span class="m-auto whitespace-nowrap">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.statuses.pending' | translate }}
            </span>
          </div>
          <div
            *ngIf="(vm.vehicle$ | async)?.accrStatus === 'not_passed'"
            class="flex h-[18px] bg-red-500 rounded-sm text-white font-bold text-xs leading-4 px-1"
            transportTooltip
            [context]="{ message: (vm.vehicle$ | async)?.accrComment }"
          >
            <span class="m-auto whitespace-nowrap">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.statuses.notPassed' | translate }}
            </span>
          </div>
        </div>
        <div *ngIf="!isThirdPartyType" class="flex flex-col gap-[10px]">
          <div class="flex flex-col w-full">
            <span class="text-md font-medium" *ngIf="vehicleType === 'TRUCK'">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.vehicleTypes.truck' | translate }}
            </span>
            <span class="text-md font-medium" *ngIf="vehicleType === 'TRAILER'">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.vehicleTypes.trailer' | translate }}
            </span>
            <span class="text-md font-medium" *ngIf="vehicleType === 'COUPLER'">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.vehicleTypes.coupler' | translate }}
            </span>

            <common-select
              class="w-full"
              [control]="$any(vm.form.controls.ownershipTractorType)"
              [dataSource]="ownershipTypes"
              [label]="'shared.mp.vehicles.vehicle.form.ownershipType' | translate"
              [allowClear]="true"
              [errorToMessage]="validationErrors.bind(this)"
              dataTest="form-vehicle-ownershipTractorType"
            >
            </common-select>
          </div>
          <div class="flex adaptive flex-col gap-3 flex-wrap" *ngIf="ownershipTractorType">
            <div class="flex flex-col gap-1 w-full" *ngIf="ownershipTractorType === 'LEASING'">
              <label for="tracktorLeasingAgreement" class="text-gray-700 font-normal text-sm">
                {{ 'carrier.directory.marketplaceVehicle.form.accreditation.ownershipTypes.leasing' | translate }}
                <span class="text-red-500 ml-0.5">*</span>
              </label>
              <transport-file-selector
                #tracktorLeasingAgreement
                [errorToMessage]="validationErrors.bind(this)"
                class="max-w-[258px]"
                [control]="$any(vm.form.controls.tracktorLeasingAgreement)"
                [attr.data-test]="'form-vehicle-file-tracktorLeasingAgreement'"
              ></transport-file-selector>
            </div>
            <div class="flex flex-col gap-1 w-full" *ngIf="ownershipTractorType === 'RENT'">
              <label for="tracktorRentContract" class="text-gray-700 font-normal text-sm">
                {{ 'carrier.directory.marketplaceVehicle.form.accreditation.ownershipTypes.rent' | translate }}
                <span class="text-red-500 ml-0.5">*</span>
              </label>
              <transport-file-selector
                #tracktorRentContract
                [errorToMessage]="validationErrors.bind(this)"
                class="max-w-[258px]"
                [control]="$any(vm.form.controls.tracktorRentContract)"
                [attr.data-test]="'form-vehicle-file-tracktorRentContract'"
              ></transport-file-selector>
            </div>
            <div class="flex flex-col gap-1 w-[258px]">
              <label for="tractorStsFirstSide" class="text-gray-700 font-normal text-sm">
                {{ 'carrier.directory.marketplaceVehicle.form.accreditation.stsFirstSide' | translate }}
                <span class="text-red-500 ml-0.5">*</span>
              </label>
              <transport-file-selector
                #tractorStsFirstSide
                [errorToMessage]="validationErrors.bind(this)"
                class="!w-auto"
                [control]="$any(tractorStsForm.controls.firstSide)"
                [attr.data-test]="'form-vehicle-file-tractorStsForm-firstSide'"
              ></transport-file-selector>
            </div>
            <div class="flex flex-col gap-1 w-[258px]">
              <label for="tractorStsSecondSide" class="text-gray-700 font-normal text-sm">
                {{ 'carrier.directory.marketplaceVehicle.form.accreditation.stsSecondSide' | translate }}
                <span class="text-red-500 ml-0.5">*</span>
              </label>
              <transport-file-selector
                #tractorStsSecondSide
                [errorToMessage]="validationErrors.bind(this)"
                class="!w-auto"
                [control]="$any(tractorStsForm.controls.secondSide)"
                [attr.data-test]="'form-vehicle-file-tractorStsForm-secondSide'"
              ></transport-file-selector>
            </div>
          </div>
          <div class="flex flex-col gap-[10px]" *ngIf="vehicleType === 'TRAILER' || vehicleType === 'COUPLER'">
            <span class="text-md font-medium" *ngIf="vehicleType === 'TRAILER'">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.trailerTypes.semitrailer' | translate }}
            </span>
            <span class="text-md font-medium" *ngIf="vehicleType === 'COUPLER'">
              {{ 'carrier.directory.marketplaceVehicle.form.accreditation.trailerTypes.trailer' | translate }}
            </span>

            <common-select
              class="!gap-0 w-[258px]"
              [control]="$any(vm.form.controls.ownershipTrailerType)"
              [dataSource]="ownershipTypes"
              [label]="'shared.mp.vehicles.vehicle.form.ownershipType' | translate"
              [allowClear]="true"
              [placeholder]="'shared.mp.vehicles.vehicle.form.ownershipType' | translate"
              [errorToMessage]="validationErrors.bind(this)"
              dataTest="form-vehicle-ownershipTractorType"
            >
            </common-select>
            <div class="flex adaptive gap-3 flex-wrap" *ngIf="ownershipTrailerType">
              <div class="flex flex-col gap-1 w-full" *ngIf="ownershipTrailerType === 'LEASING'">
                <label for="trailerLeasingAgreement" class="text-gray-700 font-normal text-sm">
                  {{ 'carrier.directory.marketplaceVehicle.form.accreditation.ownershipTypes.leasing' | translate }}
                  <span class="text-red-500 ml-0.5">*</span>
                </label>
                <transport-file-selector
                  #trailerLeasingAgreement
                  [errorToMessage]="validationErrors.bind(this)"
                  class="max-w-[258px]"
                  [control]="$any(vm.form.controls.trailerLeasingAgreement)"
                  [attr.data-test]="'form-vehicle-file-trailerLeasingAgreement'"
                ></transport-file-selector>
              </div>
              <div class="flex flex-col gap-1 w-full" *ngIf="ownershipTrailerType === 'RENT'">
                <label for="trailerRentContract" class="text-gray-700 font-normal text-sm">
                  {{ 'carrier.directory.marketplaceVehicle.form.accreditation.ownershipTypes.rent' | translate }}
                  <span class="text-red-500 ml-0.5">*</span>
                </label>
                <transport-file-selector
                  #trailerRentContract
                  [errorToMessage]="validationErrors.bind(this)"
                  class="max-w-[258px]"
                  [control]="$any(vm.form.controls.trailerRentContract)"
                  [attr.data-test]="'form-vehicle-file-trailerRentContract'"
                ></transport-file-selector>
              </div>
              <div class="flex flex-col gap-1 w-[258px]">
                <label for="trailerStsFirstSide" class="text-gray-700 font-normal text-sm">
                  {{ 'carrier.directory.marketplaceVehicle.form.accreditation.stsFirstSide' | translate }}
                  <span class="text-red-500 ml-0.5">*</span>
                </label>
                <transport-file-selector
                  #trailerStsFirstSide
                  [errorToMessage]="validationErrors.bind(this)"
                  class="!w-auto"
                  [control]="$any(trailerStsForm.controls.firstSide)"
                  [attr.data-test]="'form-vehicle-file-trailerStsForm-firstSide'"
                ></transport-file-selector>
              </div>
              <div class="flex flex-col gap-1 w-[258px]">
                <label for="trailerStsSecondSide" class="text-gray-700 font-normal text-sm">
                  {{ 'carrier.directory.marketplaceVehicle.form.accreditation.stsSecondSide' | translate }}
                  <span class="text-red-500 ml-0.5">*</span>
                </label>
                <transport-file-selector
                  #trailerStsSecondSide
                  [errorToMessage]="validationErrors.bind(this)"
                  class="!w-auto"
                  [control]="$any(trailerStsForm.controls.secondSide)"
                  [attr.data-test]="'form-vehicle-file-trailerStsForm-secondSide'"
                ></transport-file-selector>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </form>
</div>
