<table
  mat-table
  matSort
  [dataSource]="dataSource"
  transportTableColumnReorder
  (dragColumnStart)="onDragColumnStart()"
  (dragColumnFinish)="onDragColumnFinish()"
  (reorder)="reorderColumns($event)"
  [ngClass]="tableClass"
>
  <colgroup transportTableColumnResizer [deltas]="deltas" [localStorageKey]="localStorageKey">
    <col *ngFor="let column of columnModels, let i=index" [style.width.px]="column.width + deltas[i].dx" />
  </colgroup>
  <ng-container *ngFor="let column of columnModels; let i=index" [matColumnDef]="column.field">
    <ng-container *ngIf="column.hasSorting">
      <th
        mat-header-cell
        *matHeaderCellDef
        [class.header-number-value]="column.type ==='number'"
        [attr.data-test]="(tableName ? tableName + '_' : '') + 'transport-table-header-cell_' + i"
      >
        <div mat-sort-header arrowPosition="before">
          <ng-template [ngTemplateOutletContext]="{column: column}" [ngTemplateOutlet]="headerTemplates[column.field]"></ng-template>
        </div>
        <div class="table-column-resizer" *ngIf="$any(column.field | cell:{type: tableCellPipeType.HAS_RESIZER})"></div>
      </th>
    </ng-container>
    <ng-container *ngIf="!column?.hasSorting">
      <th
        mat-header-cell
        *matHeaderCellDef
        [ngClass]="$any(column.field | cell:{type: tableCellPipeType.HEADER_CELL_CLASS})"
        [attr.data-test]="(tableName ? tableName + '_' : '') + 'transport-table-header-cell_' + i"
      >
        <ng-template
          [ngTemplateOutletContext]="{column: column, hasSettings: hasSettings}"
          [ngTemplateOutlet]="headerTemplates[column.field]"
        >
        </ng-template>
        <div class="table-column-resizer" *ngIf="$any(column.field | cell:{type: tableCellPipeType.HAS_RESIZER})"></div>
      </th>
    </ng-container>
    <td
      mat-cell
      *matCellDef="let rowData"
      (mousedown)="onCellClick($event, column.field, rowData)"
      [attr.data-test]="(tableName ? tableName + '_' : '') + 'transport-table-cell_' + i"
    >
      <div
        class="text-wrapper"
        [class.number-value]="column.type ==='number'"
        [class.icon-wrapper]="column.type === 'icon'"
        [attr.tn-tip]="column.hasTooltip"
      >
        <ng-template [ngTemplateOutletContext]="{rowData: rowData, column: column}" [ngTemplateOutlet]="cellTemplates[column.field]">
        </ng-template>
      </div>
    </td>
    <td mat-footer-cell *matFooterCellDef>
      <ng-template
        [ngTemplateOutletContext]="{value: footerValues[column.field]}"
        [ngTemplateOutlet]="footerCellTemplates[column.field]"
      ></ng-template>
    </td>
  </ng-container>
  <tr mat-header-row *matHeaderRowDef="displayedColumns; sticky: isSticky" [ngClass]="{ 'has-search': hasSearch }"></tr>
  <ng-container *ngIf="gotoDetailsLinkFn !== null">
    <ng-container *matRowDef="let rowData; columns: displayedColumns;">
      <a [href]="gotoDetailsLinkFn(rowData)">
        <tr
          mat-row
          [attr.data-test]="(tableName ? tableName + '_' : '') + 'transport-table-row_' + rowData['id']"
          (dblclick)="rowDblClick.emit(rowData)"
          [ngClass]="rowClasses(rowData)"
        ></tr>
      </a>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="gotoDetailsLinkFn === null">
    <ng-container *matRowDef="let rowData; columns: displayedColumns;">
      <tr
        mat-row
        [attr.data-test]="(tableName ? tableName + '_' : '') + 'transport-table-row_' + rowData['id']"
        (dblclick)="rowDblClick.emit(rowData)"
        [ngClass]="rowClasses(rowData)"
      ></tr>
    </ng-container>
  </ng-container>
  <ng-container *ngIf="hasShowFooter">
    <tr mat-footer-row *matFooterRowDef="displayedColumns; sticky: isSticky"></tr>
  </ng-container>
</table>

<ng-template #defaultCellTemplate let-rowData="rowData" let-column="column">
  {{ rowData[column?.field] }}
</ng-template>

<ng-template #defaultFooterCellTemplate let-value="value">
  {{ value }}
</ng-template>

<ng-template #dateCellTemplate let-rowData="rowData" let-column="column">
  {{ rowData[column?.field] | date: 'dd.MM.yyyy' }}
</ng-template>

<ng-template #defaultHeaderTemplate let-column="column">
  <div class="header-cell-container" [ngClass]="hasSearch ? 'header-cell-container_column' : 'header-cell-container_row'">
    <div class="text-wrapper" [attr.tn-tip]="!isDragStart" [class.number-value]="column.type ==='number'">{{column.label}}</div>
    <ng-template
      [ngTemplateOutletContext]="{ searchForm: searchForm, controlName: column.field, selectorOptions: column.selectorOptions }"
      [ngTemplateOutlet]="searchTemplates[column.field]"
    ></ng-template>
  </div>
  <transport-drag-icon *ngIf="columnsDraggable"></transport-drag-icon>
</ng-template>

<ng-template #textSearchTemplate let-form="searchForm" let-controlName="controlName">
  <form [formGroup]="form">
    <mat-form-field floatLabel="never" class="text-contains">
      <mat-icon matPrefix svgIcon="search"></mat-icon>
      <mat-label>{{ 'shared.search.title' | translate }}</mat-label>
      <input matInput [formControlName]="controlName" autocomplete="off" />
    </mat-form-field>
  </form>
</ng-template>

<ng-template #selectorSearchTemplate let-form="searchForm" let-controlName="controlName" let-options="selectorOptions">
  <form [formGroup]="form">
    <mat-form-field class="select-contains" floatLabel="never">
      <mat-label>{{ 'shared.search.title' | translate }}</mat-label>
      <mat-select [formControlName]="controlName">
        <mat-option *ngFor="let option of options" [value]="option.id">
          {{ option.name }}
        </mat-option>
        <mat-option [value]="''">
          {{ 'shared.search.all' | translate }}
        </mat-option>
      </mat-select>
    </mat-form-field>
  </form>
</ng-template>

<ng-template #settingsHeaderTemplate let-hasSettings="hasSettings">
  <transport-table-settings *ngIf="hasSettings" [columnsDefs]="settings" (changeSettings)="onColumnVisibilityChange($event)">
  </transport-table-settings>
</ng-template>

<ng-template #selectorsHeaderTemplate>
  <mat-checkbox transportMatInputDataTest="mat-checkbox-27" [checked]="isAllSelected" [indeterminate]="isSomeSelect" (change)="changeAllSelection($event.checked)"></mat-checkbox>
</ng-template>

<ng-template #selectorsCellTemplate let-rowData="rowData">
  <mat-checkbox transportMatInputDataTest="mat-checkbox-28" [checked]="isRowChecked(rowData)" (change)="changeRowSelection($event.checked, rowData)"></mat-checkbox>
</ng-template>
