import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnInit,
  Optional,
  Self,
  SimpleChange,
  SimpleChanges,
} from '@angular/core';
import { NgControl, ValidationErrors, Validators } from '@angular/forms';
import { FormBuilder, FormControl } from '@ngneat/reactive-forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { transport } from '@transport/proto';
import { TnCustomNgNeatReactiveFormControlDirective } from '@transport/ui-directives';
import { ITruckForm, TRUCK_IDENTIFICATOR_TYPE, TVehicle, VEHICLE_FORM_CONSTANTS } from '@transport/ui-interfaces';
import { createTruckFormGroup, mapTruckFormInputValueToInner } from '@transport/ui-store';
import { getTranslateParams, regExpConfig } from '@transport/ui-utils';
import { MaskApplierService } from 'ngx-mask';
import { of } from 'rxjs';

import { LAST_SYMBOL_VEHICLE_ID, MAX_VEHICLE_ID, TRUCK_DUPLICATE_FIELDS } from './constants';
import { TnSupportedCountry } from '@transport/ui-pipes';

@UntilDestroy()
@Component({
  selector: 'transport-truck-form',
  templateUrl: './truck-form.component.html',
  styleUrls: ['./truck-form.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnTruckFormComponent extends TnCustomNgNeatReactiveFormControlDirective<ITruckForm> implements OnInit, OnChanges {
  @Input() public isDisabled = false;

  @Input() public isUseMatStep = false;

  @Input() public mode = 'create';

  @Input() public vehicleTypes: TVehicle[] = [];

  @Input() public vehicleMakes: transport.Vehicle.IMaker[] = [];

  @Input() public countries: { code: string; name: string }[] = [];

  @Input() public isRequiredVin = false;

  @Input() public isRuVehicleCountry = false;

  @Input() public set duplicateError(errorFields: string[] | null) {
    this.setErrorsDuplicate(errorFields);
  }

  public regExpConf = regExpConfig;

  public limitations = getTranslateParams<typeof VEHICLE_FORM_CONSTANTS>(VEHICLE_FORM_CONSTANTS);

  public truckIdentificatorType = TRUCK_IDENTIFICATOR_TYPE;

  public get isVehicleCountryRussia() {
    return this.form.controls.vehicleCountry.value === TnSupportedCountry.RU;
  }

  public get showTruckHelpIcon() {
    return this.mode !== 'view' && this.isVehicleCountryRussia;
  }

  constructor(
    protected readonly fb: FormBuilder,
    @Optional() @Self() public controlDir: NgControl,
    private readonly changeDetector: ChangeDetectorRef,
    private readonly maskService: MaskApplierService,
  ) {
    super(fb);
    if (Boolean(controlDir)) {
      controlDir.valueAccessor = this;
    }
  }

  public ngOnInit() {
    if (this.isRequiredVin) {
      this.form.controls.vehicleVin.setValidators(Validators.required);
    }

    this.controlDir.control?.setValidators([this.validate.bind(this)]);
    this.controlDir.control?.updateValueAndValidity();
    this.changeDetector.detectChanges();

    this.form.disabledWhile(of(this.isDisabled));

    void this.form.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      this.onChange(value);
    });


    if (Boolean(this.controlDir.control)) {
      void (this.controlDir.control as FormControl).touch$.pipe(untilDestroyed(this)).subscribe(touch => {
        if (Boolean(touch)) {
          this.form.markAllAsTouched();
          this.changeDetector.detectChanges();
        }
      });
    }

    this.handleCountryChange();

    if (this.mode === 'copy') {
      this.clearVehicleIdentificators();
    }
  }

  onChangeVehicleId(event) {
    const value = event.target.value;
    if ((Boolean(value)) && value.length === MAX_VEHICLE_ID && value[LAST_SYMBOL_VEHICLE_ID] !== '0' && !Boolean(Number(value[LAST_SYMBOL_VEHICLE_ID]))) {
      this.form.controls.vehicleId.setValue(value.slice(0, -1));
    }
  }

  public ngOnChanges(changes: SimpleChanges) {
    if (Boolean(changes.countries)) {
      this.setRuCountry(changes.countries);
    }
  }

  public clearVehicleIdentificators() {
    this.form.patchValue(
      {
        vehicleId: null,
        vehicleCertificate: null,
        vehicleVin: null,
      },

      { emitEvent: false },
    );
  }

  public validate(): ValidationErrors | null {
    return this.form.invalid ? { truckInfo: true } : null;
  }

  public writeValue(data: ITruckForm): void {
    if (Boolean(data)) {
      this.form.patchValue(mapTruckFormInputValueToInner<ITruckForm>(data), {
        emitEvent: false,
      });
    }
  }

  public handleCountryChange() {
    void this.form.controls.vehicleCountry.valueChanges.pipe(untilDestroyed(this)).subscribe(value => {
      if (value === TnSupportedCountry.RU) {
        const pSts = Boolean(this.form.controls.vehicleCertificate.value)
          ? this.maskService.applyMaskWithPattern(this.form.controls.vehicleCertificate.value as string, [
              regExpConfig.ngxMask.vehiclePassport.ru.registrationCertificate.mask,
              regExpConfig.ngxMask.vehiclePassport.ru.registrationCertificate.patterns ?? {},
            ])
          : null;

        const pGosNum = Boolean(this.form.controls.vehicleId.value)
          ? this.maskService.applyMaskWithPattern(this.form.controls.vehicleId.value as string, [
              regExpConfig.ngxMask.vehiclePassport.ru.registrationNumber.mask,
              regExpConfig.ngxMask.vehiclePassport.ru.registrationNumber.patterns ?? {},
            ])
          : null;

        this.form.patchValue({
          vehicleCertificate: pSts,
          vehicleId: pGosNum,
        });
      }
    });
  }

  protected createFormGroup() {
    return createTruckFormGroup();
  }

  private setRuCountry(countries: SimpleChange) {
    const currentCountries = countries.currentValue as { code: string; name: string }[];
    if (currentCountries.length) {
      if (this.isRuVehicleCountry) {
        const ruCountry = currentCountries.find(country => country.code === TnSupportedCountry.RU);
        this.form.patchValue({
          vehicleCountry: ruCountry?.code,
        });
        this.form.controls.vehicleCountry.disable();
      }
    }
  }

  private setErrorsDuplicate(errorFields: string[] | null) {
    const { vehicleCertificate, vehicleVin, vehicleId } = this.form.controls;
    if (Boolean(errorFields)) {
      (errorFields as string[]).forEach(field => {
        switch (field) {
          case TRUCK_DUPLICATE_FIELDS.STS:
            vehicleCertificate.setErrors({ duplicate: true });
            vehicleCertificate.markAsTouched();
            break;
          case TRUCK_DUPLICATE_FIELDS.NUMBER:
            vehicleId.setErrors({ duplicate: true });
            vehicleId.markAsTouched();
            break;
          case TRUCK_DUPLICATE_FIELDS.VIN:
            vehicleVin.setErrors({ duplicate: true });
            vehicleVin.markAsTouched();
            break;
        }
      });
    }
  }
}
