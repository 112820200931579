import { AbstractControl, FormBuilder, FormGroup, ValidatorFn, Validators } from '@angular/forms';
import { transport } from '@transport/proto';

import { timesRangeValidator, timeToMoreThanTimeFromValidator, trimmedStringValidator } from '../form-validators/forms';
import { IPlace, TPlace } from '../place';
import { TnSupportedCountry } from '@transport/ui-pipes';
import { QC_GEO } from 'libs/transport-ui-interfaces/src/lib/map-point';

export interface ILoadingPlaceForm {
  id?: string;
  coordinateAccuracy: QC_GEO;
  isArchived?: boolean;
  address: string;
  companyName: string;
  pointName?: string;
  settlement: string;
  workingHoursFrom?: string | null;
  workingHoursTo?: string | null;
  extPointId?: string;
  type?: string | null;
  actions?: TPlace[];
  loadingTypes: string[];
  fiasCode: string;
  longitude: string;
  latitude: string;
  contacts: transport.IContact[];
  timezoneId: transport.ITimezone;
  drivingDirectionsImg?: string | null;
  country?: string | null;
  interestedPersons?: [] | null;
}

export const MAX_POINT_NAME_LENGTH = 200;

export const MAX_ORGANIZATION_LENGTH = 150;

export const MAX_EXTPOINTID_LENGTH = 36;
/**
 * AddLoadingPlace form data.
 */
export class TnAddLoadingPlaceFormData {
  public isArchived = false;

  public pointName = '';

  public companyName = '';

  public settlement = '';

  public country = TnSupportedCountry.RU;

  public address = '';

  public workingHoursFrom = '';

  public workingHoursTo = '';

  public extPointId = '';

  public type: string | null = null;

  public actions: { loading: boolean; unloading: boolean } = { loading: true, unloading: true };

  public loadTypes: transport.ILoadingType[] = [];

  public fiasCode = '';

  public latitude = '';

  public longitude = '';

  public contacts: transport.IContact[] = [];

  public timezoneId: transport.ITimezone;

  /**
   * TnAddLoadingPlaceFormData constructor.
   * @param TODO because namefields will rename by backenders
   */
  // eslint-disable-next-line complexity -- TODO: tech debt
  constructor(loadingPlace: IPlace) {
    this.isArchived = loadingPlace.isArchived ?? false;
    this.pointName = loadingPlace.pointName ?? '';
    this.companyName = loadingPlace.companyName ?? '';
    this.settlement = loadingPlace.settlement ?? '';
    this.country = loadingPlace.country ?? TnSupportedCountry.RU;
    this.address = loadingPlace.address ?? '';
    this.workingHoursFrom = loadingPlace.workingHoursFrom ?? '';
    this.workingHoursTo = loadingPlace.workingHoursTo ?? '';
    this.loadTypes = loadingPlace.loadingTypes ?? [];
    this.fiasCode = loadingPlace.fiasCode ?? '';
    this.latitude = loadingPlace.latitude ?? '';
    this.longitude = loadingPlace.longitude ?? '';
    this.contacts =
      loadingPlace.contacts?.map(item => {
        const contact: transport.IContact = transport.Contact.toObject(
          new transport.Contact({
            id: item.id ?? '',
            fullName: item.fullName ?? '',
            firstName: item.firstName ?? '',
            lastName: item.lastName ?? '',
            phoneNumber: item.phoneNumber ?? '',
          }),
          { defaults: true },
        );
        return contact;
      }) ?? [];
    this.timezoneId = loadingPlace.timezone ?? {};
    this.extPointId = loadingPlace.extPointId ?? '';
    if (loadingPlace.type) this.type = loadingPlace.type;
    if (loadingPlace.actions) {
      this.actions.loading = !!loadingPlace.actions.find(el => el === 'LOADING');
      this.actions.unloading = !!loadingPlace.actions.find(el => el === 'UNLOADING');
    }
  }
}

/**
 * AddLoadingPlace form interface.
 */
export interface IAddLoadingPlaceForm extends FormGroup {
  controls: {
    isArchived: AbstractControl;
    pointName: AbstractControl;
    companyName: AbstractControl;
    settlement: AbstractControl;
    country: AbstractControl;
    address: AbstractControl;
    workingHoursFrom: AbstractControl;
    workingHoursTo: AbstractControl;
    extPointId: AbstractControl;
    type: AbstractControl;
    actions?: AbstractControl;
    loadTypesSide: AbstractControl;
    loadTypesBack: AbstractControl;
    loadTypesTop: AbstractControl;
    loadTypes: AbstractControl;
    fiasCode: AbstractControl;
    latitude: AbstractControl;
    longitude: AbstractControl;
    contacts: AbstractControl;
    timezoneId: AbstractControl;
  };
}

/**
 * New AddLoadingPlace form constructor type.
 * @param fb form builder
 * @param editableItem editable item
 */
export type TNewAddLoadingPlaceFormConstructor = (
  fb: FormBuilder,
  disabled: boolean,
  posibleLoadingTypes: transport.ILoadingType[],
  editableItem?: TnAddLoadingPlaceFormData,
) => IAddLoadingPlaceForm;

/**
 * AddLoadingPlace form constructor. while use clientside-mocks
 * @param TODO because namefields will rename by backenders
 */
export const newAddLoadingPlaceForm: TNewAddLoadingPlaceFormConstructor = (
  fb: FormBuilder,
  disabled: boolean,
  posibleLoadingTypes: transport.ILoadingType[],
  editableItem?: TnAddLoadingPlaceFormData,
): IAddLoadingPlaceForm => {
  return fb.group(
    {
      isArchived: [{ value: editableItem?.isArchived, disabled }],
      pointName: [{ value: editableItem?.pointName, disabled }, Validators.compose(getPointNameValidators())],
      companyName: [{ value: editableItem?.companyName, disabled }, Validators.compose(getCompanyNameValidators())],
      settlement: [{ value: editableItem?.settlement, disabled }, Validators.compose([Validators.required, trimmedStringValidator])],
      country: [{ value: editableItem?.country, disabled }, Validators.required],
      address: [{ value: editableItem?.address, disabled }],
      workingHoursFrom: [{ value: editableItem?.workingHoursFrom, disabled }, Validators.compose([timesRangeValidator()])],
      workingHoursTo: [{ value: editableItem?.workingHoursTo, disabled }, Validators.compose([timesRangeValidator()])],
      extPointId: [
        { value: editableItem?.extPointId, disabled },
        Validators.compose([trimmedStringValidator, Validators.maxLength(MAX_EXTPOINTID_LENGTH)]),
      ],
      type: [{ value: editableItem?.type, disabled }],
      actions: fb.group(
        {
          loading: [{ value: editableItem?.actions.loading, disabled }],
          unloading: [{ value: editableItem?.actions.unloading, disabled }],
        },
        { validators: pointTypeValidator() },
      ),
      loadTypes: fb.array(getLoadingTypes(posibleLoadingTypes, disabled, fb, editableItem)),
      fiasCode: [{ value: editableItem?.fiasCode, disabled }],
      latitude: [{ value: editableItem?.latitude, disabled }],
      longitude: [{ value: editableItem?.longitude, disabled }],
      contacts: [{ value: editableItem?.contacts, disabled }],
      timezoneId: [{ value: editableItem?.timezoneId.id, disabled }],
    },
    {
      validators: [timeToMoreThanTimeFromValidator('workingHoursFrom', 'workingHoursTo')],
      updateOn: 'blur',
    },
  ) as IAddLoadingPlaceForm;
};

function getLoadingTypes(
  posibleLoadingTypes: transport.ILoadingType[],
  disabled: boolean,
  fb: FormBuilder,
  editableItem?: TnAddLoadingPlaceFormData,
) {
  return posibleLoadingTypes.map(valueType => {
    const checked = Boolean(editableItem?.loadTypes.find(t => t.id === valueType.id));
    return fb.control({
      value: { ...valueType, checked },
      disabled,
    });
  });
}

export function getPointNameValidators() {
  return [Validators.maxLength(MAX_POINT_NAME_LENGTH), trimmedStringValidator];
}

export function getCompanyNameValidators() {
  return [Validators.required, Validators.maxLength(MAX_ORGANIZATION_LENGTH), trimmedStringValidator];
}

function pointTypeValidator(): ValidatorFn {
  return (formControl: AbstractControl) => {
    const { loading, unloading } = (formControl as FormGroup)?.getRawValue();
    if (!loading && !unloading) {
      return { typePointError: true };
    }
    return null;
  };
}
