import { COMMA, ENTER, SPACE, TAB } from '@angular/cdk/keycodes';
import { ChangeDetectionStrategy, Component, Input, TemplateRef, ViewChild } from '@angular/core';
import { FormControl, FormGroup } from '@angular/forms';
import { MatChipInputEvent } from '@angular/material/chips';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { MatTooltip } from '@angular/material/tooltip';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';
import { LIVE_TOOLTIP, regExpConfig } from '@transport/ui-utils';
import { debounceTime, Subject, tap } from 'rxjs';

import { TnModalInviteService } from './modal-invite.service';
import { TnAlertService } from '@transport/ui-kit';
import { TranslateService } from '@ngx-translate/core';

export interface IEmails {
  email: string;
  isCorrectEmail: boolean;
}

@UntilDestroy()
@Component({
  selector: 'transport-modal-invite',
  templateUrl: './modal-invite.component.html',
  styleUrls: ['./modal-invite.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TnModalInviteComponent {
  @Input() inOrder = false;

  @ViewChild('dialogRef') public dialogTemplateRef?: TemplateRef<any>;

  public dialogRef?: MatDialogRef<any>;

  public inviteLink = '';

  public publicLink = '';

  public tooltipPrivate = false;

  public tooltipPublic = false;

  public tooltipTimer;

  public addOnBlur = true;

  public readonly separatorKeysCodes = [ENTER, COMMA, TAB, SPACE] as const;

  public emails: IEmails[] = [];

  public emailsform = new FormGroup({
    email: new FormControl(''),
  });

  public isCorrectEmails!: boolean;

  public showError!: boolean;

  public publicLinkStatus = false;

  private privateAutoAccreditationSetting = false;

  private readonly stringTransferReg = /\t/gi;

  @ViewChild('inviteLinkTooltip') public inviteLinkTooltip: MatTooltip | undefined;

  private readonly showInviteLinkTooltipSubject = new Subject<void>();

  private readonly inviteLinkTooltipFunctionality = this.showInviteLinkTooltipSubject.asObservable().pipe(
    tap(() => {
      this.inviteLinkTooltip?.show(0);
    }),
    debounceTime(LIVE_TOOLTIP),
    tap(() => {
      this.inviteLinkTooltip?.hide(0);
    }),
  );

  constructor(
    private readonly dialog: MatDialog,
    private readonly tnModalInviteService: TnModalInviteService,
    private alertService: TnAlertService,
    private translate: TranslateService,
  ) {}

  public addEmail(event: MatChipInputEvent): void {
    const emails: IEmails[] = [];
    const value = (event.value || '').replace(this.stringTransferReg, ' ').trim();
    this.isCorrectEmails = false;

    if (value) {
      new Set([...this.emails.map(el => el.email), ...value.split(' ')]).forEach(email => {
        emails.push({ email: email, isCorrectEmail: regExpConfig.email.regExp.test(email) });
      });
      this.emails = emails;
    }
    this.checkEmailsCorrect();
    event.chipInput?.clear();
  }

  public removeEmail(email: IEmails): void {
    const index = this.emails.indexOf(email);
    if (index >= 0) {
      this.emails.splice(index, 1);
    }
    this.emailsform.controls.email.setValue('');
    this.checkEmailsCorrect();
  }

  public onOpenModal(): void {
    this.inviteLink = '';
    this.emails = [];
    this.emailsform.reset();

    this.emailsform.controls.email.valueChanges.pipe(untilDestroyed(this)).subscribe(control => {
      this.isCorrectEmails = regExpConfig.email.regExp.test(control) && this.emails.every(elem => elem.isCorrectEmail);
      this.showError = false;
    });

    if (typeof this.dialogTemplateRef !== 'undefined') {
      void this.tnModalInviteService.createInviteLink(false).subscribe(res => {
        this.inviteLink = res.url;
      });
      void this.tnModalInviteService.setPublicLink(null).subscribe(res => {
        this.publicLink = res.url;
        this.publicLinkStatus = !res.isCompleted;
      });
      this.dialogRef = this.dialog.open(this.dialogTemplateRef, {
        width: '400px',
        autoFocus: false,
        // maxHeight: '60vh',
      });
      this.dialogRef.afterClosed().subscribe(() => {
        this.changeModalStyles(false);
      });
    }
    this.changeModalStyles(true);
    this.inviteLinkTooltipFunctionality.pipe(untilDestroyed(this)).subscribe();
  }

  public onClose(): void {
    if (typeof this.dialogTemplateRef !== 'undefined') {
      this.dialogRef?.close();
    }
  }

  public onSendEmail(): void {
    const emails = this.emails.reduce((emailsToSend: string[], elem) => {
      return [...emailsToSend, elem.email];
    }, []);
    this.onClose();
    void this.tnModalInviteService.sendEmailLink(emails, this.autoAccreditationSetting).subscribe({
      next: () => {
        this.showSuccess();
      },
      error: errors => {
        this.showEmailError(errors);
      },
    });
  }

  public onCopyLink(link, typeTooltip, withCopy = false) {
    if (Boolean(this.tooltipTimer)) {
      clearTimeout(this.tooltipTimer);
    }
    this[typeTooltip] = true;
    this.tooltipTimer = setTimeout(() => {
      this[typeTooltip] = false;
    }, LIVE_TOOLTIP);
    if (withCopy) {
      this.copyMessage(link);
    }
  }

  public showCurrentError(): string {
    if ((this.emailsform.controls.email.value === '' || this.emailsform.controls.email.value === null) && this.emails.length === 0) {
      return 'shared.errors.required';
    }
    return 'shared.errors.email';
  }

  private checkEmailsCorrect(): void {
    if (this.emails.every(elem => elem.isCorrectEmail) && this.emails.length !== 0) {
      this.isCorrectEmails = true;
      this.showError = false;
    } else {
      this.showError = true;
    }
  }

  private copyMessage(val: string): void {
    const selBox = document.createElement('textarea');
    selBox.style.position = 'fixed';
    selBox.style.left = '0';
    selBox.style.top = '0';
    selBox.style.opacity = '0';
    selBox.value = val;
    document.body.appendChild(selBox);
    selBox.focus();
    selBox.select();
    document.execCommand('copy');
    document.body.removeChild(selBox);
  }

  public showSuccess(): void {
    this.alertService.shortSuccess(this.translate.instant('shared.inviteModal.success'));
  }

  public showEmailError(errors?): void {
    if (typeof errors === 'string' && Boolean(errors.includes('Http failure response'))) {
      this.alertService.shortError(this.translate.instant('shared.errors.unknown'));
    } else {
      this.alertService.shortError(this.translate.instant('shared.errors.email'));
    }
  }

  public onChangePublicLinkStatus(): void {
    this.publicLinkStatus = !this.publicLinkStatus;
    this.tnModalInviteService.setPublicLink(!this.publicLinkStatus).subscribe();
  }

  private changeModalStyles(change: boolean): void {
    const contWrap = document.getElementsByClassName('cdk-overlay-container')[0];
    if (change) {
      contWrap.className = contWrap.className + ' custom';
    } else {
      contWrap.className = contWrap.className.replace(' custom', '');
    }
  }

  public set autoAccreditationSetting(value: boolean) {
    if (value !== this.privateAutoAccreditationSetting) {
      void this.tnModalInviteService.createInviteLink(value).subscribe(res => {
        this.inviteLink = res.url;
      });
    }
    this.privateAutoAccreditationSetting = value;
  }

  public get autoAccreditationSetting() {
    return this.privateAutoAccreditationSetting;
  }

  public showInviteLinkWasChangedTooltip() {
    this.showInviteLinkTooltipSubject.next();
  }
}
