/**
 * Translate service owner dictionary: Russian.
 */
export const RU = {
  owner: {
    auction: {
      auctionTitle: 'Аукцион',
      createAuctionTitle: 'Cоздать аукцион',
      field: {
        startPrice: 'Стартовая стоимость перевозки',
        nds: 'С НДС',
        priceWithNds: 'Итого',
        allTime: 'Завершить торги через',
        minStepPrice: 'Размер шага',
        email: 'Email для оповещения',
        enableNotifications: 'Отслеживать ход торгов',
        startDatetime: 'Дата и время начала аукциона',
        endDatetime: 'Дата окончания аукциона',
        timeDifference: 'Времени осталось',
        currentOffer: 'Текущее предложение',
        lastBet: 'Последняя ставка',
        lastBetDatetime: 'Время последней ставки',
        lastBetCarrier: 'Последняя ставка от:',
        hour: 'Ч.',
        min: ' Мин.',
        lot: 'Лот',
        startAuction: 'Аукцион начнется автоматически после создания',
        endAuctionParams: 'Параметры окончания аукциона',
        endTimeAuction: 'Время окончания аукциона',
        endAuctionParamsItems: {
          setDate: 'Указать дату и время окончания',
          setDuration: 'Указать длительность (закончить через)',
        },
      },
      errors: {
        minZero: 'Значение не может быть меньше 0',
        maxHour: 'Значение не может быть больше 23',
        maxMinute: 'Значение не может быть больше 59',
        lessThanNow: 'Дата и время окончания не может быть в прошлом',
        emptyDuration: 'Время окончания аукциона должно быть больше 0',
      },
    },
    insurance: {
      dialogTitle: 'Заявка на страхование',
      dataPreparedMessage:
        'Страховой полис будет выслан по электронной почте после сохранения заявки и подтверждения грузоперевозчиком заявки на перевозку',
      insurer: 'СТРАХОВАТЕЛЬ',
      legalStatus: 'Юридический статус',
      insurerName: 'Название страхователя',
      formOfIncorporation: 'Организационно-правовая форма',
      inn: 'ИНН',
      beneficiaryAddress: 'Адрес выгодоприобретателя',
      loadPlace: 'МЕСТО ПОГРУЗКИ',
      placeFrom: 'Откуда',
      address: 'Адрес',
      loadDate: 'Дата погрузки',
      unloadPlace: 'МЕСТО ДОСТАВКИ',
      placeTo: 'Куда',
      cargo: 'Груз',
      cargoname: 'Название груза',
      cargoKind: 'Вид груза',
      cargoClass: 'Класс груза',
      cargoCount: 'Грузовых мест',
      packing: 'Упаковка',
      weight: 'Вес (т)',
      declaredPrice: 'ОБЪЯВЛЕННАЯ СТОИМОСТЬ',
      declaredPriceCargo: 'Объявленная стоимость груза',
      insurancePrice: 'Стоимость страховки',
      profile: {
        insureAllOrders: 'Страховать груз по всем заявкам',
        manageInsurance: 'Управление страхованием',
      },
      contract: {
        confirm: {
          remove: {
            title: 'Удаление договора страхования',
            content: 'Вы уверены, что хотите удалить этот договор?',
            confirm: 'Да, удалить',
            cancel: 'Нет',
          },
        },
        addContract: 'Создать договор',
        markAsDefault: 'Выбрать по умолчанию',
        titlePage: 'Договор по страхованию',
        newTitlePage: 'Новый договор по страхованию',
        label: {
          company: 'Страховая компания',
          contractNumber: 'Номер генерального договора',
          tariff: 'Тариф (% от стоимости груза)',
          period: {
            from: 'Дата начала действия договора',
            to: 'Дата окончания действия договора',
          },
          default: 'Договор по умолчанию',
        },
        errors: {
          lessThanNow: 'Дата начала не может быть раньше текущей',
          lessThanStart: 'Дата окончания не может быть раньше даты начала',
        },
        table: {
          column: {
            id: 'ID',
            company: 'Страховая компания',
            contractNumber: '№ генерального договора',
            tariff: 'Тариф (% от стоимости груза)',
            startDate: 'Дата начала действия',
            endDate: 'Дата окончания действия',
          },
        },
        spass: {
          label: {
            kind: 'Вид груза',
            class: 'Класс груза',
            apiKey: 'Ключ API',
          },
        },
        message: {
          saveSuccess: 'Договор успешно сохранен',
          saveError: 'Произошла ошибка при сохранении',
          removeError: 'Произошла ошибка при удалении',
          removeSuccess: 'Договор успешно удален',
          setDefaultError: 'Произошла ошибка при выборе договора по умолчанию',
          setDefaultSuccess: 'Договор по умолчанию успешно выбран',
        },
      },
    },
    createOrder: {
      point: {
        contactTitle: 'Контактное лицо',
        loadingTypeTitle: 'Тип погрузки/разгрузки',
        noNameFound: 'Нет ФИО',
        commentTitle: 'Комментарий',
        additionalInfo: 'Дополнительная информация',
        form: {
          contactNamePlaceholder: 'Фамилия Имя',
          commentNamePlaceholder: 'Ваш комментарий...',
          datePlaceholder: 'дд.мм.гггг',
          timeFromPlaceholder: 'чч:мм',
          timeToPlaceholder: 'чч:мм',
          loadingTypeNotSpecified: 'Не указан',
        },
        actions: {
          cancel: 'Отменить',
          save: 'Сохранить',
          addContact: '+ Добавить контакты',
          addOneMoreContact: 'Добавить еще одно контактное лицо',
          or: 'или',
          createNewContact: 'Создать новое',
        },
      },
    },
    order: {
      orderTitle: 'Заявка',
      creationTitle: 'Создание заявки',
      customerOrganization: 'Заказчик перевозки',
      createOrderTitle: 'Cоздать заявку',
      driverTitle: 'Водитель',
      transportTitle: 'Транспорт',
      showInsureDetails: 'Параметры страхования',
      success: 'Заявка успешно создана',
      transportationPrice: 'Цена перевозки',
      toTheRouteButton: 'Перейти к маршруту',
      cargos: 'Грузы',
      route: 'Маршрут',
      proposal: {
        title: 'Активное ценовое предложение',
        status: 'Ожидает подтверждения',
        oldPrice: 'Старая цена',
        newPrice: 'Новая цена',
      },
      action: {
        cancel: 'Отменить',
        close: 'Закрыть',
        success: 'Заявка успешно опубликована',
        publish: 'Опубликовать',
        delayedPublish: 'Запланировать',
        publishAndClose: 'Опубликовать и закрыть',
        save: 'Сохранить',
        saveChanges: 'Сохранить изменения',
        saveAndClose: 'Сохранить и закрыть',
        copy: 'Копировать',
        remove: 'Удалить',
        archive: 'Архивировать',
        refresh: 'Обноввить',
        goToOrder: 'Перейти к заявке',
        insure: 'Застраховать груз',
        addUnloadingPlace: 'Добавить разгрузку',
        addLoadingPlace: 'Добавить погрузку',
        addCargo: 'Добавить груз',
        addContact: 'Добавить контакты',
        fetchMsg: {
          error: 'Ошибка получения данных заявки',
        },
        createMsg: {
          success: 'Заявка №{{orderId}} успешно создана',
          error: 'При создании заявки произошла ошибка',
        },
        change: {
          success: 'Заявка успешно назначена',
          error: 'Ошибка при назначении заявки',
        },
        update: {
          success: 'Заявка №{{orderId}} успешно сохранена',
          error: 'Ошибка при сохранении заявки',
        },
        draft: {
          success: 'Заявка успешно сохранена',
          error: 'Ошибка при сохранении заявки',
        },
        publication: {
          success: 'Заявка №{{orderId}} успешно опубликована',
          error: 'Ошибка при публикации заявки',
        },
        removeMsg: {
          success: 'Заявка №{{id}} успешно отменена',
          error: 'Ошибка при удалении заявки',
        },
        earlyFinishMsg: {
          success: 'Аукцион по заявке №{{orderId}} досрочно завершен',
          error: 'Не удалось досрочно завершить аукцион',
        },
        cancelTradingMsg: {
          success: 'Торги по заявке №{{orderId}} отменены',
          error: 'Не удалось отменить торги',
        },
        acceptCounterOfferAsBet: {
          success: 'Встречное предложение принято',
          error: 'Не удалось принять встречное предложение',
        },
        acceptCounterOfferAsBetForBidding: {
          success: 'Встречное предложение принято как ставка',
          error: 'Не удалось принять встречное предложение',
        },
        acceptCounterOffer: {
          success: 'Заявка успешно назначена',
          error: 'Не удалось назначить заявку',
        },
      },
      field: {
        loadingPlace: 'Откуда',
        loading: 'Погрузка',
        loadingPlaceAddress: 'Адрес места погрузки',
        loadingPlaceComment: 'Комментарий к погрузке',
        unloadingPlace: 'Куда',
        unloading: 'Разгрузка',
        unloadingPlaceAddress: 'Адрес места доставки',
        unloadingPlaceComment: 'Комментарий к доставке',
        date: 'Дата',
        dateFrom: 'Дата, c',
        dateTo: 'Дата, по',
        loadingDate: 'Дата погрузки',
        unloadingDate: 'Дата доставки',
        timeFrom: 'Время с',
        timeTo: 'Время по',
        time: 'Время',
        timezone: 'Часовой пояс',
        loadingLocalTime: 'Время по месту погрузки',
        unloadingLocalTime: 'Время по месту доставки',
        price: 'Цена, руб',
        nds: 'С НДС',
        allowCounterOffer: 'Разрешить встречные предложения',
        comment: 'Комментарий',
        fio: 'ФИО грузоперевозчика',
        fioShort: 'ФИО',
        email: 'Email',
        phoneNumber: 'Телефон',
        carrier: 'Перевозчик',
        carrierGroup: 'Группа перевозчиков',
        volume: 'Объем (м³)',
        weight: 'Вес (т)',
        temperatureMode: 'Температурный режим (\u2103)',
        cargoType: 'Тип груза',
        truckInfo: 'Данные автомобиля',
        trailerInfo: 'Данные прицепа',
        driverInfo: 'Данные водителя',
        contactPerson: 'Контактное лицо',
        ownerContact: 'Ответственный',
        ownerContacts: 'Ответственный за заявку',
        contactInfo: 'Контактная информация',
        organization: 'Организация',
        carrierGroups: 'Группа',
        actualCarrier: 'Фактический перевозчик',
        acceptingPlace: 'Площадка',
        partners: 'Партнеры',
        market: 'Рынок',
        transportationPrice: 'Цена за перевозку',
        paymentPeriod: 'Срок оплаты',
        paymentType: 'Тип оплаты',
        loadingType: 'Тип погрузки\\разгрузки',
        unloadingPlacePhoto: 'Фото объекта',
        unloadingPlacePhotoCount: 'Еще {{count}} фото',
        hidePhotoPanel: 'Свернуть',
        name: 'Наименование',
        weight2: 'Вес',
        volume2: 'Объем',
        package: 'Упаковка',
        amount: 'Количество',
        dimensions: 'Габариты',
        packageAndAmount: 'Упаковка и кол-во',
      },
      cargoForm: {
        title: 'Груз',
        cargoName: 'Наименование',
        form: {
          cargoPlaceholder: 'Выберите из списка',
          tabs: {
            label: {
              ttn: 'Кол-во по ТТН',
              weight: 'Вес',
              volume: 'Объем',
              package: 'Упаковка',
            },
            ttnInfo: 'Количество груза для разгрузки будет указано в ТТН',
          },
          weightLabel: 'Вес',
          volumeLabel: 'Объем',
          packageLabel: 'Упаковка',
          packagePlaceholder: 'Паллеты',
          amountLabel: 'Кол-во',
          dimensions: 'Габариты',
          dimensionsType: 'м',
          dimensionsLengthlaceholder: 'Д',
          dimensionsWidthPlaceholder: 'Ш',
          dimensionsHeightPlaceholder: 'В',
          package: 'Паллеты',
          weight: 'тонн',
          volume: 'м³',
          quantity: 'шт',
          amount: '00',
          errors: {
            emptyPackage: 'У груза не указана упаковка на загрузке',
            minPrice: 'Стоимость должна быть больше 0',
            maxPrice: 'Стоимость не может быть больше {{ value }}',
          },
          outOf: 'из',
          from: 'с',
          to: 'по',
          packageButton: 'Упаковка и кол-во',
          dimensionsButton: 'Габариты',
          cargoPrice: 'Стоимость груза',
          extCargoId: 'Идентификатор груза',
          docTypeLabel: 'Тип документа',
          docTypePlaceholder: 'Выберите тип',
        },
      },
      statuses: {
        noTruck: 'Транспорт не назначен',
        noTrailer: 'Прицеп не указан',
        noDriver: 'Водитель не назначен',
      },
      cargoStatus: {
        NOT_STARTED: 'Не выполнялся',
        LOADED: 'Загружен',
        DELIVERED: 'Доставлен',
        CANCELLED: 'Не выполнен',
        PARTLY_DELIVERED: 'Частично доставлен',
      },
      publishTypeNew: {
        carrierAllAccredited: 'Все аккредитованные',
        noOptions: 'Вариантов нет',
        publishTypeLabel: 'Тип проведения',
        displayToCarriers: 'Отображение перевозчикам',
        onlyPartners: 'Только партнеры',
        allPlatform: 'Всем на платформе',
        allPlatformDesc: 'В торгах смогут участвовать все проверенные Рынком грузов и транспорта перевозчики.',
        inDev: 'В разработке',
        endOrderLabel: 'Завершить прием предложений в: ',
        assignTo: 'Назначить на ',
        vatHint: 'В т.ч. НДС: {{value}} {{currency}}',
        price: 'Цена ',
        startPrice: 'Стартовая цена ',
      },
      executor: {
        settings: {
          manual: 'Найти перевозчика',
          search: 'Назначить перевозчика',
        },
        nds: 'С НДС',
        manual: {
          carrier: {
            assignTo: 'Назначить на',
            selectCarrier: 'Выберите перевозчика',
            carrierHint:
              'Перевозчику будет выдана данная заявка с запросом подтверждения. Транспортное средство и водитель будут определены перевозчиком.',
            transportationPrice: 'Цена перевозки',
            vatHint: 'В т.ч. НДС: {{value}} {{currency}}',
            contractNumber: 'Номер договора',
            selectContractNumber: 'Выберите номер договора',
          },
        },
        search: {
          tabs: {
            auction: 'Аукцион',
            quickOrder: 'Срочный заказ',
            bidding: 'Сбор предложений',
          },
          bidding: {
            currency: 'Валюта',
            hint: 'Перевозчики предложат свою цену за рейс, можно выбрать любое из предложений или провести аукцион на его основе.',
          },
          auction: {
            hint: 'Участники будут соревноваться, предлагая выгодную ставку. Заявка достанется перевозчику, предложившему наименьшую цену в отведенный срок.',
            startPrice: 'Стартовая цена',
            allowCounterOffer: 'Принимать встречные предложения',
          },
          quickOrder: {
            hint: 'Торги по этому заказу не проводятся, его получит первый откликнувшийся перевозчик по фиксированной цене.',
            bet: 'Ставка',
            startPrice: 'Ставка',
          },
          playground: {
            title: 'Площадки',
            myCarriers: 'Мои перевозчики',
            showToCarriers: 'Отображение перевозчикам',
            marketplace: 'Рынок грузов и транспорта',
            carrierAllAccredited: 'Все аккредитованные',
            lockHint:
              'Заявка будет опубликована на внутреннем портале. В конкурсной процедуре смогут участвовать только ваши доверенные перевозчики',
            truckHint: 'В конкурсной процедуре смогут участвовать все, проверенные Рынком грузов и транспорта, перевозчики',
          },
          duration: {
            label: 'Длительность процедуры',
            auctionLabel: 'Длительность аукциона',
            placeholder: 'Выберите длительность',
            min: 'минут',
            hour: 'час',
            hours: 'часа',
            ghours: 'часов',
            day: 'день',
            timeUntil: 'Проводить до',
            timeUntilHint: '(дата и время)',
            fromFirstBet: 'Завершить после первой ставки через:',
            fromFirstBetEmpty: 'Не выбрано',
            datetimeBeforeFirstLoading: 'При отсутствии ставок аукцион завершится {{date}} в {{time}}',
          },
          delayedPublication: 'Отложенная публикация',
        },
      },
      errors: {
        isQuickOrder: 'Цена не может быть менее {{value}} {{currency}}',
        isVatIncluded: 'Цена не может быть менее {{value}} {{currency}} + размер НДС',
        lessThanStep: 'Цена не может быть менее {{value}}{{currency}}.',
        moreThan: 'Цена не может быть более {{value}}{{currency}}.',
        required: 'Поле обязательно к заполнению',
        loadingPlace: 'Выберите точку погрузки из списка',
        unloadingPlace: 'Выберите точку доставки из списка',
        loadingDate: 'Укажите дату погрузки',
        unloadingDate: 'Укажите дату доставки',
        price: 'Поле цена обязательно к заполнению',
        comment: 'Поле комментарий обязательно к заполнению',
        carrier: 'Выберите перевозчика из списка',
        minVolume: 'Объем не может быть меньше {{value}}',
        minWeight: 'Вес не может быть меньше {{value}}',
        minTemperature: 'Температура не может быть меньше -99',
        maxTemperature: 'Температура не может быть больше 99',
        temperatureRangeError: 'Значение в поле "от" не может быть больше значения в поле "до"',
        cargoType: 'Выберите тип груза из списка',
        truckInfo: 'Укажите информацию о транспорте',
        trailerInfo: 'Укажите информацию о прицепе',
        driverInfo: 'Укажите информацию о водителе',
        startPriceLessThanMinStep: 'Стартовая стоимость не должна быть меньше размера шага',
        create: 'Ошибка при создании заявки',
        externalNoExists: 'Номер уже используется в другой заявке',
        minPriceError: 'Цена заявки на перевозку не может быть меньше или равна 100р. Введите другую сумму.',
        maxStepPrice: 'Шаг цены должен быть меньше цены заявки',
        minStepPrice: 'Шаг цены должен быть больше {{value}}',
        minStepAddiotionalCriteria: 'Шаг критерия должен быть равен или больше {{value}}',
      },
      confirm: {
        new: {
          title: 'Отмена создания заявки',
          content: `Вы не завершили создание заявки.
            При переходе на другую страницу данные не сохранятся.
            Вы уверены, что хотите отменить эту заявку?`,
          confirm: 'Да, отменить',
          cancel: 'Нет',
        },
        remove: {
          title: 'Отмена заявки',
          content: 'Вы уверены, что хотите отменить эту заявку?',
          confirm: 'Да, отменить',
          cancel: 'Нет',
        },
        clearInsurance: {
          title: 'Отказ от страхования',
          content: 'Вы уверены, что хотите отказаться от страхования?',
          confirm: 'Да',
          cancel: 'Нет',
        },
        earlyFinish: {
          title: 'Досрочное завершение аукциона',
          content: 'Вы уверены, что хотите досрочно завершить аукцион?\n Победным будет предложение: {{price}}',
          confirm: 'Да, завершить',
          cancel: 'Нет',
        },
        cancelTrading: {
          title: 'Отменить торги',
          content: 'Вы уверены, что хотите отменить активный торговый процесс?\n Заявка перейдет в статус "Ожидает обработки"',
          confirm: 'Да, отменить',
          cancel: 'Нет',
        },
        acceptCounterOffer: {
          title: 'Назначить заявку',
          content: 'Заявка будет назначена на {{carrier}} по ставке {{price}}. Продолжить?',
          confirm: 'Да',
          cancel: 'Нет',
        },
        acceptCounterOfferAsBet: {
          title: 'Принять встречное предложение',
          content: 'Встречное предложение будет принято по ставке {{price}}. Продолжить?',
          confirm: 'Да',
          cancel: 'Нет',
        },
        acceptCounterOfferAsBetForBidding: {
          title: 'Сменить тип процедуры на аукцион',
          content: 'Встречное предложение будет принято как ставка {{price}}. Продолжить?',
          confirm: 'Да',
          cancel: 'Нет',
        },
      },
      labels: {
        orderDetails: 'Информация о заказе',
        loadingPlace: 'Откуда',
        unloadingPlace: 'Куда',
        cargo: 'Груз',
        carrier: 'Перевозчик',
        cargoPlace: 'Грузовое место',
        vehicleRequirements: 'Требования к ТС',
        price: 'Цена',
        route: 'Маршрут',
        publishTypeNew: 'Публикация',
        executor: 'Исполнитель',
        publication: 'Публикация',
        publishType: 'Способ публикации',
        carrierGroup: 'Группа перевозчиков',
        singleCarrier: 'Конкретный перевозчик',
        market: 'Рынок',
        allGroups: 'Все перевозчики',
        externalNo: 'Внешний номер',
        drivingDirections: 'Схема проезда',
        look: 'Посмотреть',
        services: 'Доп. услуги',
        auction: 'Аукцион',
        auctionDesc:
          'Торги на понижение цены заказа. Заказ достанется перевозчику, предложившему наименьшую цену в отведенный на торг срок.',
        quickOrder: 'Срочный заказ',
        quickOrderDesc: 'Торги по этому заказу не проводятся, его получит первый откликнувшийся перевозчик по фиксированной цене.',
        setCarrier: 'Назначить на перевозчика',
        setCarrierDesc:
          'Вы самостоятельно назначаете перевозчика на выполнение заказа. Далее ему будут высланы уведомления с информацией о заказе.',
      },
      warnings: {
        tnCryptoCargoOwnerSigning: 'Грузоперевозчик отправил данные для формирования и подписи заявки-договора',
        tnCryptoContractSigningError: 'Заявка-договор не подписана со стороны заказчика перевозки, обратитесь в службу поддержки',
        tnCryptoContractCreatingError: 'При формировании заявки-договора возникла ошибка на стороне 1С, обратитесь в службу поддержки',
        tnCryptoContractCarrierSigningError: 'Ошибка подписи договора у грузоперевозчика',
        tnCryptoContractCarrierWait: 'Вы подписали договор. Ожидаем подписание грузоперевозчика',
        tnCryptoContractSigned: 'Договор успешно подписан',

        tnCryptoAgreementOwnerChecking: 'Вы подписали Соглашение о расторжении заявки договора. Ждите, идет проверка подписи',
        tnCryptoAgreementSigningError:
          'Произошла ошибка подписи Соглашения о расторжении заявки-договора. Обратитесь в службу тех.поддержки',
        tnCryptoAgreementCarrierWait: 'Вы подписали Соглашение о расторжении заявки договора. Ожидайте подписи Грузоперевозчика',
        tnCryptoAgreementSigned: 'Соглашения о расторжении заявки-договора подписано двусторонне. Заявка отменена',
      },
      accreditationTooltip: {
        message: 'Для участия в открытых торгах пройдите аккредитацию',
        btnOpenUrl: 'Пройти',
      },
    },
    auctionBetModal: {
      header: 'Информация о торгах',
      subHeader: 'Рынок грузов и транспорта',
      orderInfo: {
        type: {
          header: 'Тип процедуры:',
          auction: 'Аукцион',
          simpleOrder: 'Срочный заказ',
          bidding: 'Сбор предложений',
        },
        timeRange: {
          header: 'Время проведения:',
          timeFrom: 'c {{value}} ',
          timeTo: 'по {{value}}',
        },
        participantsRange: {
          header: 'Участники:',
          market: 'Все на платформе',
          partners: 'Все аккредитованные',
        },
        startPrice: 'Стартовая цена:',
        initialPrice: 'Начальная цена:',
        simpleOrderPrice: 'Ставка:',
        factPrice: 'Фактическая цена:',
        timeTillEnd: 'До завершения процедуры:',
        participantsCount: 'Участников:',
        bestBet: 'Лучшая ставка:',
      },
      counterOfferHeader: 'Встречные предложения',
      priceHistoryHeader: 'Изменения цены',
      betsHeader: 'Сделанные ставки',
      noBets: 'Ставок нет',
      cancelButton: 'Отменить торги',
      vat: 'c НДС',
      noVat: 'без НДС',
      finish: 'Завершить досрочно',
      assign: 'Назначить',
      accept: 'Принять',
      biddingToAuction: 'Принять как ставку',
      button: 'Открыть детали торгов',
    },
    cargoType: {
      action: {},
      cargoTypeTitle: 'Тип груза',
      success: {
        save: 'Тип груза успешно сохранен',
        remove: 'Тип груза "{{name}}" успешно добавлен в архив',
      },
      view: {
        cargoName: 'Название груза',
        cargoParams: 'Вес, объем, температурный режим',
        cargoPlace: 'Грузовое место (ДхВхШ), м',
        cargoPlaceCount: 'Грузовых мест',
        packageType: 'Вид упаковки',
        capacity: 'Грузоподъемность',
        bodyType: 'Тип кузова',
        subBodyType: 'Подтип кузова',
        volume: 'Объем',
        internalBodySize: 'Внутренний размер кузова (ДхВхШ), м',
        loadingType: 'Тип погрузки',
        cargoPlaceTitle: 'Грузовое место',
        internalBodySizeTitle: 'Внутренний размер кузова',
        value: {
          weight: '{{ value }} т',
          volume: '{{ value }} м³',
          temperature: '{{ value }} \u2103',
        },
      },
      failure: {
        remove: 'Не удалось добавить в архив тип груза "{{name}}"',
      },
      errors: {
        name: 'Максимальное количество символов {{value}}',
        defaultWeight: 'Минимальное значение {{value}}',
        defaultVolume: 'Минимальное значение {{value}}',
        temperatureMode: 'Минимальное значение {{value}}',
        cargoPackagesCount: 'Минимальное значение 1',
        size: {
          min: 'Минимальное значение {{value}}',
          max: 'Максимальное значение {{value}}',
        },
        save: 'Ошибка сохранения типа груза',
      },
      form: {
        status: 'Статус',
        active: 'активная',
        archive: 'архивная',
        commonInfoBlock: 'Общая информация',
        shippingRequirements: 'Требования к перевозке груза',
        gargoPlaceBlock: 'Грузовое место',
        vehicleRequirementsBlock: 'Требования к ТС',
        lengthCargoPackage: 'Длина (м)',
        widthCargoPackage: 'Ширина (м)',
        heightCargoPackage: 'Высота (м)',
      },
      confirm: {
        new: {
          title: 'Отмена создание типа груза',
          content: `Вы не завершили создание типа груза.
              При переходе на другую страницу данные не сохраняться.
              Вы уверены, что хотите удалить эту заявку?`,
          confirm: 'Да, отменить',
          cancel: 'Нет',
        },
        remove: {
          title: 'Архивирование типа груза',
          content: 'Вы уверены, что хотите переместить этот тип груза в архив?',
          confirm: 'Да, архивировать',
          cancel: 'Нет',
        },
      },
      packagingType: {
        pallets: 'Паллеты',
        boxes: 'Коробки',
        bundles: 'Пачки',
        placer: 'Россыпь',
      },
    },
    carrierOrganization: {
      actions: {
        create: 'Добавить договор',
      },
      title: 'Перевозчик',
      errors: {
        phoneNumber: 'Неверный формат. Номер должен содержать 11 цифр, первая цифра не должна быть 0',
      },
      contract: {
        number: 'Договор №{{contractNumber}}',
        dates: 'Срок с {{startDate}} по {{endDate}}',
        datesOnlyStart: 'Срок с {{startDate}}',
        cargoQuantity: 'Количество перевозок - {{cargoQuantity}}',
        loadingPlaces: 'Точки погрузки: ',
        unloadingPlaces: 'Точки доставки: ',
        form: {
          status: 'Статус',
          archive: 'архивный',
          number: 'Номер договора',
          period: 'Период действия договора',
          from: 'с',
          to: 'по',
          loadingPlace: 'Место погрузки',
          unloadingPlace: 'Место доставки',
          cargoQuantity: 'Договорное количество перевозок',
          cargoType: 'Тип груза',
          downloadDoc: 'Скачать договор',
          actions: {
            addLoadingPlace: 'Добавить место погрузки',
            addUnloadingPlace: 'Добавить место доставки',
            addCargoType: 'Добавить тип груза',
          },
        },
        confirm: {
          remove: {
            title: 'Архивирование контракта',
            content: 'Вы уверены, что хотите архивировать этот контракт?',
            confirm: 'Да, архивировать',
            cancel: 'Нет',
          },
          lossData: {
            title: 'Отмена контракта',
            content: `Вы не завершили ввод данных контракта.
                При переходе на другую страницу данные не сохраняться.
                Вы уверены, что хотите перейти без сохранения?`,
            confirm: 'Да, перейти',
            cancel: 'Нет',
          },
        },
        result: {
          successSave: 'Изменения сохранены',
          successRemove: 'Контракт отправлен в архив',
        },
      },
    },
    subOrganization: {
      title: 'Управление организациями',
      status: 'Статус',
      filter: {
        all: 'Все',
        active: 'Активные',
        archived: 'Неактивные',
      },
      columns: {
        id: 'Ид.',
        type: 'Тип',
        name: 'Название',
        signer: 'ФИО руководителя',
        legalAddress: 'Юридический адрес',
        isArchived: 'Статус',
        isCompanyGroup: 'Группа компаний',
        isNotCompanyGroup: 'Дочерняя компания',
      },
      modals: {
        archive: {
          title: 'Удаление организации',
          content: 'Вы уверены, что хотите удалить эту организацию?',
          confirm: 'Да, удалить',
          cancel: 'Нет',
        },
      },
    },
    profile: {
      title: 'Профиль компании',
      settings: 'Настройки',
      subOrganizations: {
        title: 'Группа компаний',
        titleDescr: 'Включает в себя несколько организаций',
        editSubOrganizationsButton: 'Управление организациями',
      },
      error: {
        orderDuration: 'Максимальное время длительности аукциона не может быть меньше времени жизни заявки.',
        monitoring: 'Ошибка сохранения настроек мониторинга',
        routing: 'Ошибка сохранения настроек маршрутизации',
      },
      form: {
        confirmation: {
          title: 'Сохранение настроек',
          content: 'Вы действительно хотите сохранить настройки?',
          confirm: 'Сохранить',
          cancel: 'Отмена',
        },
        blocks: {
          auctionSettings: 'Торги',
          about: 'Об организации',
          commonSettings: 'Общие настройки',
          paymentsSettings: 'Оплата',
          insurance: 'Страхование',
          personalization: 'Персонализация',
          documentFlow: 'Документооборот',
          cargos: 'Грузы',
          signature: 'Подпись (факсимиле)',
          logo: 'Логотип',
          routings: 'Маршрутизация',
          monitoring: 'Мониторинг',
          driverRequiredFields: 'Требуемые документы водителя',
        },
        cargos: {
          cargoRequired: 'Обязательный ввод грузов в заявке',
        },
        monitoring: {
          coordinatesUpdatePeriod: 'Периодичность получения геолокации',
          maxDistanceToPoint: 'Радиус контроля дистанции въезда в точку при ручном проставлении статуса',
          canChangePointsOrder: 'Возможность менять порядок объезда',
          isWaybillRequired: 'Принудительный запрос фото документов у водителя',
          notifyLocInterval: 'Ограничить периодичность получения писем о местоположении водителя',
          allowedNonActivePeriod: 'Ограничить длительность работы мониторинга по заявке',
          allowedOutOfRadarPeriod: 'Сообщать о проблеме если не поступает геолокация',
          trackBeforeFirstPoint: 'Включить мониторинг до погрузки',
          autoComplete: 'Авто-проставление статусов',
          radiusForAutoComplete: 'Радиус дистанции автоматического проставления статуса при въезде в точку',
        },
        showOrderExternalNo: {
          label: 'Использовать внешний номер в заявках на перевозку',
        },
        companyName: {
          label: 'Наименование компании',
        },
        country: {
          label: 'Страна регистрации',
        },
        inn: {
          label: 'ИНН',
        },
        kpp: {
          label: 'КПП',
          error: 'Допустимый формат 9 цифр',
        },
        phoneNumber: {
          label: 'Телефон',
        },
        legalAddress: {
          label: 'Юридический адрес',
        },
        postalAddress: {
          label: 'Почтовый адрес',
        },
        email: {
          label: 'Эл. почта',
        },
        ogrn: {
          label: 'ОГРН/ОГРНИП',
        },
        legalForm: {
          label: 'ОПФ',
        },
        iin: {
          label: 'ИИН',
        },
        bin: {
          label: 'БИН',
        },
        signer: {
          label: 'Подписывающее лицо',
          fio: 'ФИО',
          country: 'Страна регистрации',
        },
        vatType: {
          label: 'Тип НДС цены по умолчанию',
        },
        currency: {
          label: 'Валюта по умолчанию',
        },
        paymentType: {
          label: 'Предпочитаемый тип оплаты',
        },
        paymentPeriod: {
          label: 'Срок оплаты по умолчанию',
        },
        enableDeliveryTracking: {
          label: 'Требуется отслеживание доставки',
        },
        fieldsForCompleteReg: 'Для завершения регистрации организации заполните следующие реквизиты',
        routings: {
          averageSpeed: 'Средняя скорость движения',
          averageLoadingDuration: 'Среднее время погрузки',
          averageUnloadingDuration: 'Среднее время разгрузки',
          cargoPriceRequired: 'Цена груза обязательна при создании',
          coordinatesSelect: {
            title: 'Минимальная точность координат',
            none: 'Не указан',
            exact: 'Точные координаты',
            nearestHouse: 'Ближайший дом',
            street: 'Улица',
            locality: 'Населенный пункт',
            city: 'Город',
            undefinedCoordinates: 'Координаты не определены',
          },
        },
      },
      subOrganizationsTable: {
        columns: {
          companyName: 'Организация',
          signer: 'ФИО Руководителя',
          legalAddress: 'Юридический адрес',
        },
      },
      message: {
        saveProfileSuccess: 'Данные сохранены',
        removeProfileSuccess: 'Организация успешно добавлена в архив',
        removeProfileError: 'Произошла ошибка при удалении организации',
      },
    },
    report: {
      statistics: 'Время обработки заявок',
      summary: 'Общая информация по заявкам',
      ordersTrading: 'Эффективность торгов',
      ordersTradingDetails: 'Детали торгов',
      title: 'Отчеты',
      form: {
        selectReport: 'Выберите отчет',
        range: {
          from: 'Дата создания заявки, с',
          to: 'Дата создания заявки, по',
        },
        numberOrder: 'Номер заявки',
        carrier: 'Грузоперевозчик',
        carrierGroup: 'Группа грузоперевозчиков',
        download: 'Скачать',
      },
      error: {
        dateToLessThanDateFrom: '"Дата создания заявки, по" не может быть раньше "Дата создания заявки, с"',
      },
    },
    place: {
      removeDrivingImg: 'Удалить схему',
      search: {
        placeholder: 'Введите название населенного пункта',
      },
      success: {
        save: 'Адрес успешно сохранен',
        remove: 'Адрес успешно добавлен в архив',
      },
      errors: {
        save: 'Ошибка при сохранении адреса',
        pointNameDublicate: 'Точка с таким именем уже существует',
        remove: 'Не удалось добавить в архив адрес "{{name}}"',
      },
    },
    draft: {
      title: 'Черновик',
      typelabel: 'Тип заявки',
      freeOrder: 'Свободная заявка',
    },
    tracking: {
      notifications: {
        noCoordinates: 'Место не имеет координат. Для возможности отслеживания заявки обновите адрес в справочнике',
        trackingNotAvailable:
          'Отслеживание данной заявки невозможно, так как при создании одно или несколько мест не имело координат. Обновите адреса мест погрузки/доставки в справочнике и используйте их при создании заявки, чтобы иметь возможность отслеживать новую заявку.',
        disableTracking: 'Отслеживание  заявок отключено',
      },
    },
    loadingPlaceFilter: {
      title: 'Фильтры по адресу погрузки / доставки',
      form: {
        address: 'Адрес',
        settlement: 'Населенный пункт',
        pointName: 'Название места в системе',
        organizationName: 'Организация',
        inn: 'ИНН',
        loading: 'Место погрузки',
        unloading: 'Место доставки',
      },
    },
    sla: {
      notification: {
        deleteSuccess: 'Уровень обслуживания удалён',
        deleteFailed: 'Не удалось удалить уровень обслуживания',
        saveError: 'Ошибка сохранения уровня ослуживания',
        saveSuccess: 'Уровень обслуживания сохранен',
      },
      page: {
        section: {
          main: {
            name: {
              title: 'Название уровня обслуживания',
              placeholder: 'Название уровня обслуживания',
            },
            description: {
              placeholder: 'Описание уровня обслуживания',
              slaInfo: 'В уровне обслуживания можно выбрать критерии выполнения.',
              eventInfo: 'События по Отслеживаемым критериям автоматически фиксируются.',
              descriptionInfo: 'Описание Пользовательского критерия показывается перевозчику в заявке-договоре.',
            },
          },
          trackable: {
            title: 'Отслеживаемые критерии уровня обслуживания',
            criterion: 'Критерий',
            thresholdName: 'Пороговое значение',
            description: {
              title: 'Описание критерия для перевозчика',
              placeholder: 'Описание',
            },
          },
          nonTrackable: {
            title: 'Пользовательские критерии уровня обслуживания',
            noCriteria: 'Пользовательских критериев нет. Нажмите "Добавить" чтобы создать.',
            criterion: {
              title: 'Критерий',
              placeholder: 'Название',
            },
            description: {
              title: 'Описание критерия для перевозчика',
              placeholder: 'Описание',
            },
          },
        },
      },
    },

    routings: {
      cargoCardModal: {
        header: 'Карточка груза',
        copyHeader: 'Копирование груза',
        fields: {
          isVisibleForEveryone: 'Виден для всех',
          status: {
            label: 'Статус',
          },
          extOrderId: {
            label: 'Номер заказа',
          },
          extCargoId: {
            label: 'Номер груза',
          },
        },
        actions: {
          save: 'Сохранить',
          cancel: 'Отмена',
          copy: 'Копировать',
          delete: 'Удалить',
          open: 'Открыть',
          ungroup: 'Запретить группировку',
          group: 'Разрешить группировку',
        },
        placeTypes: {
          loading: 'Погрузка',
          unloading: 'Разгрузка',
        },
        confirm: 'Вы действительно хотите удалить груз?',
        cancelConfirm: 'Вы изменили данные груза, который хотите скопировать. Сохранить изменения перед копированием?',
      },
      table: {
        header: {
          weight: 'Вес',
          volume: 'Объем',
          receiver: 'Получатель',
          address: 'Адрес',
          comment: 'Комментарий',
          cargo: 'Груз',
          characteristic: 'Характеристики',
          loadingAddress: 'Адрес погрузки',
          unloadingAddress: 'Адрес доставки',
          group: 'Группировать',
        },
        empty: 'Грузы не найдены',
        weight: '{{value}} т',
        volume: ' | {{value}} м³',
        price: '{{value}}',
      },
      filter: {
        deliveryDate: 'Дата доставки',
        loadingPoints: 'Места погрузки',
        deliveryTime: 'Время доставки',
        status: {
          label: 'Статус груза',
          placeholder: 'Выберите статус',
        },
        weight: 'Вес груза',
        volume: 'Объем груза',
        price: 'Стоимость груза',
      },
      status: {
        new: 'Новый',
        distributed: 'Распределен',
        published: 'Назначение',
        ready: 'К отправке',
        undelivered: 'Не выполнен',
        delivered: 'Доставлен',
      },
      messages: {
        coordinatesNotCorrect: 'Координаты определены неточно, кликните на иконку для ручной корректировки.',
        error: {
          create: 'Не удалось создать груз',
          delete: 'Не удалось удалить груз',
          edit: 'Не удалось сохранить груз',
          filter: {
            dateTime: 'Формат времени чч:мм',
            format: 'Невалидный формат',
          },
        },
        success: {
          create: 'Груз успешно создан',
          delete: 'Груз успешно удален',
          edit: 'Груз успешно сохранен',
          copy: 'Груз успешно скопирован',
        },
      },
      routingList: {
        header: {
          routes: 'Маршруты',
          info: 'Характеристики',
          weight: 'Вес',
        },
        noRoutesFound: 'Маршруты не найдены',
        noCargosFound: 'Грузы в маршруте отсутствуют',
        routeStatus: {
          created: 'Планирование',
          planned: 'Обработка',
          published: 'Назначение',
          refused: 'Отказано',
          booked: 'К отправке',
          cancelled: 'Отменен',
          finished: 'Завершен',
        },
        distance: '{{value}} км',
        duration: '{{value}} ч',
        showMore: 'Показать еще {{value}}...',
        errors: {
          downloadRoute: 'Ошибка загрузки маршрутов. Попробуйте перезагрузить страницу',
          downloadCargos: 'Ошибка загрузки точек маршрута. Попробуйте перезагрузить страницу',
          createOrder: 'Не удалось создать заявку',
          optimizeRoute: 'Не удалось оптимизировать маршрут',
          deleteRoute: 'Не удалось удалить маршрут',
          positionErrorHeader: 'Ошибка изменения маршрута',
          positionCantBeChanged: 'Маршруты в данном статусе нельзя редактировать',
          positionError: 'Точки разгрузки не могут быть раньше точек погрузки, соответствующих одному грузу',
        },
        success: {
          createOrder: 'Заявка успешно создана',
          deleteRoute: 'Маршрут успешно удален',
          optimizeRoute: 'Маршрут успешно оптимизирован',
        },
        actions: {
          createOrder: 'Создать заявку',
          openOrder: 'К заявке',
          optimizeRoute: 'Оптимизировать',
          delete: 'Удалить',
          openCard: 'Карточка груза',
          deletePoint: 'Удалить из маршрута',
          editRoute: 'Грузы в маршруте',
        },
        confirm: {
          deleteOrderBeforeDeletingRoute: 'Чтобы удалить маршрут необходимо сначала удалить заявку',
          deleteRoute: 'Вы действительно хотите удалить маршрут?',
        },
      },
      routeCard: {
        createTitle: 'Создать маршрут',
        editTitle: 'Редактировать маршрут',
        routeNameLabel: 'Наименование маршрута',
        routeNamePlaceholder: 'Начните ввод',
        allCargos: 'Все грузы',
        routeCargos: 'Точки маршрута',
        epmtyRoute: 'Грузы не добавлены',
        actions: {
          open: 'Карточка груза',
          add: 'Добавить в маршрут',
          delete: 'Удалить из маршрута',
        },
        messages: {
          errors: {
            emptyRoute: 'Чтобы создать маршрут, выберите хотя бы один груз',
            create: 'Не удалось создать маршрут',
            maxWeight: 'Суммарный вес грузов не может превышать 99.99 т',
            maxVolume: 'Суммарный объем грузов не может превышать 120 м³',
            maxWeightVolume: 'Суммарный вес грузов не может превышать 99.99 т. Cуммарный объем грузов не может превышать 120 м³',
            edit: 'Не удалось сохранить маршрут',
          },
          success: {
            create: 'Маршрут успешно создан',
            edit: 'Маршрут успешно сохранен',
          },
        },
      },
    },
    tenders: {
      title: 'Тендеры',
      tender: 'Тендер',
      newTender: 'Новый тендер',
      table: {
        columns: {
          id: 'И.Д.',
          directionsAmount: 'Направлений',
          contractTime: 'Период закрепления',
          startTime: 'Начало тендера(MCK)',
          finishTime: 'Завершение тендера(MCK)',
          status: 'Статус',
          offers: 'Имеются предложения',
          carrierOffers: 'Участвую',
          winningOffers: 'Побеждаю',
          bidsEndDate: 'Подведение итогов до',
        },
        actions: {
          copy: 'Копировать',
          cancel: 'Отменить',
          create: 'Создать',
        },
        filters: {
          all: 'Все',
          canceled: 'Отменен',
          created: 'Ожидает начала',
          processing: 'Прием предложений',
          pending: 'Подведение итогов',
          completed: 'Завершен',
        },
      },
      directions: {
        tabs: {
          all: 'Все',
          participate: 'Участвую',
        },
      },
      form: {
        common: 'Общее',
        transportationAmount: 'Объем перевозок',
        directions: 'Направления',
        loadingPlace: 'Откуда',
        unloadingPlace: 'Куда',
        vehicleRequirements: 'Требование к ТС',
        offersByDirection: 'Предложения по направлению',
        cargoName: 'Груз',
        acceptFullVolume: 'Предложения с полным объемом перевозок',
        carrierOffer: 'Ваше предложение',
        offers: 'Предложения',
        more: 'еще',
        view: 'посмотреть',
        bet: 'Ставка',
        transportationsByMonth: 'рейсов в месяц',
        transportationsAmount: 'в месяц',
        byTransportation: 'за рейс',
        noOffers: 'Нет предложений',
        priceByTransportation: 'Цена за рейс',
        startPrice: 'Стартовая цена',
        bestPrice: 'Лучшая цена',
        comment: 'Дополнительная информация',
        commentToOffer: 'Комментарий к предложению',
        contractPeriod: {
          from: 'Период закрепления от',
          to: 'до',
        },
        loadingTime: 'Окно погрузки',
        unloadingTime: 'Окно разгрузки',
        way: 'рейс',
        contacts: 'Контактные лица',
        description: 'Описание услуги',
        error: {
          max: 'Ставка не должна превышать {{ value }}',
        },
      },
      actions: {
        sendOffer: 'Отправить предложение',
        add: 'Добавить',
        saveDraft: 'Сохранить черновик',
        publish: 'Опубликовать',
      },
      modals: {
        useDraft: {
          title: 'Черновик',
          content: 'Применить сохраненные данные?',
          confirm: 'Да',
          cancel: 'Нет',
        },
        cancelConfirmation: {
          title: 'Отмена',
          content: 'Подтвердить отмену тендера?',
          confirm: 'Да',
          cancel: 'Нет',
        },
      },
      publication: {
        success: 'Тендер №{{tenderId}} успешно опубликован',
        error: 'Ошибка при публикации тендера',
      },
      cancel: {
        success: 'Тендер №{{tenderId}} отменен',
        error: 'Ошибка при отмене тендера',
      },
      makeOffer: {
        success: 'Предложения по тендеру №{{tenderId}} отправлен грузовладельцу',
        error: 'Ошибка при отправки предложения',
      },
      emptyDirections: {
        error: 'Нужно добавить минимум одно направление',
      },
      savedOwnerDraft: {
        success: 'Черновик сохранен',
      },
    },
  },
};
