import { Pipe, PipeTransform } from '@angular/core';
import memo from 'memo-decorator';

@Pipe({
  name: 'toolbarCounter',
})
export class TnToolbarCounterPipe implements PipeTransform {
  constructor() {}

  @memo({
    resolver: (...args: any[]): string => JSON.stringify(args),
  })
  public transform(value: number | string | null | undefined): string {
    const tabCountLimit = 100;

    if (Boolean(value) && value !== '0') {
      return (value ?? 0) >= tabCountLimit ? '99+' : `${value}`;
    }
    return '';
  }
}
