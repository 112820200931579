import { HttpClient } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Sort } from '@angular/material/sort';
import { DomSanitizer } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { transport } from '@transport/proto';
import {
  ICargoPackagingType,
  ICargoType,
  IDriver,
  IGqlAddWaybillResponse,
  IInsuranceData,
  IInsuranceDialogData,
  IInsuranceOutputData,
  ILot,
  insuranceOutputDataFromDto,
  IOrder,
  IOrdersFilter,
  IPlace,
  IVehicle,
  MIME_TYPE,
  MS_AMOUNT_OF_SECOND,
  ORDER_ALLOCATION_TYPE,
  TCargoPackagingTypeCode,
  TnFileToUpload,
  TnOrderDocumentTypes,
  TOrderAttachmentForSave,
  USER_ROLE,
} from '@transport/ui-interfaces';
import { snakeCaseToCamelCase } from '@transport/ui-utils';
import { forkJoin, Observable, of } from 'rxjs';
import { map, switchMap, tap } from 'rxjs/operators';

import { TnFeatureAccessService } from '../../../feature-access/feature-access.service';
import { TnHttpHandlersService } from '../../../feature-access/http-handlers.service';
import { TnGqlClientCarrierService } from '../../../gql-client-carrier/graphql-client-carrier.service';
import { TnGqlClientSharedService } from '../../../gql-client-shared/graphql-client-shared.service';
import { TnCommonOrdersService } from '../common/common-order.service';

@Injectable({
  providedIn: 'root',
})
export class TnCarrierOrdersService extends TnCommonOrdersService {
  constructor(
    private readonly graphQL: TnGqlClientCarrierService,
    protected graphQLShared: TnGqlClientSharedService,
    private readonly http: HttpClient,
    private readonly handlers: TnHttpHandlersService,
    protected translateService: TranslateService,
    protected featureAccess: TnFeatureAccessService,
    private readonly domSanitizer: DomSanitizer,
  ) {
    super(translateService, featureAccess, graphQLShared);
  }

  /**
   * Accept free order
   * @returns [Observable<IOrder>] new value  if order is accepted
   */
  public acceptOrder(orderId: string): Observable<IOrder> {
    return this.graphQL.acceptOrder(orderId).pipe(
      switchMap(data => {
        return of(data.bookOrder);
      }),
    );
  }

  /**
   * Cancel assigned order
   * @returns [Observable<IOrder>] new value if order is canceled
   */
  public cancelOrder(orderId: string, isTerminationAgreement = false): Observable<IOrder> {
    return this.graphQL.cancelOrder(orderId, isTerminationAgreement);
  }

  /**
   * Complete assigned order
   * @returns [Observable<IOrder>] new value if order is completed
   */
  public completeOrder(orderId: string): Observable<IOrder> {
    return this.graphQL.completeOrder(orderId).pipe(map(result => result.completeOrder));
  }

  /**
   * Cancel transport reserve in the order
   * @returns [Observable<IOrder>] new value if order is completed
   */
  public unreserveTransport(orderId: string): Observable<IOrder> {
    return this.graphQL.unreserveTransport(orderId).pipe(map(result => result.unreserveTransport));
  }

  public getOrders(listenX?: number, withSpinner?: boolean, first?: number, offset?: number, filter?: IOrdersFilter, sorter?: Sort) {
    return this.graphQL.queryOrders(listenX, withSpinner, first, offset, filter, sorter).pipe(
      map(data => {
        return {
          list: data.orders.map(item => ({
            ...item,
            loadingPlace: (item.loadingPlaces ?? [])[0]?.storagePoint ?? null,
            unloadingPlace: (item.unloadingPlaces ?? [])[0]?.storagePoint ?? null,
            lifeTimeExpiredMs: this.getOrderLifeTimeExpiredMs(item),
            lotTimeExpiredMs: Boolean(item.lot?.viewEndDatetime) ? new Date(item.lot?.viewEndDatetime ?? '').getTime() - Date.now() : 0,
          })),
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  public getBiddingOrders(listenX?: number, withSpinner?: boolean, first?: number, offset?: number, filter?: IOrdersFilter, sorter?: Sort) {
    return this.graphQL.queryBiddingOrders(listenX, withSpinner, first, offset, filter, sorter).pipe(
      map(data => {
        return {
          list: data.biddingOrders.map(item => ({
            ...item,
            loadingPlace: (item.loadingPlaces ?? [])[0]?.storagePoint ?? null,
            unloadingPlace: (item.unloadingPlaces ?? [])[0]?.storagePoint ?? null,
            lifeTimeExpiredMs: this.getOrderLifeTimeExpiredMs(item),
            biddinglotTimeExpiredMs: Boolean(item.biddinglot) ? (item.biddinglot?.viewEndDatetime ?? 0) * MS_AMOUNT_OF_SECOND : 0,
            biddingStatusForCarrier: `shared.biddingStatusForCarrier.${snakeCaseToCamelCase(item?.biddingStatusForCarrier as string)}`,
          })),
          totalCount: data.totalCount ?? 0,
        };
      }),
    );
  }

  /**
   * Get order by id
   * @returns [Observable<IOrder>] order
   */
  public getOrder(orderId: string): Observable<IOrder> {
    return this.graphQL.queryOrder(orderId).pipe(
      map(data => {
        return { ...data.order };
      }),
    );
  }

  /**
   * Get auction order by id
   */
  public getAuctionOrder(orderId: string, withSpinner?: boolean): Observable<IOrder> {
    return this.graphQL.queryAuctionOrder(orderId, withSpinner).pipe(map(data => data.auctionOrder));
  }

  public getBiddingOrder(orderId: string, withSpinner?: boolean): Observable<IOrder> {
    return this.graphQL.queryBiddingOrder(orderId, withSpinner).pipe(map(data => data.biddingOrder));
  }

  /**
   * Get cargo body types with sublasses
   * @returns [Observable<IBodyType[]>] list of cargo body types
   */
  public getBodyTypes(): Observable<transport.Vehicle.Body.IType[]> {
    return this.graphQL.queryBodyTypes().pipe(map(data => data.bodyTypes));
  }

  public getLoadTypes(): Observable<transport.Vehicle.Body.IType[]> {
    return this.graphQL.queryLoadingTypes().pipe(map(data => data.loadingTypes));
  }

  public getPackagingTypesList(): Observable<ICargoPackagingType[]> {
    return this.graphQL.queryPackagingTypes();
  }

  public getProfileInfo(): Observable<transport.IAdminOrganization> {
    return this.graphQL.getProfileInfo().pipe(map(data => data.profile.organization));
  }

  public getInsuranceInfo(orderId: string): Observable<{
    cargoClasses: { id: string; name: string }[];
    cargoKinds: { id: string; name: string }[];
  }> {
    return this.graphQL.queryInsuranceInfo(orderId, false).pipe(map(value => this.getInsuranceInfoMap(value)));
  }

  public getInsuranceCost(orderId: string, declaredPrice: number) {
    return this.graphQL.queryInsuranceCost(orderId, declaredPrice, false).pipe(map(value => value.insuranceCost));
  }

  public getInsuranceData(params: IInsuranceDialogData): Observable<IInsuranceData> {
    const { currentValue, selectValues, orderId } = params;
    const { declaredPrice } = currentValue;
    const { cargoTypes } = selectValues;

    return forkJoin({
      insuranceInfo: this.getInsuranceInfo(orderId as string),
      userOrganization: this.getProfileInfo(),
      insurancePrice: Boolean(declaredPrice) ? this.getInsuranceCost(orderId as string, declaredPrice as number) : of(''),
    }).pipe(
      switchMap(result => {
        const { userOrganization, insurancePrice, insuranceInfo } = result;
        const { cargoKinds, cargoClasses } = insuranceInfo;

        return of({
          cargoType: cargoTypes[0] as ICargoType,
          userOrganization,
          insurancePrice,
          select: {
            cargoKinds,
            cargoClasses,
          },
        });
      }),
    );
  }

  public addInsuranceContract(orderId: string, insurance: transport.IInsurance) {
    return this.graphQL.addInsuranceContract(orderId, insurance);
  }

  public reserveTransportV2(
    driverId: string,
    orderId: string,
    vehicleId: string,
    carrierContractId: string | null,
    agentCarrierId: string | null,
    freightState?: string | null,
  ): Observable<IOrder> {
    return this.graphQL
      .reserveTransportV2(driverId, orderId, vehicleId, carrierContractId, agentCarrierId, freightState)
      .pipe(map(data => data.reserveTransportV2));
  }

  /**
   * Get order by id
   * @returns [Observable<IOrder>] order
   */
  public placeABet(order: IOrder, bet: number): Observable<ILot> {
    return this.graphQL.placeABet(bet, order.id ?? '').pipe(map(data => data.placeBet));
  }

  public placeBiddingBet(orderId: string, bet: number): Observable<transport.IBiddingLot> {
    return this.graphQL.placeBiddingBet(bet, orderId).pipe(map(data => data.placeBiddingBet));
  }

  /**
   * Attaches provided contract files to provided order.
   * @param files contract files
   * @param order order object
   */
  public attachContract(files: TnFileToUpload[], order: IOrder) {
    return this.graphQLShared.attachContract(files, order.id ?? '').pipe(map(data => data.attachContract));
  }

  public getOrderDocumentTypes(): Observable<TnOrderDocumentTypes> {
    return super.getOrderDocumentTypes(USER_ROLE.CARRIER);
  }

  public attachDocuments(documents: TOrderAttachmentForSave[], orderId: string): Observable<transport.IOrderUploadedDocument[]> {
    return super.attachDocuments(documents, orderId, USER_ROLE.CARRIER);
  }

  public addWaybill(documents: TOrderAttachmentForSave[], orderId: string): Observable<IGqlAddWaybillResponse> {
    return this.graphQL.addWaybill(orderId, documents);
  }

  public editWaybill(documents: TOrderAttachmentForSave[], orderId: string): Observable<IGqlAddWaybillResponse> {
    return this.graphQL.editWaybill(orderId, documents);
  }

  public removeDocument(documentId: string, orderId: string) {
    return super.removeDocument(documentId, orderId, USER_ROLE.CARRIER);
  }

  /**
   * Downloads contract.
   * @param order order object
   */
  // TODO использовать downloadService
  public downloadContract(order: IOrder): Observable<boolean> {
    const url = this.handlers.getEndpointUrl(`/download/contract-form/${order.id}/`);
    const headers = this.handlers.getAuthHeaders();
    return this.http.get(url, { observe: 'response', responseType: 'arraybuffer', headers }).pipe(
      tap(data => {
        if (Boolean(data?.headers)) {
          const constentHeaderString = data.headers.get('Content-Disposition');
          const sliceStart = 29;
          const fileNameString = constentHeaderString?.slice(sliceStart);
          let fileName = 'Форма договора.docx';

          try {
            fileName = decodeURIComponent(fileNameString ?? '');
          } catch (error) {
            // ERROR: console.error('decodeURIComponent error', error);
          }

          if (Boolean(window.navigator?.msSaveOrOpenBlob)) {
            // IE11
            const blob = new Blob([data.body ?? new ArrayBuffer(0)], {
              type: MIME_TYPE.DOCX,
            });
            window.navigator.msSaveOrOpenBlob(blob, fileName);
          } else {
            const file = new File([data.body ?? new ArrayBuffer(0)], fileName, {
              type: MIME_TYPE.DOCX,
            });
            const link = document.createElement('a');
            link.href = window.URL.createObjectURL(file);
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
          }
        }
      }),
      map(() => true),
    );
  }

  /**
   * Changes provided order status to a respective value.
   * @param order order object
   */
  public letsGo(orderId: string): Observable<IOrder> {
    return this.graphQL.letsGo(orderId).pipe(map(data => data.letsGo));
  }

  public getVehicleDriver(vehicleId: string, orderId: string, withSpinner = false, listenX = 1): Observable<IDriver[]> {
    return this.graphQL.vehicleDriver(vehicleId, orderId, listenX, withSpinner).pipe(map(data => data.vehicleDrivers));
  }

  public getVehicleByDriver(driverId: string, orderId: string, withSpinner = false, listenX = 1): Observable<IVehicle[]> {
    return this.graphQL.vehicleByDriver(driverId, orderId, listenX, withSpinner).pipe(map(data => data.driverVehicles));
  }

  public getDataForInsuranceDialog(isViewMode: boolean, order: IOrder, packagesList: ICargoPackagingType[]): Partial<IInsuranceDialogData> {
    const fakeId = '1';
    const insuranceData = isViewMode ? insuranceOutputDataFromDto(JSON.parse(order.insurance?.data ?? '')) : null;
    const fakePackages = [{ id: fakeId as TCargoPackagingTypeCode, name: insuranceData?.cargoPacking }] as ICargoPackagingType[];
    const selectValues = {
      cargoTypes: [{ id: fakeId, name: order.cargoType }] as ICargoType[],
      packagingTypes: isViewMode ? fakePackages : packagesList,
    };
    const currentValue = this.getCurrentInsuranceValues(isViewMode, order, insuranceData, fakeId);
    const result = {
      isViewMode,
      selectValues,
      currentValue,
      contractId: order.ownerInsuranceContract?.id ?? fakeId,
      ownerInsuranceContract: order.ownerInsuranceContract,
      orderId: order.id as string,
    };
    return result;
  }

  private getCurrentInsuranceValues(isViewMode: boolean, order: IOrder, insuranceData: IInsuranceOutputData | null, fakeId: string) {
    const loadingPlaces = order.loadingPlaces as transport.Order.StoragePoint[];
    const unloadingPlaces = order.unloadingPlaces as transport.Order.StoragePoint[];
    const firstLoadingPlace = loadingPlaces[0];
    const lastUnloadingPlace = unloadingPlaces[unloadingPlaces.length - 1];

    return {
      loadingPlace: { ...firstLoadingPlace.storagePoint } as IPlace,
      unloadingPlace: { ...lastUnloadingPlace.storagePoint } as IPlace,
      loadingDate: order.loadingDate as string,
      weight: insuranceData?.cargoWeight as number,
      cargoTypeId: fakeId,
      cargoPackagesCount: isViewMode ? insuranceData?.cargoCount ?? 0 : (order.cargoPackagesCount as number),
      ...(isViewMode && { declaredPrice: insuranceData?.declaredPrice }),
      ...(isViewMode && {
        cargoKind: insuranceData?.cargoKind?.toUpperCase(),
      }),
      ...(isViewMode && {
        cargoClass: insuranceData?.cargoClass,
        packagingType: fakeId as TCargoPackagingTypeCode,
      }),
    };
  }

  public getCarrierUserMarkerAttributes(id: string): Observable<transport.ICarrierUser> {
    return this.graphQL.getCarrierUserMarkerAttributes(id).pipe(map(result => result.carrierUser));
  }

  public setSecondDriver(coDriverId: string, orderId: string): Observable<IDriver> {
    return this.graphQL.setCoDriver(coDriverId || null, orderId).pipe(
      map(result => {
        return result.coDriver as IDriver;
      }),
    );
  }

  public getCarrierContractsWithCargoOwner(cargoOwnerUserId: string): Observable<transport.Order.ICarrierContract[]> {
    return this.graphQL.getCarrierContractsWithCargoOwner(cargoOwnerUserId).pipe(map(result => result.carrierContractsWithCargoOwner));
  }

  public getNewDesignOrderPageLink({ allocationType, id }: IOrder) {
    const query = `${
      allocationType === ORDER_ALLOCATION_TYPE.AUCTION_ALLOCATION
        ? '?kind=auction'
        : allocationType === ORDER_ALLOCATION_TYPE.BIDDING_ALLOCATION
        ? '?kind=bid'
        : ''
    }`;
    return this.domSanitizer.bypassSecurityTrustResourceUrl(`${window.location.protocol}//${window.location.host}/orders/${id}${query}`);
  }
}
