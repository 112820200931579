import { Directive } from '@angular/core';
import { ControlValueAccessor, FormBuilder, FormGroup } from '@ngneat/reactive-forms';

@Directive()
export abstract class TnCustomNgNeatReactiveFormControlDirective<T> implements ControlValueAccessor<T> {
  public form: FormGroup = this.createFormGroup();

  public constructor(protected readonly fb: FormBuilder) {}

  public registerOnChange(fn): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn): void {
    this.onTouched = fn;
  }

  public writeValue(obj: T): void {
    this.form.patchValue(Boolean(obj) ? obj : {}, { emitEvent: false });
  }

  public setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.form.disable({ emitEvent: false }) : this.form.enable({ emitEvent: false });
  }

  protected abstract createFormGroup(): FormGroup;

  public onChange = (...args) => null;

  public onTouched = () => null;
}
