<div fxFlex fxLayoutAlign="start center">
  <ng-container *ngFor="let button of flowButtons">
    <transport-button
      fxFlexOffset="8px"
      [disabled]="disabled"
      [label]="button.label | translate"
      [icon]="button.icon"
      [type]="button.type"
      (action)="onAction($event, button.actionType)"
      [dataTest]="dataTest + '-entity-flow'"
    ></transport-button>
  </ng-container>
</div>
