import { Injectable } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { CARRIER_MUTATION } from '@transport/gql';
import { ACCEPTING_PLACE, AUCTION_CRITERION_CHOICES, USER_ROLE } from '@transport/ui-interfaces';
import { TnAnalyticsService, TnGqlClientSharedService } from '@transport/ui-store';
import { BehaviorSubject, Subject } from 'rxjs';
import { AUCTION_POPUP_QUERIES } from './auction-popup-queries';

export enum AuctionPopupViewEnum {
  bet = 'bet',
  counterOffer = 'counterOffer',
}

interface IAddOrderCounterOfferInput {
  orderId: number;
  amount: number;
  vatIncluded: boolean;
  currency: string;
  comment: string;
}

@Injectable()
export class AuctionPopupService {
  public $closePopupSubject = new Subject();

  public currentView$ = new BehaviorSubject<AuctionPopupViewEnum>(AuctionPopupViewEnum.bet);

  constructor(
    private readonly sharedGraphQlClient: TnGqlClientSharedService,
    public readonly translate: TranslateService,
    private readonly analytics: TnAnalyticsService,
  ) {}

  public placeBet(
    bet: {bet: number, additionalCriteriaData?: {type: AUCTION_CRITERION_CHOICES, value: number}[]},
    orderId: string,
    acceptingPlace: ACCEPTING_PLACE | null | undefined,
    comment?: string
  ) {
    this.analytics.track(`user sent bet`, {payload: acceptingPlace ?? ACCEPTING_PLACE.MARKET});
    return this.sharedGraphQlClient.mutate(USER_ROLE.CARRIER, AUCTION_POPUP_QUERIES.placeBet, { ...bet, orderId, comment, acceptingPlace });
  }

  public addOrderCounterOffer(input: IAddOrderCounterOfferInput, acceptingPlace: ACCEPTING_PLACE | null | undefined) {
    this.analytics.track(`user sent counter offer`, {payload: acceptingPlace ?? ACCEPTING_PLACE.MARKET});
    return this.sharedGraphQlClient.mutate(USER_ROLE.CARRIER, CARRIER_MUTATION.addOrderCounterOffer, { input, acceptingPlace });
  }
}
