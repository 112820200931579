<div class="container py-3 px-3 bg-white border rounded-md mb-2" fxLayout="column" fxFlex="1 1 auto">
  <div class="flex items-center mb-3">
    <div class="flex items-center place-content-center w-[34px] h-[34px] min-w-[34px] min-h-[34px] rounded-full mr-2" [ngClass]="{'bg-red-400': isProhibited, 'bg-orange-300': !isProhibited}">
      <mat-icon *ngIf="!isProhibited" [svgIcon]="'warning_amber'"></mat-icon>
      <mat-icon *ngIf="isProhibited" class="material-icons text-white">block</mat-icon>
    </div>
    <div>
      <div class="text-md font-bold">{{ title }}</div>
      <div *ngIf="subTitle" class="text-sm font-bold">{{ subTitle }}</div>
    </div>
  </div>
  <div class="flex ml-[42px]">
    <section class="flex flex-col w-[70%]">
      <transport-string-info
        label="{{ 'shared.blackList.card.labels.startDate' | translate }}"
        [body]="info?.startDate | date: 'dd.MM.yyyy'"
      ></transport-string-info>

      <section class="sm:hidden mobile:flex flex-col">
        <ng-container *ngIf="type === blackListType.TRUCK || type === blackListType.TRAILER">
          <transport-string-info
            label="{{ 'shared.blackList.card.labels.lockObject' | translate }}"
            [body]="textType"
          ></transport-string-info>
          <transport-string-info
            *ngIf="nomenclature"
            label="{{ 'shared.blackList.card.labels.nomenclature' | translate }}"
            [body]="nomenclature"
          ></transport-string-info>
        </ng-container>
      </section>

      <transport-string-info label="{{ 'shared.blackList.card.labels.reason' | translate }}" [body]="info?.reason"></transport-string-info>

      <transport-string-info
        label="{{ 'shared.blackList.card.labels.description' | translate }}"
        [body]="info?.description"
      ></transport-string-info>
    </section>

    <section class="sm:flex mobile:hidden flex-col w-[30%]">
      <ng-container *ngIf="type === blackListType.TRUCK || type === blackListType.TRAILER">
        <transport-string-info
          label="{{ 'shared.blackList.card.labels.lockObject' | translate }}"
          [body]="textType"
        ></transport-string-info>
        <transport-string-info
          *ngIf="nomenclature"
          label="{{ 'shared.blackList.card.labels.nomenclature' | translate }}"
          [body]="nomenclature"
        ></transport-string-info>
      </ng-container>
    </section>
  </div>
</div>

<ng-template #lockObjectTemplate>
  <transport-string-info label="{{ 'shared.blackList.card.labels.lockObject' | translate }}" [body]="textType"></transport-string-info>
</ng-template>
