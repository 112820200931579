import { Component, OnInit, Input } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, ValidationErrors, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';
import { regExpConfig } from '@transport/ui-utils';

@Component({
  selector: 'verification-vehicle-form[form]',
  templateUrl: './vehicle-form.component.html',
  styleUrls: ['./vehicle-form.component.scss'],
})
export class TnVehicleFormComponent implements OnInit {
  @Input() form!: FormGroup;

  public regExpConf = regExpConfig;

  constructor(private _formBuilder: FormBuilder, private translate: TranslateService) {}

  get ownership() {
    return this.form.controls['ownership'].value;
  }

  get vehicles() {
    return (this.form.controls['vehicles'] as FormArray).controls as FormGroup[];
  }

  ngOnInit(): void {
    if (!this.vehicles.length) this.addVehicle();
  }

  public addVehicle() {
    const vehicleArraySize = (this.form.get('vehicles') as FormArray).length;
    const vehicle = this._formBuilder.group({
      regNumber: ['', vehicleArraySize > 0 ? Validators.required : null],
      photoFirst: ['', vehicleArraySize > 0 ? Validators.required : null],
      photoSecond: ['', vehicleArraySize > 0 ? Validators.required : null],
      // regNumber: [''],
      // photo: [''],
    });
    (this.form.get('vehicles') as FormArray).push(vehicle);
  }

  public remove(index: number) {
    (this.form.get('vehicles') as FormArray).removeAt(index);
  }

  public changeOwnershiState() {
    this.vehicles.forEach(vehicle => {
      if (this.ownership === 'YES') {
        vehicle.controls['regNumber'].setValidators([Validators.min(9), Validators.required]);
        vehicle.controls['photoFirst'].setValidators(Validators.required);
        vehicle.controls['photoSecond'].setValidators(Validators.required);
      } else {
        vehicle.controls['regNumber'].reset();
        vehicle.controls['photoFirst'].reset();
        vehicle.controls['photoSecond'].reset();
        vehicle.controls['regNumber'].clearValidators();
        vehicle.controls['photoFirst'].clearValidators();
        vehicle.controls['photoSecond'].clearValidators();
      }
      vehicle.controls['regNumber'].updateValueAndValidity();
      vehicle.controls['photoFirst'].updateValueAndValidity();
      vehicle.controls['photoSecond'].updateValueAndValidity();
    });
  }

  public validationErrors(e: ValidationErrors | null) {
    // if (e?.required) return this.translate.instant('shared.errors.required');
    if (e?.trimmedString) return this.translate.instant('shared.errors.trimmedString');
    if (e?.lessThanAdultAge) return this.translate.instant('shared.errors.lessThanAdultAge');
    if (e?.moreThanNow) return this.translate.instant('shared.errors.moreThanNow');
    if (e?.pattern) return this.translate.instant('shared.errors.phone');
    if (e?.mask) return this.translate.instant('shared.mp.verification.validationErrors.regNumberMask');

    return '';
  }
}
