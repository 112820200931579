<input matInput class="mat-select-search-input mat-select-search-hidden"/>

<div class="mat-select-search-inner border-b-[1px] border-gray-200"
     [ngClass]="{'mat-select-search-inner-multiple': isMultipleSelect, 'flex items-center': withContent}">
  <ng-content></ng-content>
  <input matInput
         class="mat-select-search-input"
         #searchSelectInput
         (keydown)="_handleKeydown($event)"
         (input)="onInputChange($event)"
         (blur)="onBlur($event)"
         [placeholder]="placeholderLabel"
         autocomplete="off"
  />
  <button mat-button *ngIf="value"
          mat-icon-button
          aria-label="Clear"
          (click)="_reset(true)"
          class="mat-select-search-clear">
    <mat-icon class="!text-base !leading-[14px]">close</mat-icon>
  </button>
</div>
