<div *ngFor="let file of files; index as i" class="file-container">
  <div fxLayout="row" fxLayoutGap="16px">
    <transport-file-icon [fileType]="file.type" [fileExtension]="file.extension"></transport-file-icon>

    <div fxFlex fxLayout="column">
      <div
        fxLayout="row"
        fxFlex
        fxLayoutAlign="start start"
        class="file-info"
        [ngClass]="{
          uploaded: file.status === uploadStatusEnum.UPLOADED,
          error:
            file.status === uploadStatusEnum.UPLOAD_ERROR ||
            file.status === uploadStatusEnum.FILE_SIZE_ERROR
        }"
      >
        <div fxLayout="column" fxFlex>
          <span fxFlex class="file-name">
            <ng-container *ngIf="file.downloadUrl; else labelName">
              <a
                class="file-link"
                *ngIf="downloadHandlerType === clickHandlerTypeEnum.LINK_TARGET_BLANK"
                href="{{ file.downloadUrl }}"
                target="_blank"
                >{{ file.fullName }}</a
              >
              <a
                class="file-link"
                *ngIf="downloadHandlerType === clickHandlerTypeEnum.DOWNLOAD_GET_REQUEST"
                (click)="download(file)"
                >{{ file.fullName }}</a
              >
            </ng-container>

            <ng-template #labelName>{{ file.fullName }}</ng-template>
          </span>
        </div>

        <button
          mat-icon-button
          data-test="refresh-icon"
          *ngIf="file.status === uploadStatusEnum.UPLOAD_ERROR"
          (click)="onRefreshButtonClick(i)"
        >
          <mat-icon svgIcon="refresh" class="icon-button"></mat-icon>
        </button>

        <button mat-icon-button data-test="complete-icon" *ngIf="file.status === uploadStatusEnum.UPLOADED" disabled="true">
          <mat-icon svgIcon="complete2" class="icon-button"></mat-icon>
        </button>

        <ng-container *ngIf="file.downloadUrl">
          <a
            *ngIf="downloadHandlerType === clickHandlerTypeEnum.LINK_TARGET_BLANK"
            mat-icon-button
            href="{{ file.downloadUrl }}"
            target="_blank"
          >
            <ng-container *ngTemplateOutlet="downloadIcon"></ng-container>
          </a>

          <button mat-icon-button *ngIf="downloadHandlerType === clickHandlerTypeEnum.DOWNLOAD_GET_REQUEST" (click)="download(file)">
            <ng-container *ngTemplateOutlet="downloadIcon"></ng-container>
          </button>

          <ng-template #downloadIcon> <mat-icon svgIcon="download-gray" class="icon-button"></mat-icon></ng-template>
        </ng-container>

        <button *ngIf="!isReadOnly" mat-icon-button data-test="remove-icon" (click)="onRemoveButtonClick(i)">
          <mat-icon svgIcon="delete-outlined" class="icon-button"></mat-icon>
        </button>
      </div>

      <div fxLayout="row" fxFlex fxLayoutAlign="start start">
        <ng-container [ngSwitch]="file.status">
          <mat-progress-bar *ngSwitchCase="uploadStatusEnum.UPLOADING" mode="indeterminate"></mat-progress-bar>
          <mat-error *ngSwitchCase="uploadStatusEnum.UPLOAD_ERROR">{{
            'shared.errors.fileUpload' | translate
          }}</mat-error>
          <mat-error *ngSwitchCase="uploadStatusEnum.FILE_SIZE_ERROR">{{
            file.errorText
          }}</mat-error>
        </ng-container>
      </div>
    </div>
  </div>
</div>
