import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { TnUiMaterialModule } from '@transport/ui-material';
import { TnUiTranslateModule } from '@transport/ui-translate';

import { TnYamapComponent } from './components/yamap/yamap.component';
import { TnYamapTrackingComponent } from './components/yamap/yamap-tracking.component';
import { TnYamapsBalloonsService } from './services/balloons/yamaps-balloons.service';
import { TnYamapsControlsService } from './services/controls/yamaps-controls.service';
import { TnYamapsDispatcherService } from './services/dispatcher/yamaps-dispatcher.service';
import { TnYamapsGeocodeService } from './services/geocode/yamaps-geocode.service';
import { TnYamapsLoaderService } from './services/loader/yamaps-loader.service';
import { TnYamapsUtilsService } from './services/utils/yamaps-utils.service';

export const coreDirectives = [TnYamapComponent, TnYamapTrackingComponent];

@NgModule({
  imports: [CommonModule, FormsModule, ReactiveFormsModule, RouterModule, TnUiMaterialModule, TnUiTranslateModule, FlexLayoutModule],
  declarations: [...coreDirectives],
  exports: [...coreDirectives],
  providers: [
    TnYamapsControlsService,
    TnYamapsLoaderService,
    TnYamapsUtilsService,
    TnYamapsGeocodeService,
    TnYamapsBalloonsService,
    TnYamapsDispatcherService,
  ],
})
export class TnYamapsCoreModule {}
