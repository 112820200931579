<h1 mat-dialog-title fxLayoutAlign="space-between center">
  <ng-content select="[title]"></ng-content>
  <mat-icon class="material-icons dialog-close" (click)="cancel.emit()">clear</mat-icon>
</h1>

<div mat-dialog-content>
  <ng-content select="[content]"></ng-content>
</div>

<div mat-dialog-actions fxLayout="row" fxLayoutAlign="end center">
  <button *ngIf="hasChanges" class="clear-all" data-test="clear-all" mat-button cdkFocusInitial (click)="clearAll.emit()">
    {{ 'shared.actions.clearAll' | translate }}
  </button>
  <button class="apply" data-test="apply" mat-raised-button color="primary" mat-button (click)="apply.emit()">
    {{ 'shared.actions.apply' | translate }}
  </button>
</div>
