import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ValidationErrors } from '@angular/forms';
import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()
@Component({
  selector: 'common-input',
  templateUrl: './common-input.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CommonInputComponent implements OnInit {
  @Input() public size: 'xs' | 'sm' | 'md' | 'lg' = 'sm';
  @Input() public errorsSize: 'xs' | 'sm' | 'md' | 'lg' = 'xs';
  @Input() public inputFieldClass = '';
  @Input() public label?: string = '';
  @Input() public labelClasses?: string[];
  @Input() public labelAddition?: string;
  @Input() public caption?: string;
  @Input() public captionClasses?: string[];
  @Input() public showErrors = true;
  @Input() public required = false;
  @Input() public leftAddon = '';
  @Input() public rightAddon = '';
  @Input() public inputClasses?: string;
  @Input() public disabled = false;
  @Input() public inputType = '';
  @Input() public trim = false; // Не работает setAsyncValidators, если указано true
  @Input() public errorToMessage: (errors: ValidationErrors | null) => string = () => 'Error';

  @Input() public mask?;
  @Input() public patterns?;

  @Input() public formGroup?: FormGroup;
  @Input() public control?: FormControl;
  @Input() public controlName: string = '';
  @Input() public placeholder: string = '';
  @Input() public dataTest: string = '';
  @Input() public autocomplete: 'off' | 'email' = 'off';

  public ngOnInit(): void {
    if (this.trim) {
      this.formControl.valueChanges
        .pipe(untilDestroyed(this))
        .subscribe(value => this.formControl?.setValue(value?.trim(), { emitEvent: false }));
    }
  }

  get formControl(): AbstractControl {
    return (this.formGroup?.get(this.controlName) ?? this.control)!;
  }
}
