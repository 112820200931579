<div class="individual-businessman gap-3 divide-y divide-gray-200">
  <div class="pb-2">
    <div class="individual-businessman__name not-italic font-medium text-lg text-gray-700">
      <span *ngIf="isSelfEmployed(legalForm | async)">{{ 'shared.mp.verification.form.organization.selfEmployedShort' | translate }}</span>
      {{currentUserService.profile.organization?.name}}
    </div>
    <div class="individual-businessman__address not-italic font-normal text-sm text-gray-500">{{currentUserService.profile.organization?.legalAddress}}</div>
  </div>

  <ng-container *ngIf="!isIndividualInterpreneur(legalForm | async) && !isSelfEmployed(legalForm | async); else inn">
    <div class="row">
      <span class="row__label" fxFlex="%50">{{ 'shared.mp.verification.form.organization.innKpp' | translate }}</span>
      <span class="row__value" fxFlex="%50">{{currentUserService.profile.organization?.inn}} / {{currentUserService.profile.organization?.kpp}}</span>
    </div>
  </ng-container>
  <ng-template #inn>
    <div class="row">
      <span class="row__label" fxFlex="%50">{{ 'shared.mp.verification.form.organization.inn' | translate }}</span>
      <span class="row__value" fxFlex="%50">{{currentUserService.profile.organization?.inn}}</span>
    </div>
  </ng-template>

  <div class="row" *ngIf="!isSelfEmployed(legalForm | async)">
    <span class="row__label" fxFlex="%50">{{ 'shared.mp.verification.form.organization.ogrn' | translate }}</span>
    <span class="row__value" fxFlex="%50"
      >{{currentUserService.profile.organization?.registrationNumber}} {{ 'shared.mp.verification.form.from' | translate }} {{ogrnDate}}
    </span>
  </div>

  <ng-container *ngIf="!isIndividualInterpreneur(legalForm | async) && !isSelfEmployed(legalForm | async); else passportPhoto">
    <div class="row row--file">
      <span class="row__label flex items-center">{{ 'shared.mp.verification.form.organization.regulation' | translate }}</span>
      <transport-file-selector [errorToMessage]="validationErrors.bind(this)" [control]="form.controls.regulation"></transport-file-selector>
    </div>
  </ng-container>
  <ng-template #passportPhoto>
    <div class="row row--file" *ngIf="isSelfEmployed(legalForm | async)">
      <span class="row__label flex items-center">{{ 'shared.mp.verification.form.organization.selfEmployedReferrence' | translate }}</span>
      <transport-file-selector
        [errorToMessage]="validationErrors.bind(this)"
        [control]="form.controls.selfEmployedReferrence"
      ></transport-file-selector>
    </div>
    <div class="row row--file">
      <span class="row__label flex items-center">{{ 'shared.mp.verification.form.organization.passportPhoto' | translate }}</span>
      <transport-file-selector
        [errorToMessage]="validationErrors.bind(this)"
        [control]="form.controls.passportPhoto"
      ></transport-file-selector>
    </div>
    <div class="row row--file">
      <span class="row__label flex items-center">{{ 'shared.mp.verification.form.organization.passportRegistrationPhoto' | translate }}</span>
      <transport-file-selector
        [errorToMessage]="validationErrors.bind(this)"
        [control]="form.controls.passportRegistrationPhoto"
      ></transport-file-selector>
    </div>
  </ng-template>

  <div class="row row--file" *ngIf="!isSelfEmployed(legalForm | async)">
    <span class="flex items-center">{{ 'shared.mp.verification.form.organization.registrationCertificate' | translate }}</span>
    <transport-file-selector
      [errorToMessage]="validationErrors.bind(this)"
      [control]="form.controls.registrationCertificate"
    ></transport-file-selector>
  </div>
</div>
