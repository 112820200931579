<ng-container *ngIf="(currentView$ | async) as view">
  <ng-container *ngIf="view === popupView.bet" [ngTemplateOutlet]="acceptTmpl"></ng-container>
  <ng-container *ngIf="view === popupView.counterOffer" [ngTemplateOutlet]="counterOffer"></ng-container>
</ng-container>

<ng-template #acceptTmpl>
  <div class="px-3 w-[220px] min-w-max" fxLayout="column" fxLayoutGap="12px">
    <div class="header not-italic mb-3 font-medium text-sm text-gray-700">
      {{'shared.mp.cargo.table.popup.take.pickUpOrder'
      | translate}}
    </div>
    <div fxLayout="column">
      <div *ngIf="!isCancelled && !isTaken" class="price not-italic text-center font-medium text-xl text-gray-700">
        {{popupContext.price | currencyMulti: popupContext.currency : false}}
      </div>
      <div *ngIf="isTaken" class="not-italic text-center font-medium text-base text-gray-700">
        <span>{{'shared.mp.cargo.table.popup.take.orderWasTaken' | translate}}</span>
      </div>
      <div *ngIf="isCancelled" class="not-italic text-center font-medium text-base text-gray-700">
        {{'shared.mp.cargo.table.popup.take.orderCancelled' | translate}}
      </div>
      <div class="text-xs price-info not-italic text-center font-normal text-gray-500">
        {{(popupContext.isVatIncluded ? 'shared.mp.cargo.table.popup.take.priceWithVat' :
        'shared.mp.cargo.table.popup.take.priceWithoutVat') | translate}}
      </div>
      <div class="flex justify-center max-w-[188px] items-center gap-2 text-xs not-italic text-center font-normal text-orange-500">
        <span *ngIf="popupContext.bookingAttemptsLeft === 1">Осталась 1 попытка</span>
        <span *ngIf="popupContext.bookingAttemptsLeft === 0">Вы не можете забрать этот заказ, так как превысили допустимое количество бронирований</span>
      </div>
    </div>
    <div>
      <button
        class="bet__button tw-button-solid-sm w-full m-0"
        [disabled]="isDisabled || (loading | async)"
        (click)="accept(popupContext.id)"
      >
        <mat-spinner *ngIf="loading | async" [diameter]="20" [strokeWidth]="2"></mat-spinner>
        <span>{{'shared.mp.cargo.table.popup.take.take' | translate}}</span>
      </button>

      <div *ngIf="popupContext.allowCounterOffer" class="h-[1px] w-full bg-gray-300 mt-3">&nbsp;</div>

      <button
        *ngIf="popupContext.allowCounterOffer"
        (click)="auctionPopup.currentView$.next(popupView.counterOffer)"
        class="tw-button-outline-sm w-full mt-3"
      >
        {{ 'shared.mp.counterOfferPopup.sendCounterOffer' | translate }}
      </button>
    </div>
  </div>
</ng-template>

<ng-template #counterOffer>
  <div class="popup px-3 max-w-[229px]">
    <div class="flex flex-row w-full mb-3 gap-[18px] items-center" (click)="auctionPopup.currentView$.next(popupView.bet)">
      <img src="assets/icons/chevron_left.svg" class="w-2 h-4 object-cover cursor-pointer" />
      <h3 class="popup__header not-italic font-medium text-sm text-gray-700">
        {{ 'shared.mp.counterOffer.counterOffer' | translate }}
      </h3>
    </div>
    <div class="popup__main flex flex-col gap-2">
      <span class="text-sm font-normal text-gray-700">{{ 'carrier.order.section.lot.field.yourBet' | translate }}:</span>
      <transport-tw-input-field
        errorsSize="xs"
        size="sm"
        [control]="counterOfferPriceControl"
        [errorToMessage]="priceControlErrorToMessage"
      >
        <div class="flex gap-1 items-center">
          <transport-tw-input size="sm" [rightAddon]="currencySign" [control]="counterOfferPriceControl">
            <input
              tw-input
              transportNumberOnly
              class="max-w-[140px] text-gray-700 text-end"
              thousandSeparator=" "
              autocomplete="off"
              [required]="true"
              [mask]="'separator.0'"
              [formControl]="counterOfferPriceControl"
            />
          </transport-tw-input>
          <mat-checkbox class="text-sm text-gray-700" [formControl]="counterOfferVatControl">
            {{'shared.mp.publications.modal.form.price.withVat' | translate}}
          </mat-checkbox>
        </div>
      </transport-tw-input-field>
    </div>
    <common-textarea
      [control]="$any(counterOfferComment)!"
      [placeholder]="'shared.counterOfferComment' | translate"
      [errorToMessage]="validationErrors.bind(this)"
      [dataTest]="'counter-offer-modal-comment'"
      [rows]="3"
      size="sm"
    >
    </common-textarea>
    <button
      class="tw-button-solid-sm w-full mt-2"
      [disabled]="!(counterOfferPriceControl.valid && counterOfferComment.valid) || (loading | async)"
      (click)="sendCounterOffer()"
    >
      {{ 'shared.mp.verification.buttons.send' | translate }}
    </button>
  </div>
</ng-template>

<ng-template #counterOfferSuccess let-input>
  <transport-alert [title]="input.title" titleVariant="top" state="success"> </transport-alert>
</ng-template>
<ng-template #orderSuccess>
  <transport-alert
    [title]="'shared.mp.cargo.table.popup.take.orderWasMovedTitle' | translate : { value: popupContext.id }"
    titleVariant="top"
    state="success"
  >
    {{'shared.mp.cargo.table.popup.take.orderWasMoved' | translate}}.
    <a class="cursor-pointer" (click)="goToOrder()">{{'shared.mp.cargo.table.popup.take.goToOrder' | translate}}</a>
  </transport-alert>
</ng-template>
<ng-template #orderError>
  <transport-alert [title]="'shared.mp.cargo.table.popup.take.takenErrorTitle' | translate" titleVariant="top" state="error">
    {{'shared.mp.cargo.table.popup.take.takenError' | translate}}.
  </transport-alert>
</ng-template>
